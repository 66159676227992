import { useEffect, useState, useRef, useCallback } from "react";
import { LensSDKException } from "lens-platform-sdk";
import { useAnalytics } from "src/hooks/useAnalytics";
import { useLensPlatformClient } from "./useLensPlatformClient";

/**
 * Hook for verifying a secondary email address
 */
export const useVerifySecondaryEmail = () => {
  const lensPlatformClient = useLensPlatformClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<LensSDKException>();
  const { track, trackError } = useAnalytics();
  // Keep up with mounted/unmounted state
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const verifySecondaryEmail = useCallback(
    async (token: string) => {
      let lensSDKException: LensSDKException | undefined;

      try {
        if (mounted.current) {
          setError(undefined);
          setLoading(true);
        }
        await lensPlatformClient.user.verifySecondaryEmail(token);

        if (mounted.current) {
          track("Verification Token Sent");
        }
      } catch (exception) {
        if (exception instanceof LensSDKException) {
          trackError("Verification Token Sent Failed");
          setError(exception);
          lensSDKException = exception;
        }
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }

      return lensSDKException;
    },
    [lensPlatformClient.user, track, trackError],
  );

  return {
    verifySecondaryEmail,
    error,
    loading,
  };
};
