import { HTMLAttributes, PropsWithChildren, ReactNode, useMemo } from "react";
import clsx from "clsx";
import { Cell, Column, flexRender, Header, HeaderGroup, Row } from "@tanstack/react-table";

import TableHeaderGroup, { TableHeaderGroupProps } from "./TableHeaderGroup";
import TableRows, { TableRowsProps } from "./TableRows";

import styles from "./Table.module.css";

type BaseProps = HTMLAttributes<HTMLTableElement> & {
  caption: string | ReactNode;
  showCaption?: boolean;
  className?: string;
  responsive?: boolean;
  loading?: boolean;
  noDataText?: string;
  /**
   * Remove the border from the table
   * @default true
   */
  borderless?: boolean;
};

type TablePropsWithData<TData> = BaseProps & {
  headerGroups?: HeaderGroup<TData>[];
  headerGroupProps?: Omit<TableHeaderGroupProps<TData>, "headerGroup">;
  rows?: Row<TData>[];
  rowProps?: Omit<TableRowsProps<TData>, "rows">;
  columns?: Column<TData, unknown>[];
};

const Wrapper = ({
  caption,
  showCaption,
  responsive,
  className,
  children,
  borderless = true,
  ...props
}: PropsWithChildren<
  HTMLAttributes<HTMLTableElement> & {
    caption: string | ReactNode;
    showCaption?: boolean;
    responsive?: boolean;
    borderless?: boolean;
  }
>) => (
  <table
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    className={clsx(styles.table, className, {
      [styles.borderless]: borderless,
      [styles.responsive]: responsive,
    })}
  >
    {children}
    <caption className={clsx(styles.caption, { "lds-only-aria": !showCaption })}>{caption}</caption>
  </table>
);

const Table = function <TData>({
  responsive = true,
  loading = false,
  noDataText = "No data",
  headerGroups = [],
  headerGroupProps = {},
  rows = [],
  rowProps = {},
  columns = [],
  ...tableProps
}: TablePropsWithData<TData>) {
  const rowLength = useMemo(() => rows.length, [rows]);
  const columnLength = useMemo(() => columns.length, [columns]);

  const renderHeaderCell = (header: Header<TData, unknown>) =>
    flexRender(header.column.columnDef.header, header.getContext());
  const renderCell = (cell: Cell<TData, unknown>) => flexRender(cell.column.columnDef.cell, cell.getContext());
  const renderCellHeaderCell = (cell: Cell<TData, unknown>) =>
    flexRender(
      cell.column.columnDef.header,
      {} as any, // TODO: Find a way to get context
    );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Wrapper responsive={responsive} {...tableProps}>
      <>
        <thead>
          {headerGroups.map((headerGroup) => (
            <TableHeaderGroup<TData>
              key={headerGroup.id}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...headerGroupProps}
              headerGroup={headerGroup}
              renderHeaderCell={headerGroupProps.renderHeaderCell || renderHeaderCell}
            />
          ))}
        </thead>
        <tbody aria-busy={loading}>
          {loading && (
            <tr>
              <td colSpan={columnLength}>
                <div className={styles.noData}>Loading…</div>
              </td>
            </tr>
          )}
          {!loading && rowLength > 0 && (
            <TableRows<TData>
              responsive={responsive}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...rowProps}
              rows={rows}
              renderCellHeaderCell={rowProps.renderCellHeaderCell || renderCellHeaderCell}
              renderCell={rowProps.renderCell || renderCell}
            />
          )}
          {!loading && rowLength === 0 && (
            <tr>
              <td colSpan={columnLength}>
                <div className={styles.noData}>{noDataText}</div>
              </td>
            </tr>
          )}
        </tbody>
      </>
    </Wrapper>
  );
};

export default Table;
