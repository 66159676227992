import { forwardRef, ForwardedRef } from "react";
import { useLocation } from "react-router-dom";

import { useAnalytics } from "src/hooks/useAnalytics";
import { useTrackedLinkClickHandler } from "src/hooks/useTrackedLinkClickHandler";

import RouterLinkButton, { type RouterLinkButtonProps } from "./RouterLinkButton";

const TrackedRouterLinkButton = forwardRef<HTMLAnchorElement, RouterLinkButtonProps>(
  ({ to, onClick, label, children, ...props }: RouterLinkButtonProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    const location = useLocation<unknown>();
    const { trackButtonClicked } = useAnalytics();

    const handleClick = useTrackedLinkClickHandler<unknown>({
      to,
      onClick,
      children,
      location,
      elementType: "LinkButton",
      label,
      tracker: trackButtonClicked,
    });

    return (
      <RouterLinkButton
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        to={to}
        onClick={handleClick}
        label={label}
      >
        {children}
      </RouterLinkButton>
    );
  },
);

export default TrackedRouterLinkButton;
