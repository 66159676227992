import { useGetLicense } from "src/hooks/useGetLicense";
import { PersonalSubscription } from "src/components/pages/Home/PersonalSubscription";
import { LicenseType } from "./license";
import { NoSubscriptions } from "./NoSubscription";
import { ProSubscription } from "./ProSubscription";

export const LicenseContainer = () => {
  const license = useGetLicense();

  if (!license) {
    return <NoSubscriptions />;
  }

  if (license.type === LicenseType.personal) {
    return <PersonalSubscription />;
  }

  return <ProSubscription />;
};
