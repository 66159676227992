import { useContext } from "react";
import Layout from "src/components/Layout/Layout";
import { ProfileContext } from "src/providers/profile-provider";

import { UserBillingDetails } from "./UserBillingDetails";

export const BillingDetails = () => {
  const { profile } = useContext(ProfileContext);

  return (
    <Layout className="bg-secondary" profile={profile}>
      <UserBillingDetails />
    </Layout>
  );
};
