import { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { type NotificationProps } from "@k8slens/lds/lib/es/Notification/Notification";

import { useAnalytics } from "src/hooks/useAnalytics";
import { ProfileContext } from "src/providers/profile-provider";
import { useAcceptBusinessInvitation } from "src/hooks/useAcceptBusinessInvitation";
import { useParseInvitationError } from "src/hooks/useParseInvitationError";
import { useBusinessInvitation } from "src/hooks/useBusinessInvitation";
import { useAuth } from "src/hooks/useAuth";
import { useQuery } from "src/hooks/useQuery";

import CurrentUser from "src/components/CurrentUser/CurrentUser";
import Button from "src/components/Button/TrackedButton";
import LinkButton from "src/components/Button/TrackedRouterButton";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";
import PublicLayout from "src/components/PublicLayout/PublicLayout";

import styles from "./page.module.css";

const panelProps = {
  contentProps: {
    className: styles.content,
  },
};
const notificationProps: Partial<NotificationProps> = { level: "error" };

export const AcceptBusinessInvitation = () => {
  const parseInvitationError = useParseInvitationError();

  const { updateToken } = useAuth();
  const { track } = useAnalytics();
  const history = useHistory();
  const onAcceptSuccess = useCallback(
    async (role: string, businessId: string, invitationId: string) => {
      await updateToken();
      track("Business Invitation Accepted");

      if (role === "Administrator") {
        history.push(`/business/${businessId}`, {
          invitationId,
        });
      } else {
        history.push("/home", {
          invitationId,
        });
      }
    },
    [history, track, updateToken],
  );

  const {
    acceptBusinessInvitation,
    error: invitationError,
    isLoading,
    isNoSeat,
  } = useAcceptBusinessInvitation(onAcceptSuccess);
  const { profile } = useContext(ProfileContext);
  const query = useQuery();
  const { invitationId, businessId } = useMemo(() => {
    return {
      invitationId: query.get("invitationId"),
      businessId: query.get("businessId"),
    };
  }, [query]);
  const { businessInvitation } = useBusinessInvitation(businessId, invitationId);
  const username = profile?.username;

  const handleAccept = () => {
    if (businessId && invitationId) {
      acceptBusinessInvitation({
        businessId,
        invitationId,
      });
    }
  };

  const invalidKey = invitationId === null || businessId === null;

  const errorMessage = useMemo(() => {
    if (invitationError) {
      return parseInvitationError(invitationError.message);
    } else if (invalidKey) {
      return "Something went wrong";
    }

    return undefined;
  }, [invitationError, parseInvitationError, invalidKey]);

  return (
    <PublicLayout
      title="Accept Business ID Invitation"
      notification={errorMessage}
      notificationProps={notificationProps}
      panelProps={panelProps}
      pageFooter={<CurrentUser />}
      footer={
        <>
          <LinkButton to="/home" label="Cancel" discreet disabled={isNoSeat} />
          {isNoSeat ? (
            <Button
              label="Ok"
              onClick={async () => {
                await updateToken();
                history.push("/home", {
                  invitationId,
                });
              }}
              loading={isLoading}
              disabled={isLoading}
              primary
            />
          ) : (
            <Button label="Accept" onClick={handleAccept} loading={isLoading} disabled={isLoading} primary />
          )}
        </>
      }
    >
      <>
        {!invalidKey && (
          <div>
            This invitation will add <strong>{username}</strong> to the Lens Business ID{" "}
            <strong>{businessInvitation?.business?.name}</strong>.
          </div>
        )}
        {invalidKey && (
          <div className={styles.error}>
            <p>There seems to be some issues with your invitation.</p>
            <p>
              Try re-opening the link in your invitation email. If that doesn&apos;t help, please contact us at{" "}
              <TrackedAnchor href="mailto:info@k8slens.dev">info@k8slens.dev.</TrackedAnchor>
            </p>
          </div>
        )}
      </>
    </PublicLayout>
  );
};
