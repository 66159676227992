import { useEffect, useState, useRef, useCallback } from "react";
import { LensPlatformClient, LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useAnalytics } from "./useAnalytics";

type UploadAvatarResponse = Awaited<ReturnType<LensPlatformClient["user"]["uploadAvatar"]>>;

let uploadCount = 0;

// Value incremented in uploadAvatar fn
// Used in useAvatarUrl to force avatar url update
export const getUploadCount = () => uploadCount;

/**
 * Hook for uploading user avatar
 */
export const useUploadAvatar = () => {
  const lensPlatformClient = useLensPlatformClient();
  const [error, setError] = useState<LensSDKException | undefined>();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<UploadAvatarResponse | undefined>();
  const { track, trackError } = useAnalytics();

  // Keep up with mounted/unmounted state
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const uploadAvatar = useCallback(
    async (file: File) => {
      try {
        if (mounted.current) {
          setLoading(true);
        }
        const response = await lensPlatformClient.user.uploadAvatar(file);

        uploadCount++;
        track("User avatar uploaded");

        if (mounted.current) {
          setResponse(response);
          setError(undefined);
        }

        return response;
      } catch (exception) {
        if (mounted.current && exception instanceof LensSDKException) {
          trackError("User avatar upload failed!");

          setError(exception);
        }

        return undefined;
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }
    },
    [lensPlatformClient.user, track, trackError],
  );

  return {
    uploadAvatar,
    response,
    loading,
    error,
  };
};
