import type { OperatingSystems } from "src/services/downloadURI";
import { OSFileExtensionsPairs } from "src/services/downloadURI";
import getKeys from "src/services/getKeys";

export const useOS = (): OperatingSystems => {
  const { userAgent } = navigator;

  // on Android device, user agent will contain both 'Linux' and 'Android';
  // return early here to avoid device to be detected as 'Linux'
  if (userAgent.includes("Android")) {
    return "Unknown";
  }

  for (const os of getKeys(OSFileExtensionsPairs)) {
    if (userAgent.includes(os)) {
      return os;
    }
  }

  return "Unknown";
};
