import { useState, useEffect, useRef } from "react";
import copy from "copy-to-clipboard";
import { action, base } from "@k8slens/lds-icons";

import styles from "./CopyToClipboard.module.css";

const { CopyPasteIcon } = action;
const { CheckIcon } = base;

export const CopyToClipboard = ({ text, onCopy }: { text: string; onCopy?: (text: string) => void }) => {
  const copyIconTimeout = useRef<number>();
  const [showCopy, setShowCopy] = useState(true);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();

    if (copy(text)) {
      setShowCopy(false);
      copyIconTimeout.current = window.setTimeout(() => {
        setShowCopy(true);
      }, 1000);
    }

    onCopy && onCopy(text);
  };

  useEffect(
    () => () => {
      clearTimeout(copyIconTimeout.current);
    },
    [],
  );

  return (
    <button
      type="button"
      className={styles.copy}
      aria-label="Copy to clipboard"
      title="Copy to clipboard"
      onClick={handleClick}
    >
      {showCopy ? <CopyPasteIcon /> : <CheckIcon color="primary" />}
    </button>
  );
};
