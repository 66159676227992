import { useMutation } from "@tanstack/react-query";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { LensSDKException, SubscriptionChangePreview, Business } from "lens-platform-sdk";

export const usePreviewBusinessSubscriptionUpdate = () => {
  const lensPlatformClient = useLensPlatformClient();

  const {
    isPending: updatingSubscriptionChangePreview,
    isSuccess: updateSubscriptionChangePreviewSuccess,
    error: errorUpdatingSubscriptionChangePreview,
    mutateAsync: previewSubscriptionChange,
    data: subscriptionChangePreview,
  } = useMutation<
    SubscriptionChangePreview,
    LensSDKException,
    {
      businessId: Business["id"];
      businessSubscriptionId: string;
      quantity: number;
    }
  >({
    mutationFn: ({ businessId, businessSubscriptionId, quantity }) =>
      lensPlatformClient.business.previewBusinessSubscriptionSeatsQuantityChange({
        businessSubscriptionId,
        businessId,
        quantity,
      }),
  });

  return {
    subscriptionChangePreview,
    previewSubscriptionChange,
    updateSubscriptionChangePreviewSuccess,
    updatingSubscriptionChangePreview,
    errorUpdatingSubscriptionChangePreview,
  };
};
