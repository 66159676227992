import { memo, useCallback } from "react";

import { useAuth } from "src/hooks/useAuth";
import { useAnalytics } from "src/hooks/useAnalytics";

import TrackedLink from "src/components/TrackedLink/TrackedLink";

import styles from "./ThankYouFooter.module.css";

const logoutButtonLabel = "Logout";

export const ThankYouFooter = memo(() => {
  const { logout, username } = useAuth();
  const { trackButtonClicked } = useAnalytics();

  const handleLogout = useCallback(() => {
    trackButtonClicked(logoutButtonLabel);
    logout();
  }, [trackButtonClicked, logout]);

  return (
    <div>
      <div className={styles.thankYouFooter}>
        <span>
          Logged in as <strong>{username}</strong>
        </span>
        <TrackedLink className="primary" to="/">
          Lens ID Portal
        </TrackedLink>
        <button tabIndex={0} role="link" type="button" onClick={handleLogout}>
          {logoutButtonLabel}
        </button>
      </div>
    </div>
  );
});
