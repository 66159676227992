import { LoadingIndicator } from "@k8slens/lds";
import { useEffect } from "react";
import { useCanDownloadCode } from "src/hooks/useCanDownloadCode";
import { useDownloadActivationCode } from "src/hooks/useDownloadActivationCode";

export function DownloadCode() {
  const { downloadActivationCode, downloaded, offlineActivationCode } = useDownloadActivationCode();
  const canDownload = useCanDownloadCode();

  useEffect(() => {
    if (offlineActivationCode && !downloaded && canDownload) {
      downloadActivationCode();
    }

    if (downloaded) {
      // redirect immediately if has downloaded the offline activation code
      // we need to use `window.location.replace` to re-init the auth provider to ensure
      // the app is authenticated using `keycloakClientId` instead of `keycloakLensDesktopClientId`
      window.location.replace(`${window.location.origin}/`);
    }
  }, [downloadActivationCode, offlineActivationCode, downloaded, canDownload]);

  return <LoadingIndicator size="2xl" />;
}
