import { Buffer } from "buffer";

export const getActivationCode = (accessToken: string, refreshToken: string, idToken: string): string =>
  Buffer.from(
    JSON.stringify({
      access_token: accessToken,
      id_token: idToken,
      refresh_token: refreshToken,
    }),
    "utf8",
  ).toString("base64");

export const getActivationCodeBlob = (activationCode: string) => {
  const blob = new Blob([activationCode], { type: "text/text" });

  return blob;
};

export const getActivationCodeUrl = (activationCode: string) => {
  const url = URL.createObjectURL(getActivationCodeBlob(activationCode));

  return url;
};

export const createOfflineActivationCodeDownload = (activationCode: string) => {
  const activationCodeUrl = getActivationCodeUrl(activationCode);
  const anchor = document.createElement("a");

  anchor.href = activationCodeUrl;
  anchor.target = "_blank";
  anchor.download = "activation-code.txt";
  anchor.click();
  anchor.remove();
};
