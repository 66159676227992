/**
 * Get the "full name" for UI from a user's fullname property, firstname and lastname properties
 */
export const getUserFullname = (user: { firstName?: string; lastName?: string; fullname?: string }) => {
  // If user has firstname, add a space to be used as a separator when merged with lastname
  // If firstname is null, return an empty string instead
  const firstNameWithSpace = user.firstName ? `${user.firstName} ` : "";

  // If user's lastname is null, return an empty string instead.
  const lastName = user.lastName ?? "";

  return `${firstNameWithSpace}${lastName}` || user.fullname;
};
