import { useMemo, useState } from "react";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { action, base, misc } from "@k8slens/lds-icons";

import Table from "src/components/Table/Table";
import { type UserEmails, type EmailStatus } from "src/hooks/useGetEmails";
import { ContextMenuItem } from "src/components/ContextMenu/ContextMenu";
import ContextMenuCell, { contextMenuColDef } from "src/components/TableCells/ContextMenuCell";

import styles from "./EmailTable.module.css";

const { DeleteIcon } = action;
const { SuccessIcon } = base;
const { EmailIcon } = misc;

type EmailItem = {
  index: number;
  email: string;
  state: EmailStatus;
};

const columnHelper = createColumnHelper<EmailItem>();

type Props = {
  emails: UserEmails;
  onSendVerificationEmail: (email: string) => Promise<void>;
  onSetPrimaryEmail: (email: string) => Promise<void>;
  onDeleteEmails: (email: string[]) => Promise<void>;
};

export const EmailTable = ({ emails, onSendVerificationEmail, onSetPrimaryEmail, onDeleteEmails }: Props) => {
  const emailArray = useMemo<EmailItem[]>(
    () => Object.entries(emails).map(([email, state], index) => ({ index, email, state })),
    [emails],
  );
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("email", {
        header: () => <span>Email Address</span>,
        cell: ({ getValue, row }) => {
          const { state } = row.original;

          return (
            <div>
              <span className={styles.email}>{getValue()}</span>
              {state === "primary" ? <span className={styles.primaryBadge}>{state}</span> : null}
            </div>
          );
        },
      }),
      columnHelper.accessor("state", {
        header: () => <span>Verify Status</span>,
        cell: ({ getValue }) => {
          // primary is always renders as verified
          const verifyStatus = getValue() === "primary" ? "verified" : getValue();

          return <span className={styles[verifyStatus]}>{verifyStatus}</span>;
        },
      }),
      columnHelper.accessor("index", {
        ...contextMenuColDef,
        header: () => <span className="lds-only-aria">Context Menu</span>,
        cell: ({ row }) => {
          const { email } = row.original;
          const isPrimary = row.original.state === "primary";
          const isUnverified = row.original.state === "unverified";
          const isVerifiedSecondary = row.original.state === "verified" && !isPrimary;

          if (isPrimary) {
            return null;
          }

          const title = isUnverified
            ? `Send a verification email to ${email} or delete it`
            : `Set ${email} as the primary email or delete it`;

          return (
            <ContextMenuCell toggleButtonProps={{ title, "aria-label": title }}>
              {isUnverified && (
                <ContextMenuItem label="Verify" icon={EmailIcon} onClick={() => onSendVerificationEmail(email)} />
              )}
              {isVerifiedSecondary && (
                <ContextMenuItem label="Set as primary" icon={SuccessIcon} onClick={() => onSetPrimaryEmail(email)} />
              )}
              {!isPrimary && (
                <ContextMenuItem label="Remove" icon={DeleteIcon} onClick={() => onDeleteEmails([email])} />
              )}
            </ContextMenuCell>
          );
        },
      }),
    ],
    [onSendVerificationEmail, onSetPrimaryEmail, onDeleteEmails],
  );

  const table = useReactTable({
    data: emailArray,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });
  const tableRowModel = table.getRowModel();
  const tableRows = useMemo(() => tableRowModel.rows, [tableRowModel]);

  return (
    <section>
      <Table<EmailItem>
        showCaption={false}
        caption=""
        responsive={false}
        rows={tableRows}
        columns={table.getAllColumns()}
        headerGroups={table.getHeaderGroups()}
        noDataText="No Emails!"
      />
    </section>
  );
};
