import { useQuery } from "./useQuery";

export const invitationKeyParameter = "invitation-key";

/**
 * Get the invitation key from query string parameter invitation-key
 */
export const useInvitationKey = () => {
  const query = useQuery();
  const invitationKeyQueryParameter = query.get(invitationKeyParameter);

  return invitationKeyQueryParameter;
};
