import { type PropsWithChildren } from "react";
import clsx from "clsx";
import { ButtonBar } from "@k8slens/lds";
import { type ColumnMeta } from "@tanstack/react-table";

import styles from "./ActionsCell.module.css";

const ActionsCell = ({ end, children }: PropsWithChildren<{ end?: boolean }>) => {
  return (
    <ButtonBar as="span" className={clsx(styles.actions, { [styles.end]: end })}>
      {children}
    </ButtonBar>
  );
};

export const actionsCellMeta: ColumnMeta<unknown, unknown> | undefined = {};

export const actionsColDef = {
  id: "actions",
  enableSorting: false,
  meta: actionsCellMeta,
  header: () => <span />,
};

export default ActionsCell;
