import { LensSDKException, NotFoundException } from "lens-platform-sdk";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import { LinkedUserAccount } from "lens-platform-sdk";
import { useAnalytics } from "src/hooks/useAnalytics";
import { queryClient } from "src/queryClient";

/**
 * Get get user linked accounts
 */
export const useGetUserLinkedAccounts = () => {
  const lensPlatformClient = useLensPlatformClient();
  const { track } = useAnalytics();

  const {
    isLoading: loadingUserLinkedAccounts,
    error: errorLoadingUserLinkedAccounts,
    data: userLinkedAccounts,
    refetch: refetchUserLinkedAccounts,
  } = useQuery<LinkedUserAccount[], LensSDKException>({
    queryKey: ["user-linked-accounts"],
    queryFn: async () => {
      try {
        const accounts = await lensPlatformClient.user.getUserLinkedAccounts();

        track("Linked accounts retrieved for user");

        return accounts;
      } catch (error) {
        if (error instanceof NotFoundException) {
          track("Fail to load linked accounts for user");

          return [];
        }
        throw error;
      }
    },
    initialData: [],
  });

  const {
    isPending: deleteUserLinkedAccountLoading,
    error: errorDeleteUserLinkedAccount,
    mutate: deleteUserLinkedAccount,
  } = useMutation<void, LensSDKException, string>({
    mutationFn: async (identityProviderAlias: string) =>
      lensPlatformClient.user.deleteUserLinkedAccount(identityProviderAlias),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["user-linked-accounts"] });
    },
  });

  return {
    userLinkedAccounts,
    refetchUserLinkedAccounts,
    loadingUserLinkedAccounts,
    errorLoadingUserLinkedAccounts,
    deleteUserLinkedAccountLoading,
    errorDeleteUserLinkedAccount,
    deleteUserLinkedAccount,
  };
};
