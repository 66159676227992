import { useGetUserBusinesses } from "./useGetUserBusinesses";
import { useGetUserLinkedAccounts } from "./useGetUserLinkedAccounts";
import { useCallback, useEffect, useState } from "react";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { LensSDKException, SSOProviderConnection, UserBusinessWithSSOInfo } from "lens-platform-sdk";
import { useAnalytics } from "./useAnalytics";

export type UserBusinessSSOConnection = SSOProviderConnection & UserBusinessWithSSOInfo;

/**
 * Get get user sso
 */
export const useGetUserSSO = () => {
  const { userBusinesses } = useGetUserBusinesses();
  const { userLinkedAccounts } = useGetUserLinkedAccounts();
  const { track } = useAnalytics();
  const [userSSOAccountsConnectionLinks, setUserSSOAccountsConnectionLinks] = useState<UserBusinessSSOConnection[]>();
  const lensPlatformClient = useLensPlatformClient();

  const getUserSSOConnectionsLinks = useCallback(async () => {
    if (userBusinesses && userBusinesses.length > 0) {
      // Check if user has business SSO not connected accounts
      const notConnectedSSOAccounts = userBusinesses.filter(
        (business) =>
          business.sso &&
          !userLinkedAccounts.find((account) => account.identityProviderAlias === business?.sso?.identityProviderID),
      );

      try {
        const userConnectionLinks = await Promise.all(
          notConnectedSSOAccounts.map(async (business) => {
            // Get SSO Connection link for user business accounts
            const link = await lensPlatformClient.sso.getSSOProviderConnectionLink(
              business?.sso?.identityProviderID as string,
              "lensCloudFrontend",
            );

            // Return link along with the business info
            return {
              ...link,
              ...business,
            };
          }),
        );

        track("User SSO connection links retrieved");

        setUserSSOAccountsConnectionLinks(userConnectionLinks);

        return userConnectionLinks;
      } catch (error) {
        if (error instanceof LensSDKException) {
          track(`Error retrieving user SSO connection links. Because of ${error.message}`);
        }
      }
    }
  }, [userBusinesses, userLinkedAccounts, lensPlatformClient.sso, track]);

  useEffect(() => {
    getUserSSOConnectionsLinks();
  }, [getUserSSOConnectionsLinks]);

  return {
    userSSOAccountsConnectionLinks,
  };
};
