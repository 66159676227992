export const defaultDateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const dateToString = (
  date: Date,
  dateTimeFormatOptions: Intl.DateTimeFormatOptions = defaultDateTimeFormatOptions,
) => date.toLocaleDateString("en-US", dateTimeFormatOptions);
