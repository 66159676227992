import { type ColumnMeta, type Row } from "@tanstack/react-table";

import { dateToString } from "src/utils/date";

type Input = Date | string | number | null | undefined;

interface DateCellProps {
  getValue: () => Input;
  options?: Intl.DateTimeFormatOptions;
  className?: string;
  /**
   * Text to display when there is no data.
   * @default empty
   */
  noDataText?: string;
}

const DateCell = ({ getValue, className, noDataText, options = undefined }: DateCellProps) => {
  const value = getValue();
  const date = value ? new Date(value) : null;
  const dateIsValid = Boolean(date instanceof Date && !isNaN(date.getTime()));

  if (!dateIsValid && !noDataText) {
    return null;
  }

  const res = date && dateIsValid ? dateToString(date, options) : noDataText;

  return <div className={className}>{res}</div>;
};

export const dateCellMeta: ColumnMeta<unknown, unknown> | undefined = {};

export const getDate = (d: unknown) => {
  let date: Date | null = null;

  if (typeof d === "number" || typeof d === "string" || d instanceof Date) {
    date = new Date(d);
  }

  return date instanceof Date && !isNaN(date.getTime()) ? date.getTime() : 0;
};

export function dateColSortingFn<T>(row1: Pick<Row<T>, "original">, row2: Pick<Row<T>, "original">, id: any) {
  const date1 = getDate(row1.original[id as keyof T]);
  const date2 = getDate(row2.original[id as keyof T]);

  if (!date1 && !date2) {
    return 0; // Both dates are missing, consider them equal
  } else if (!date1) {
    return 1; // Only 'a' date is missing, 'b' comes first
  } else if (!date2) {
    return -1; // Only 'b' date is missing, 'a' comes first
  }

  return date2 - date1;
}
export const dateColDef = {
  meta: dateCellMeta,
  sortingFn: dateColSortingFn,
  header: () => <span>Date</span>,
  cell: DateCell,
};

export default DateCell;
