import { useEffect, useState } from "react";
import customProtocolCheck from "custom-protocol-check";
import { type PanelProps } from "@k8slens/lds/lib/es/Panel/Panel";

import { getCustomProtocolURL, openLens } from "src/services/lens-app";
import { useAnalytics } from "src/hooks/useAnalytics";

import PublicLayout from "src/components/PublicLayout/PublicLayout";
import LensDownloadSelect from "src/components/LensDownloadSelect/LensDownloadSelect";

import styles from "./page.module.css";

const panelProps: Partial<PanelProps> = {
  className: styles.lensLauncher,
  footerComponent: "footer",
};

export interface Props {
  launchOnStart?: boolean;
}

export const LensLauncher = ({ launchOnStart = true }) => {
  const { track } = useAnalytics();
  const [autoLaunched, setAutoLaunched] = useState(false);

  useEffect(() => {
    if (launchOnStart && !autoLaunched) {
      customProtocolCheck(
        getCustomProtocolURL(),
        () => {
          // Lens not found
          track("Lens Desktop not found");
        },
        () => {
          // Lens launched, does this ever fire?
          track("Lens Desktop launched automatically");
        },
        5000,
      );
      setAutoLaunched(true);
    }
  }, [launchOnStart, autoLaunched, track]);

  return (
    <PublicLayout
      size="xl"
      pageFooter={
        <div>
          Lens Desktop not starting automatically?
          <button
            className={styles.launchButton}
            type="button"
            aria-label="Launch Lens Desktop Manually"
            onClick={openLens}
          >
            Launch Lens Desktop Manually
          </button>
        </div>
      }
      title="Lens Desktop Web Launcher"
      header={
        <p className={styles.subtitle}>If you have Lens Desktop installed, it should open automatically in a moment.</p>
      }
      footer={
        <>
          <p>Don’t have Lens Desktop installed?</p>
          <LensDownloadSelect />
        </>
      }
      panelProps={panelProps}
    >
      <img src="static/media/rocket-robot.svg" alt="Launching lens…" aria-hidden className={styles.lensLauncherImage} />
    </PublicLayout>
  );
};
