import { ComponentType } from "react";
import { Tab } from "@headlessui/react";

type ExtractProps<T> = T extends ComponentType<infer P> ? P : T;

export type TabGroupProps = ExtractProps<(typeof Tab)["Group"]>;

export const TabGroup: React.FC<TabGroupProps> = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tab.Group {...props}>{children}</Tab.Group>
);
