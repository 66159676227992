import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import type { Location } from "history";
import pick from "lodash/pick";
import debounce from "lodash/debounce";

import { useAnalytics } from "src/hooks/useAnalytics";

export const useTrackPageView = () => {
  const { pageViewed } = useAnalytics();
  const history = useHistory();

  const [initialised, setInitilised] = useState(false);
  const [pageJSON, setPageJSON] = useState("");

  const handlePageView = useCallback(
    (location: Location<unknown>) => {
      // Ignore changes to keys that are not relevant to the change in page
      const page = pick(location, ["pathname", "search", "hash", "state"]);
      const nextPageJSON = JSON.stringify(page);

      if (pageJSON !== nextPageJSON) {
        const tracked = pageViewed(page.pathname!, page);

        // Wait until tracker is ready
        if (tracked !== undefined) {
          setPageJSON(nextPageJSON);

          return true;
        }
      }

      return false;
    },
    [pageJSON, pageViewed],
  );

  useEffect(() => {
    if (!initialised && handlePageView(history.location)) {
      // Capture initial page view
      setInitilised(true);
    }
  }, [history.location, initialised, handlePageView]);

  useEffect(() => {
    // Track page views, debounce to prevent capturing router redirects
    const unlisten = history.listen(debounce(handlePageView, 10));

    return () => {
      unlisten();
    };
  }, [history, handlePageView]);
};
