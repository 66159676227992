import { UserWithEmail } from "lens-platform-sdk";

import { recurly } from "src/constants";
import { formatCurrency } from "src/utils/currency";
import { DecodedAccessToken, LensDesktopLicenseType } from "src/components/pages/Home/license";

export const getRecurlyHostedPaymentPageUrl = (planType: PlanCode, profile: UserWithEmail, token: DecodedAccessToken) =>
  `${recurly}/subscribe/${planType}/${encodeURIComponent(token.sub ?? "")}?quantity=1&email=${encodeURIComponent(
    profile.email,
  )}&username=${encodeURIComponent(profile.username ?? "")}`;

export const getRecurlyHostedPaymentPageUrlForLBID = (
  planType: PlanCode,
  profile: UserWithEmail,
  businessId: string,
  businessName: string,
  quantity: number = 1,
) =>
  `${recurly}/subscribe/${planType}/${encodeURIComponent(businessId)}?quantity=${quantity}&email=${encodeURIComponent(
    profile.email,
  )}&company_name=${encodeURIComponent(businessName)}`;

export type LensTrialPlanCode = "pro-trial";
export type LensPersonalPlanCode = "personal";
export type LensProPlanCode = "pro-monthly" | "pro-yearly";
export type LensEnterprisePlanCode = "enterprise-monthly" | "enterprise-annual";
/**
 * Type of plan codes that is used to activate Lens Desktop
 */
export type LensPlanCode = LensTrialPlanCode | LensPersonalPlanCode | LensProPlanCode | LensEnterprisePlanCode;
export type ActivatablePlanCode = LensProPlanCode;
export type ControlCenterPlanCode = "control-center-annual" | "control-center-monthly";

// This must be kept in sync with LensLicenseCustomProtocolMapper
export const lensTrialPlanCodes: LensTrialPlanCode[] = ["pro-trial"];
export const lensPlanCodes: LensPlanCode[] = [
  "personal",
  "pro-monthly",
  "pro-yearly",
  "pro-trial",
  "enterprise-monthly",
  "enterprise-annual",
];
export const assignablePlanCodes: PlanCode[] = ["pro-monthly", "pro-yearly", "enterprise-monthly", "enterprise-annual"];

const proFeatures = [
  "Lens Desktop",
  "Lens Teamwork",
  "Lens Security Center",
  "Lens Desktop Kubernetes",
  "Community Forums",
  "24 x 5 Commercial Support",
];

const enterpriseFeatures = [
  "Hardened Lens Desktop",
  "Lens Teamwork",
  "Lens Security Center",
  "Lens Desktop Kubernetes",
  "VDI Support",
  "Offline / air-gapped mode",
  "Community Forums",
  "Priority Enterprise Support",
];

/**
 * All possible types of plan codes on Recurly
 */
export type PlanCode =
  | LensTrialPlanCode
  | LensProPlanCode
  | LensPersonalPlanCode
  | ControlCenterPlanCode
  | LensEnterprisePlanCode;

export const isAssignablePlan = (planCode: string) => assignablePlanCodes.includes(planCode as PlanCode);
export const isControlCenterPlan = (planCode: string) =>
  planCode === "control-center-annual" || planCode === "control-center-monthly";
export const isLensTrialPlan = (planCode: any): planCode is LensTrialPlanCode => lensTrialPlanCodes.includes(planCode);
export const isLensProPlan = (planCode: any): planCode is LensProPlanCode =>
  planCode === "pro-yearly" || planCode === "pro-monthly";
export const isLensEnterprisePlan = (planCode: any): planCode is LensEnterprisePlanCode =>
  planCode === "enterprise-annual" || planCode === "enterprise-monthly";

/**
 * Get plan name from a `planCode`.
 */
export const getPlanName = (planCode?: string | null) => {
  switch (planCode) {
    case "pro-trial":
      return "Lens Pro (Trial)";
    case "pro-yearly":
      return "Lens Pro (Annual)";
    case "pro-monthly":
      return "Lens Pro (Monthly)";
    case "enterprise-monthly":
      return "Lens Enterprise (Monthly)";
    case "enterprise-annual":
      return "Lens Enterprise (Annual)";
    case "control-center-annual":
      return "Lens Control Center (Annual)";
    case "control-center-monthly":
      return "Lens Control Center (Monthly)";
    case "personal":
      return "Lens Personal";
    default:
      return "";
  }
};

/**
 * Temporary place for plans' text content, prices and features
 */
export const proMonthlyPrice = 24.9;
export const enterpriseMonthlyPrice = 99.9;
const enterpriseAnnualPrice = 999;
const proAnnualPrice = 249;

type Price = {
  text: string;
  value: number;
};
export type PlanMetadata = {
  type: "personal" | "pro" | "enterprise";
  nameShort: string;
  planSideNote: Array<string>;
  licenseName: string;
  licenseDescription: string;
  features: Array<string>;
  price: {
    monthly: Price;
    annual: Price;
  };
  quantity: {
    singular: string;
    plural: string;
  };
};
export const personalEligibilityText = "You or your company have less than $10M in annual revenue or funding.";
export const planMetadata: {
  personal: PlanMetadata;
  pro: PlanMetadata;
  proTrial: PlanMetadata;
  enterprise: PlanMetadata;
} = {
  personal: {
    type: "personal",
    nameShort: "Personal",
    planSideNote: ["Eligibility:", personalEligibilityText],
    licenseName: "Lens Personal",
    licenseDescription: "Start Lens experience with the free version of Lens Desktop.",
    features: ["Lens Desktop", "Community Forums"],
    price: {
      monthly: {
        text: "Free",
        value: 0,
      },
      annual: {
        text: "Free",
        value: 0,
      },
    },
    quantity: {
      singular: "seat",
      plural: "seats",
    },
  },
  pro: {
    type: "pro",
    nameShort: "Pro",
    planSideNote: ["Free 14-day trial available", "No credit card required."],
    licenseName: "Lens Pro",
    licenseDescription: "Complete solution for cloud native professionals.",
    features: proFeatures,
    price: {
      monthly: {
        text: formatCurrency(proMonthlyPrice),
        value: proMonthlyPrice,
      },
      annual: {
        text: formatCurrency(proAnnualPrice),
        value: proAnnualPrice,
      },
    },
    quantity: {
      singular: "seat",
      plural: "seats",
    },
  },
  proTrial: {
    type: "pro",
    nameShort: "Pro Trial",
    planSideNote: ["Free 14-day trial available", "No credit card required."],
    licenseName: "Lens Pro Trial",
    licenseDescription: "Complete solution for cloud native professionals.",
    features: proFeatures,
    price: {
      monthly: {
        text: formatCurrency(proMonthlyPrice),
        value: proMonthlyPrice,
      },
      annual: {
        text: formatCurrency(proAnnualPrice),
        value: proAnnualPrice,
      },
    },
    quantity: {
      singular: "seat",
      plural: "seats",
    },
  },
  enterprise: {
    type: "enterprise",
    nameShort: "Enterprise",
    planSideNote: [],
    licenseName: "Lens Enterprise",
    licenseDescription: "For enterprises seeking to deliver an enterprise-grade user experience.",
    features: enterpriseFeatures,
    price: {
      monthly: {
        text: formatCurrency(enterpriseMonthlyPrice),
        value: enterpriseMonthlyPrice,
      },
      annual: {
        text: formatCurrency(enterpriseAnnualPrice),
        value: enterpriseAnnualPrice,
      },
    },
    quantity: {
      singular: "seat",
      plural: "seats",
    },
  },
};

export const getPlanData = (planCode: PlanCode) => {
  switch (planCode) {
    case "pro-monthly":
    case "pro-yearly":
    case "pro-trial":
      return planMetadata.pro;
    case "enterprise-monthly":
    case "enterprise-annual":
      return planMetadata.enterprise;
    default:
      return planMetadata.personal;
  }
};

export const getPlanFeatureByLicenseType = (licenseType: keyof typeof LensDesktopLicenseType) => {
  switch (licenseType) {
    case "pro":
    case "proProd":
    case "proTrial":
      return planMetadata.pro.features;
    case "enterprise":
      return planMetadata.enterprise.features;
    default:
      return planMetadata.personal.features;
  }
};
export type PlanPriceData = {
  title: string;
  perText: string;
  perSeatText: string;
  value: number;
};

export const getPriceData = (planCode: PlanCode): PlanPriceData => {
  const planData = getPlanData(planCode);

  switch (planCode) {
    case "pro-monthly":
      return {
        title: getPlanName(planCode),
        perText: "per month",
        perSeatText: "per seat / month",
        value: planData.price.monthly.value,
      };
    case "pro-yearly":
      return {
        title: getPlanName(planCode),
        perText: "per year",
        perSeatText: "per seat / year",
        value: planData.price.annual.value,
      };
    default:
      return {
        title: getPlanName(planCode),
        perText: "per month",
        perSeatText: "per seat / month",
        value: 0,
      };
  }
};
