import { LensPlatformClient, LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useQuery } from "@tanstack/react-query";

export type UserEmails = Awaited<ReturnType<LensPlatformClient["user"]["getEmails"]>>;

type ValueOf<T> = T[keyof T];

export type EmailStatus = ValueOf<UserEmails>;

/**
 * Get user's emails
 */
export const useGetEmails = () => {
  const lensPlatformClient = useLensPlatformClient();

  const {
    isLoading,
    isFetching,
    error,
    data: emails,
    refetch,
  } = useQuery<UserEmails, LensSDKException>({
    queryKey: ["emails"],
    queryFn: () => lensPlatformClient.user.getEmails(),
    initialData: {},
  });

  return {
    loading: isLoading || isFetching,
    error,
    emails,
    reload: refetch,
  };
};
