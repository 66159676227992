import { useEffect, useState } from "react";
import { useLensPlatformClient } from "./useLensPlatformClient";

/**
 * Get the billing page token for subscription
 */
export const useSubscriptionBillingPageToken = (username: string | undefined, subscriptionId: string | undefined) => {
  const lensPlatformClient = useLensPlatformClient();

  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let cancelled = false;

    if (!username || !subscriptionId) {
      setToken(null);
      setError(null);
    } else {
      lensPlatformClient.user
        .getBillingPageTokenBySubscriptionId(username, subscriptionId)
        .then((billingPageToken) => {
          if (cancelled === false) {
            setToken(billingPageToken.hostedLoginToken);
            setError(null);
          }
        })
        .catch((error: any) => {
          if (cancelled === false) {
            setError(error);
            setToken(null);
          }
        });
    }

    return () => {
      cancelled = true;
      setToken(null);
    };
  }, [username, lensPlatformClient, subscriptionId]);

  return { error, token };
};
