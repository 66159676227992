import capitalize from "lodash/capitalize";
import { useHistory } from "react-router-dom";
import { getPlanFeatureByLicenseType } from "src/services/plans";
import { dateToString } from "src/utils/date";
import { misc, navigation } from "@k8slens/lds-icons";

import Button from "src/components/Button/TrackedButton";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

import { ActiveLensSubscription } from "./ActiveLensSubscription";
import { LicenseType, SubscriptionSeatWithBillingPeriod } from "./license";

import styles from "./ProSubscription.module.css";
import { useGetLicense } from "src/hooks/useGetLicense";
import { useMemo } from "react";
import { openUrl } from "src/utils/url";
import { useGetBillingInformation } from "src/hooks/useGetBillingInfo";
import { useGetSubscriptionSeats } from "src/hooks/useGetSubscriptionSeats";
import { useSubscriptionBillingUrls } from "src/hooks/useSubscriptionBillingUrls";
import { useUsername } from "src/hooks/useUsername";

const { CreditCardIcon, EventIcon } = misc;
const { RepeatIcon } = navigation;

const getBillingPeriodLabel = (
  license: ReturnType<typeof useGetLicense>,
  currentSubscriptionSeatWithBillingPeriod: SubscriptionSeatWithBillingPeriod | undefined,
  isCanceled: boolean,
) => {
  if (license?.type === LicenseType.proTrial) {
    return "No Automatic Renewal";
  }

  if (currentSubscriptionSeatWithBillingPeriod?.deactivatedAt !== null) {
    return "Deactivated subscription";
  }

  const label = isCanceled ? "Cancelled Subscription" : "Automatic Renewal";

  return `${label} (${capitalize(currentSubscriptionSeatWithBillingPeriod?.billingPeriod ?? "")})`;
};

const getBillingPeriodEndsLabel = (
  license: ReturnType<typeof useGetLicense>,
  currentSubscriptionSeatWithBillingPeriod: SubscriptionSeatWithBillingPeriod | undefined,
  isCanceled: boolean,
) => {
  if (license?.type === LicenseType.proTrial) {
    return "Trial Ends";
  }

  return isCanceled || currentSubscriptionSeatWithBillingPeriod?.deactivatedAt !== null
    ? "Valid Until"
    : "Next Renewal";
};

export function ProSubscription() {
  const license = useGetLicense();
  const username = useUsername();
  const history = useHistory();
  const { billingInfo } = useGetBillingInformation();
  const { currentSubscriptionSeatWithBillingPeriod } = useGetSubscriptionSeats();
  const subscriptionId = currentSubscriptionSeatWithBillingPeriod?.subscriptionId;
  const { billingInfoUrl, accountUrl } = useSubscriptionBillingUrls(username, subscriptionId);
  const handleLicenseChange = () => {
    if (!accountUrl) {
      history.push("/subscribe");

      return;
    }
    openUrl(accountUrl, true);
  };
  const companyName = currentSubscriptionSeatWithBillingPeriod?.companyName;
  const isCanceled = currentSubscriptionSeatWithBillingPeriod?.recurlySubscriptionState === "canceled";
  const isProTrial = license?.type === LicenseType.proTrial;
  const changeButtonLabel = isCanceled && !isProTrial ? "Reactivate Subscription" : "Change Lens Subscription";
  const paymentMethod = isProTrial ? "No Payment Method Required" : billingInfo?.paymentMethod?.cardType;
  const billingPeriodLabel = getBillingPeriodLabel(license, currentSubscriptionSeatWithBillingPeriod, isCanceled);
  const billingPeriodEndsLabel = getBillingPeriodEndsLabel(
    license,
    currentSubscriptionSeatWithBillingPeriod,
    isCanceled,
  );

  const licenseType = useMemo(() => {
    if (license?.type === LicenseType.proTrial) {
      return "proTrial";
    }

    if (license?.type === LicenseType.pro || license?.type === LicenseType.proProd) {
      return "pro";
    }

    if (license?.type === LicenseType.enterprise) {
      return "enterprise";
    }
  }, [license?.type]);

  const billing = (
    <div className={styles.billing}>
      <>
        <ul>
          <li>
            <CreditCardIcon size="sm" />
            {currentSubscriptionSeatWithBillingPeriod?.isBusinessAccount && companyName ? (
              <span>
                Paid By <span className={styles.companyName}>{companyName}</span>
              </span>
            ) : (
              paymentMethod
            )}
          </li>
          {currentSubscriptionSeatWithBillingPeriod?.billingPeriod && (
            <li>
              <RepeatIcon size="sm" />
              {billingPeriodLabel}
            </li>
          )}
          {currentSubscriptionSeatWithBillingPeriod?.currentPeriodEndsAt && (
            <li>
              <EventIcon size="sm" />
              {billingPeriodEndsLabel}:{" "}
              {dateToString(new Date(currentSubscriptionSeatWithBillingPeriod.currentPeriodEndsAt))}
            </li>
          )}
          {billingInfoUrl && license?.type !== LicenseType.proTrial && (
            <li>
              <TrackedAnchor className={styles.billingLink} href={billingInfoUrl}>
                Billing details
              </TrackedAnchor>
            </li>
          )}
        </ul>
      </>
      <div className={styles.buttons}>
        {isProTrial ? null : (
          <Button
            label="Cancel Subscription"
            primary
            discreet
            onClick={() => handleLicenseChange()}
            disabled={!accountUrl || isCanceled}
          />
        )}
        {(isCanceled || isProTrial) && (
          <Button
            label={changeButtonLabel}
            primary
            onClick={() => {
              handleLicenseChange();
            }}
          />
        )}
      </div>
    </div>
  );

  return licenseType ? (
    <ActiveLensSubscription
      licenseType={licenseType}
      features={getPlanFeatureByLicenseType(licenseType)}
      billing={billing}
    />
  ) : null;
}
