import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ButtonBar } from "@k8slens/lds";
import { navigation } from "@k8slens/lds-icons";
import { Listbox } from "@headlessui/react";
import clsx from "clsx";
import { Business } from "lens-platform-sdk";
import { type NotificationProps } from "@k8slens/lds/lib/es/Notification/Notification";

import Button from "src/components/Button/TrackedButton";
import LinkButton from "src/components/Button/TrackedRouterButton";
import CurrentUser from "src/components/CurrentUser/CurrentUser";

import { useBusinessChildInvitation } from "src/hooks/useBusinessChildInvitation";
import { useBusinessChildInvitationToken } from "src/hooks/useBusinessChildInvitationToken";
import { NewBusiness, useBusiness } from "src/hooks/useBusiness";
import { useParseInvitationError } from "src/hooks/useParseInvitationError";
import CreateBusinessModal from "src/components/MainNavigation/CreateBusinessModal";
import OpenModalButton from "src/components/MainNavigation/OpenModalButton";
import LBIDSelectItem from "src/components/LBIDSelect/LBIDSelectItem";
import Select from "src/components/Select/Select";
import IDSelectStyles from "src/components/LBIDSelect/LBIDSelect.module.css";
import PublicLayout from "src/components/PublicLayout/PublicLayout";

import styles from "./page.module.css";

const notificationProps: Partial<NotificationProps> = { level: "error" };
const panelProps = {
  contentProps: {
    className: styles.content,
  },
};

const { ArrowDownIcon } = navigation;

/**
 * The shape of the option data in the selector
 */
interface Option {
  id: Business["id"];
  label: Business["name"];
}

export const AcceptBusinessChildrenInvitation = () => {
  const parseInvitationError = useParseInvitationError();
  const history = useHistory();
  const { acceptChildInvitation, errorAcceptingChildInvitation, acceptingChildInvitationSuccess } =
    useBusinessChildInvitation();
  const token = useBusinessChildInvitationToken();
  const {
    businesses,
    loadingBusinesses,
    createBusiness,
    creatingBusiness,
    createBusinessSuccess,
    newlyCreatedBusiness,
    errorCreatingBusiness,
  } = useBusiness();

  const selectButtonRef = useRef<HTMLButtonElement>(null);

  const [selected, setSelected] = useState<Option>({
    id: "loading",
    label: "loading",
  });
  const options = useMemo<Option[]>(
    () =>
      businesses.length > 0
        ? businesses
            .map(({ id, name }) => ({ id, label: name }))
            .filter(({ id }) => id !== selected?.id)
            // sort by label alphabetically
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
    [businesses, selected],
  );

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const openModal = useCallback(() => {
    setCreateModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  // select the first business on mount
  useEffect(() => {
    if (businesses.length > 0 && selected.id === "loading") {
      setSelected({
        id: businesses[0].id,
        label: businesses[0].name,
      });
    }
  }, [businesses, selected]);

  useEffect(() => {
    if (newlyCreatedBusiness && createBusinessSuccess) {
      setSelected({
        id: newlyCreatedBusiness.id,
        label: newlyCreatedBusiness.name,
      });
    }
  }, [createBusinessSuccess, newlyCreatedBusiness]);

  const handleAccept = () => {
    if (!selected) {
      throw new Error("No business selected");
    }

    if (!token) {
      throw new Error("No token provided");
    }
    acceptChildInvitation({ token, childBusinessId: selected.id });
  };

  useEffect(() => {
    if (acceptingChildInvitationSuccess === true && selected?.id) {
      history.push(`/business/${selected.id}/account`);
    }
  }, [acceptingChildInvitationSuccess, selected, history]);

  return (
    <PublicLayout
      title="Accept Invitation"
      pageFooter={<CurrentUser />}
      panelProps={panelProps}
      footer={
        <>
          <LinkButton to="/home" label="Cancel" discreet />
          <Button
            label="Accept"
            onClick={handleAccept}
            disabled={loadingBusinesses || !selected || acceptingChildInvitationSuccess}
            loadingStateOnPromise
            primary
          />
        </>
      }
      notification={
        errorAcceptingChildInvitation ? parseInvitationError(errorAcceptingChildInvitation.message) : undefined
      }
      notificationProps={notificationProps}
    >
      <>
        {businesses.length > 0 ? (
          <>
            <p>
              You have been invited to join a Lens Business ID as a child account. Select or create a Lens Business ID
              to accept this invitation.
            </p>
            <div className={styles.lbidSelect}>
              <Select
                className={IDSelectStyles.select}
                onChange={(value) => {
                  setSelected(value);
                }}
                loading={loadingBusinesses}
                value={selected}
                options={options}
                renderButton={(item, open) => (
                  <Listbox.Button
                    ref={selectButtonRef}
                    className={clsx(
                      "lds-select--select-button",
                      {
                        [styles.selectOpen]: open,
                      },
                      styles.resetItem,
                    )}
                    data-testid="id-select-button"
                  >
                    <span className="lds-select--item">
                      <LBIDSelectItem item={item} />
                    </span>
                    <ArrowDownIcon className={IDSelectStyles.dropDownIcon} />
                  </Listbox.Button>
                )}
                buttonRef={selectButtonRef}
                optionClassName={IDSelectStyles.selectItem}
                optionsClassName={styles.optionsReset}
                renderOption={(option) => (
                  <span className="lds-select--item">
                    <LBIDSelectItem item={option} />
                  </span>
                )}
                renderAfterLastOption={() => <OpenModalButton handleClick={() => openModal()} />}
                renderEmptyOption={() => (
                  <OpenModalButton
                    handleClick={() => {
                      openModal();
                    }}
                  />
                )}
              />
            </div>
          </>
        ) : (
          <>
            <p>
              You have been invited to join a Lens Business ID as a child account. You do not have any Lens Businesses
              ID yet. Please create a Lens Business ID to accept this invitation.{" "}
            </p>
            <ButtonBar type="grid" className={styles.createLbidButton}>
              <Button label="Create Lens Business ID" onClick={() => openModal()} primary />
            </ButtonBar>
          </>
        )}
        <CreateBusinessModal
          open={createModalOpen}
          disabled={creatingBusiness}
          loading={creatingBusiness}
          error={errorCreatingBusiness}
          onModalClose={() => {
            closeModal();
          }}
          onClickCreate={(business) => {
            createBusiness(business as NewBusiness);
            closeModal();
          }}
        />
      </>
    </PublicLayout>
  );
};
