import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ turns retries off in tests to avoid timeout/flakiness
      retry: process.env.NODE_ENV !== "test",
    },
  },
});
