import type { CellContext, ColumnMeta } from "@tanstack/react-table";

import styles from "./StateCell.module.css";

const StateCell = <T extends {}, R extends string>({ getValue }: CellContext<T, R>) => (
  <span className={styles[getValue()] ?? styles.defaultState}>{getValue()}</span>
);

export const stateCellMeta: ColumnMeta<unknown, unknown> | undefined = {};

export const stateColDef = {
  meta: stateCellMeta,
  header: () => <span>State</span>,
  cell: StateCell,
};

export default StateCell;
