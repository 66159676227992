import { useContext, useState, useCallback, useEffect, ReactNode } from "react";
import {
  LensSDKException,
  type UserBusinessRole,
  type BusinessJoinRequest,
  type BusinessInvitation,
} from "lens-platform-sdk";
import { type BusinessUserWithSeats } from "lens-platform-sdk/dist/cjs/BusinessService";
import { LoadingIndicator, NumberBadge, Notification } from "@k8slens/lds";
import { base } from "@k8slens/lds-icons";
import { Redirect, Route, useHistory, Switch } from "react-router-dom";

import { useAnalytics } from "src/hooks/useAnalytics";
import { useBusinessUsers } from "src/hooks/useBusinessUsers";
import { getUniqueEmailsArray } from "src/services/email";
import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";
import { useGetBusinessInvitations } from "src/hooks/useGetBusinessInvitations";
import { useDeleteBusinessInvitation } from "src/hooks/useDeleteBusinessInvitation";
import { useSubscriptionSeatCount } from "src/hooks/useSubscriptionSeatCount";
import { useBusinessIdParam } from "src/hooks/useBusinessIdParam";

import { BusinessContext } from "src/components/Business/Base";
import { TabGroup, TabList, Tab } from "src/components/Tabs/Tabs";
import PageHeader from "src/components/PageHeader/PageHeader";
import PageAction from "src/components/PageAction/PageAction";

import { AutomaticSeatAssignmentModal } from "./AutomaticSeatAssignmentModal";
import { AddUsersModal } from "./AddUsersModal";
import Pending from "./Pending";
import Requests from "./Requests";
import Users from "./Users";

import styles from "./page.module.css";

const { InfoIcon } = base;

const inviteUsersLabel = "Invite Users";
const noSeatsText = "No seats";

const isPendingUser = ({ state }: BusinessUserWithSeats) => state === "pending";

const filterActive = (users: Array<BusinessUserWithSeats>) => users.filter((user) => !isPendingUser(user));
const filterPending = (invitations: Array<BusinessInvitation>) =>
  invitations.filter(({ state }) => state === "pending");

const views: Array<{
  key: string;
  title: string;
  notifications(d: {
    users: Array<BusinessUserWithSeats>;
    requests: Array<BusinessJoinRequest>;
    invitations: Array<BusinessInvitation>;
  }): ReactNode | null;
  path(businessId: string): string;
}> = [
  {
    key: "active",
    title: "Active",
    notifications: ({ users }) => {
      const n = filterActive(users).length;

      return n ? ` (${n})` : null;
    },
    path: (businessId) => `/business/${businessId}/users/active`,
  },
  {
    key: "requests",
    title: "Requests",
    notifications: ({ requests }) => (
      <NumberBadge
        className="ml-md"
        type="error"
        value={requests.length}
        title={`${requests.length} pending request(s).`}
      />
    ),
    path: (businessId) => `/business/${businessId}/users/requests`,
  },
  {
    key: "pending",
    title: "Pending",
    notifications: ({ invitations }) => {
      const n = filterPending(invitations).length;

      return n ? ` (${n})` : null;
    },
    path: (businessId) => `/business/${businessId}/users/pending`,
  },
];

const BusinessUsers = () => {
  const history = useHistory();
  const { trackButtonClicked } = useAnalytics();
  const businessId = useBusinessIdParam();
  const {
    business,
    pendingRequests: { requests, updateRequestAsync: updateRequest, errorUpdatingRequest },
  } = useContext(BusinessContext);

  const {
    businessUsers,
    loading: loadingBusinessUsers,
    error: errorLoadingBusinessUsers,
    createInvitesAsync: addBusinessUsers,
    errorCreatingInvites: errorAddingBusinessUsers,
    creatingInvites: addingBusinessUsers,
  } = useBusinessUsers(businessId);

  const { loading: loadingBusinessSubscriptions, reload: reloadBusinessSubscriptions } =
    useGetBusinessSubscriptions(businessId);
  const {
    businessInvitations,
    loading: loadingBusinessInvitations,
    reload: reloadBusinessInvitations,
  } = useGetBusinessInvitations(businessId);

  const { deleteBusinessInvitation, loading: deleteBusinessInvitationLoading } = useDeleteBusinessInvitation();

  const { seatCount } = useSubscriptionSeatCount(businessId);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [addUsersModalOpen, setAddUsersModalOpen] = useState(false);
  const openAddUsersModal = useCallback(() => {
    trackButtonClicked(inviteUsersLabel);
    setAddUsersModalOpen(true);
  }, [trackButtonClicked]);

  const closeAddUsersModal = useCallback(() => {
    setAddUsersModalOpen(false);
  }, []);

  const handleRemoveBusinessInvitation = async (invitation: BusinessInvitation) => {
    if (!businessId || !invitation) {
      return;
    }
    await deleteBusinessInvitation(businessId, invitation.id, "link" in invitation);
    await reloadBusinessInvitations();
  };

  const handleAddUsers = useCallback(
    async (emails: string, subscriptionId: string | undefined, role: UserBusinessRole) => {
      const emailArray = getUniqueEmailsArray(emails);

      try {
        await addBusinessUsers(emailArray.map((email) => ({ email, role, subscriptionId })));

        await Promise.all([reloadBusinessSubscriptions(), reloadBusinessInvitations()]);
        closeAddUsersModal();

        // Set active tab to "Pending" to show assigned seat.
        if (activeTabIndex !== 2) {
          setActiveTabIndex(2);
        }

        if (businessId) {
          const path = views.find(({ key }) => key === "pending")?.path(businessId);

          if (path) {
            history.push(path);
          }
        }

        return null;
      } catch (err) {
        return err as LensSDKException;
      }
    },
    [
      activeTabIndex,
      addBusinessUsers,
      businessId,
      closeAddUsersModal,
      history,
      reloadBusinessInvitations,
      reloadBusinessSubscriptions,
    ],
  );

  // Keep tabs up to date with the URL
  useEffect(() => {
    const tab = views.find((view) => businessId && view.path(businessId) === history.location.pathname);

    if (tab) {
      setActiveTabIndex(views.indexOf(tab));
    }
  }, [history.location.pathname, businessId]);

  const handleTabChange = useCallback(
    (index: number) => {
      if (!businessId) {
        return;
      }
      setActiveTabIndex(index);

      const tab = views[index];

      history.push(tab.path(businessId));
    },
    [history, businessId],
  );

  const activeUsers = filterActive(businessUsers);
  const pendingUsers = filterPending(businessInvitations);

  return (
    <>
      <section className={styles.manageUsers}>
        <PageHeader
          title="Manage Users"
          className={styles.header}
          headingContent={
            loadingBusinessSubscriptions || loadingBusinessInvitations ? (
              <div className={styles.seatInfo} aria-label="Loading">
                <LoadingIndicator />
              </div>
            ) : (
              <div
                className={styles.seatInfo}
                aria-label={
                  seatCount.total === 0 ? noSeatsText : `${seatCount.inUse} out of ${seatCount.total} Lens seats in use`
                }
              >
                {seatCount.total === 0 ? (
                  <span className={styles.noSeats} aria-hidden title="Get Seats by clicking the Purchase button">
                    {noSeatsText}
                    <InfoIcon size="sm" />
                  </span>
                ) : (
                  <>
                    <span className={styles.seatCount} aria-hidden>{`${seatCount.inUse} / ${seatCount.total}`}</span>
                    <span aria-hidden>Lens seats in use</span>
                  </>
                )}
              </div>
            )
          }
        />
        <div>
          {(errorLoadingBusinessUsers || errorUpdatingRequest) && (
            <Notification
              type="flash"
              level="error"
              message={errorLoadingBusinessUsers?.message || errorUpdatingRequest?.message}
            />
          )}
          <TabGroup defaultIndex={0} selectedIndex={activeTabIndex} onChange={handleTabChange}>
            <div className={styles.tabs}>
              <span className={styles.tableSubTitle}>Manage your Lens Business ID users.</span>
              <TabList className={styles.tableTabButtonBar}>
                {views.map((view) => (
                  <Tab
                    key={view.key}
                    label={
                      <>
                        {view.title}
                        {view.notifications({
                          users: businessUsers,
                          requests: requests || [],
                          invitations: businessInvitations,
                        })}
                      </>
                    }
                  />
                ))}
              </TabList>
            </div>
          </TabGroup>
          <Switch>
            <Route path="/business/:businessId/users/active">
              <Users
                caption="Active Users"
                businessUsers={activeUsers}
                loading={loadingBusinessUsers}
                notDataText="No active users on your Lens Business ID!"
              />
            </Route>
            <Route path="/business/:businessId/users/requests">
              {updateRequest ? <Requests requests={requests} updateRequest={updateRequest} /> : null}
            </Route>
            <Route path="/business/:businessId/users/pending">
              <Pending
                businessInvitations={pendingUsers}
                loading={loadingBusinessUsers || deleteBusinessInvitationLoading}
                onRemoveBusinessInvitation={handleRemoveBusinessInvitation}
              />
            </Route>
            <Route>
              {({ match }) => {
                return <Redirect to={`/business/${match?.params.businessId}/users/active`} />;
              }}
            </Route>
          </Switch>
          <PageAction
            label={inviteUsersLabel}
            buttonProps={{
              onClick: () => {
                openAddUsersModal();
              },
            }}
          />
        </div>
      </section>
      {business?.automaticSeatAssignment ? (
        <AutomaticSeatAssignmentModal
          open={addUsersModalOpen}
          adding={addingBusinessUsers}
          errorAdding={errorAddingBusinessUsers}
          loadingBusinessInvitations={loadingBusinessInvitations}
          onModalClose={closeAddUsersModal}
          addUsers={handleAddUsers}
        />
      ) : (
        <AddUsersModal
          open={addUsersModalOpen}
          adding={addingBusinessUsers}
          errorAdding={errorAddingBusinessUsers}
          businessInvitations={businessInvitations}
          loadingBusinessInvitations={loadingBusinessInvitations}
          onModalClose={closeAddUsersModal}
          addUsers={handleAddUsers}
        />
      )}
    </>
  );
};

export default BusinessUsers;
