import type { Business } from "lens-platform-sdk";
import { LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

type FilterBy = (business: Business) => boolean;

/**
 * Get user's business entities (Lens Business IDs)
 */
export const useGetBusinesses = (filterBy?: FilterBy) => {
  const lensPlatformClient = useLensPlatformClient();

  const {
    isLoading,
    error,
    data: businesses,
    refetch,
    isFetched,
  } = useQuery<Business[], LensSDKException>({
    queryKey: ["businesses"],
    queryFn: () => lensPlatformClient.business.getMany(),
    initialData: [],
  });

  const filteredBusinesses = useMemo(() => {
    if (filterBy) {
      return businesses.filter(filterBy);
    }

    return businesses;
  }, [businesses, filterBy]);

  return {
    loading: isLoading,
    error,
    businesses: filteredBusinesses,
    reload: refetch,
    isFetched,
  };
};
