import { useEffect } from "react";
import { pricingUrl } from "src/constants";
import { openUrl } from "src/utils/url";

export function SubscribePage() {
  useEffect(() => {
    openUrl(pricingUrl);
  }, []);

  return null;
}
