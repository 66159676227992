import { SSOType } from "lens-platform-sdk";

import styles from "./BusinessSSOInfo.module.css";
import { BusinessContext } from "./Base";
import { useContext, useMemo } from "react";
import { useBusinessSSO } from "src/hooks/useBusinessSSO";

export const BusinessSSOInfo = () => {
  const { businessId, business } = useContext(BusinessContext);
  const { businessSSO } = useBusinessSSO(businessId);
  const verifiedDomains = useMemo(() => business?.verifiedDomains || [], [business?.verifiedDomains]);
  const hasVerifiedDomains = verifiedDomains.length > 0;

  if (!businessSSO) {
    return null;
  }

  const { config } = businessSSO;

  return (
    <>
      {config.type === SSOType.SAML && (
        <div className={styles.ssoInfoSection}>
          <dl className={styles.ssoInfo}>
            {hasVerifiedDomains ? (
              <dt aria-label="Verified Domain">
                <strong>Verified Domain:</strong>
              </dt>
            ) : null}
            {verifiedDomains.map(({ domain }) => (
              <dd aria-label={domain} className={styles.verifiedDomainDD}>
                <span className={styles.ssoInfoText} title={domain}>
                  {domain}
                </span>
              </dd>
            ))}
            <dt aria-label="SSO Service URL">
              <strong>SSO Service URL:</strong>
            </dt>
            <dd aria-label={config.singleSignOnServiceUrl!}>{config.singleSignOnServiceUrl}</dd>
            <dt>
              <strong>Identity Provider EntityID:</strong>
            </dt>
            <dd aria-label={config.idpEntityId!}>{config.idpEntityId}</dd>
          </dl>
        </div>
      )}
      {config.type === SSOType.OIDC && (
        <div className={styles.ssoInfoSection}>
          <dl className={styles.ssoInfo}>
            {hasVerifiedDomains ? (
              <dt aria-label="Verified Domain">
                <strong>Verified Domain:</strong>
              </dt>
            ) : null}
            {verifiedDomains.map(({ domain }) => (
              <dd aria-label={domain}>
                <span className={styles.ssoInfoText} title={domain}>
                  {domain}
                </span>
              </dd>
            ))}
            <dt>
              <strong>Client ID</strong>
            </dt>
            <dd aria-label={config.clientId!}>{config.clientId}</dd>
            <dt aria-label="Authorization URL">
              <strong>Authorization URL</strong>
            </dt>
            <dd aria-label={config.authorizationUrl!}>{config.authorizationUrl}</dd>
            <dt>
              <strong>Token URL</strong>
            </dt>
            <dd aria-label={config.tokenUrl!}>{config.tokenUrl}</dd>
            <dt>
              <strong>JWKS URL</strong>
            </dt>
            <dd aria-label={config.jwksUrl!}>{config.jwksUrl}</dd>
            <dt>
              <strong>User Info URL</strong>
            </dt>
            <dd aria-label={config.userInfoUrl!}>{config.userInfoUrl}</dd>
            {config.logoutUrl ? (
              <>
                <dt>
                  <strong>Logout URL</strong>
                </dt>
                <dd aria-label={config.logoutUrl!}>{config.logoutUrl}</dd>
              </>
            ) : null}
            <dt>
              <strong>Issuer</strong>
            </dt>
            <dd aria-label={config.issuer!}>{config.issuer}</dd>
          </dl>
        </div>
      )}
    </>
  );
};
