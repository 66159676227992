import type { BusinessSubscription } from "lens-platform-sdk";

export type UsedSeat = BusinessSubscription["usedSeats"][number];

export type ActiveSeat = UsedSeat & { deactivatedAt: null; subscription?: BusinessSubscription };

/**
 * @returns true if the `usedSeat` hasn't been deactivated (`deactivatedAt === null`)
 */
export const isActiveSeat = (usedSeat: UsedSeat): usedSeat is ActiveSeat => usedSeat.deactivatedAt === null;
