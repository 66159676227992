import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { createColumnHelper, useReactTable, getCoreRowModel, getSortedRowModel } from "@tanstack/react-table";
import { action } from "@k8slens/lds-icons";

import { type ChildBusiness, useBusinessChildren } from "src/hooks/useBusinessChildren";
import { useBusiness } from "src/hooks/useBusiness";
import { useTrackedTableSort } from "src/hooks/useTrackedTableSort";

import Table from "src/components/Table/Table";
import { ContextMenuItem } from "src/components/ContextMenu/ContextMenu";
import { AvatarTableCell } from "src/components/TableCells/AvatarTableCell";
import { subscriptionIdColDef } from "src/components/TableCells/SubscriptionIdCell";
import ContextMenuCell, { contextMenuColDef } from "src/components/TableCells/ContextMenuCell";

const { DeleteIcon } = action;

const activeChildrenTableColumnHelper = createColumnHelper<ChildBusiness>();

const caption = "Active children accounts";

export const ActiveChildrenTable = () => {
  const { businessId: parentBusinessId } = useParams<{ businessId: string }>();
  const { businessChildren, loadingBusinessChildren, removeOneBusinessChild } = useBusinessChildren(parentBusinessId);
  const { businesses } = useBusiness();
  const history = useHistory();

  const activeChildrenTableColumns = useMemo(
    () => [
      activeChildrenTableColumnHelper.accessor("id", {
        ...subscriptionIdColDef,
        meta: { ...subscriptionIdColDef.meta, text: "tertiary" },
        header: () => <span>ID</span>,
      }),
      activeChildrenTableColumnHelper.accessor("name", {
        header: () => <span>Lens Business ID</span>,
        cell: ({ getValue, row }) => {
          const isAdmin = businesses.find((business) => business.id === row.original.id) !== undefined;

          return (
            <AvatarTableCell
              name={getValue() ?? "N/A"}
              subtitle="Lens Business ID"
              onClick={() => {
                if (isAdmin) {
                  history.push(`/business/${row.original.id}`);
                }
              }}
            />
          );
        },
      }),
      activeChildrenTableColumnHelper.accessor("department", {
        header: () => <span>Department</span>,
        cell: ({ getValue }) => <div>{getValue() ?? "N/A"}</div>,
        meta: { text: "tertiary" },
      }),
      activeChildrenTableColumnHelper.accessor("email", {
        header: () => <span>Contact</span>,
        cell: ({ getValue }) => <div>{getValue() ?? "N/A"}</div>,
        meta: { text: "tertiary" },
      }),
      activeChildrenTableColumnHelper.accessor("id", {
        ...contextMenuColDef,
        cell: ({ getValue }) => (
          <ContextMenuCell toggleButtonProps={{ title: "Toggle context menu for the child" }}>
            <ContextMenuItem
              label="Remove"
              icon={DeleteIcon}
              onClick={() => removeOneBusinessChild({ parentBusinessId, childBusinessId: getValue() })}
            />
          </ContextMenuCell>
        ),
      }),
    ],
    [parentBusinessId, removeOneBusinessChild, businesses, history],
  );

  const { sorting, onSortingChange } = useTrackedTableSort({ table: caption });

  const activeBusinessChildrenTable = useReactTable({
    data: businessChildren,
    columns: activeChildrenTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange,
  });
  const activeBusinessChildrenRowModel = activeBusinessChildrenTable.getRowModel();
  const activeBusinessChildrenRows = useMemo(
    () => activeBusinessChildrenRowModel.rows,
    [activeBusinessChildrenRowModel],
  );

  return (
    <Table<ChildBusiness>
      caption={caption}
      loading={loadingBusinessChildren}
      rows={activeBusinessChildrenRows}
      columns={activeBusinessChildrenTable.getAllColumns()}
      headerGroups={activeBusinessChildrenTable.getHeaderGroups()}
      noDataText="No active children accounts"
    />
  );
};
