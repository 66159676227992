import { PropsWithChildren } from "react";

import TrackedLink from "src/components/TrackedLink/TrackedLink";

import { base } from "@k8slens/lds-icons";
import styles from "./Page.module.css";

const { LogoIcon } = base;

type PageProps = {
  showLogo?: boolean;
};

const Page = ({ children, showLogo = true }: PropsWithChildren<PageProps>) => (
  <div className={styles.page}>
    {showLogo && (
      <TrackedLink to="/" title="Lens ID">
        <h1 className={styles.logo}>
          <LogoIcon color="accent" />
          <span className={styles.logoText}>Lens ID</span>
        </h1>
      </TrackedLink>
    )}
    {children}
  </div>
);

export default Page;
