export class SilentRenewError extends Error {
  constructor(cause: Error) {
    super(cause.message);
    this.cause = cause;
    Object.setPrototypeOf(this, SilentRenewError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SilentRenewError);
    }
  }
}

export class TokenExpiredError extends Error {
  constructor(cause?: Error) {
    super(cause?.message ?? "Token expired");
    this.cause = cause;
    Object.setPrototypeOf(this, TokenExpiredError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TokenExpiredError);
    }
  }
}

export class SignInRedirectError extends Error {
  constructor(cause: unknown) {
    super(cause instanceof Error ? cause?.message : "Sign in redirect failed");
    this.cause = cause;
    Object.setPrototypeOf(this, SignInRedirectError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SignInRedirectError);
    }
  }
}

export class LogOutRedirectError extends Error {
  constructor(cause: unknown) {
    super(cause instanceof Error ? cause?.message : "Log out redirect failed");
    this.cause = cause;
    Object.setPrototypeOf(this, LogOutRedirectError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, LogOutRedirectError);
    }
  }
}

export class NoMatchingStateInStorageError extends Error {
  constructor(cause: Error) {
    super(cause.message);
    this.cause = cause;
    Object.setPrototypeOf(this, NoMatchingStateInStorageError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NoMatchingStateInStorageError);
    }
  }
}

export class LogInRequiredError extends Error {
  constructor(cause?: unknown) {
    let message = "Login required";

    if (cause instanceof Error) {
      message = cause.message;
    }

    if (typeof cause === "string") {
      message = cause;
    }
    super(message);
    this.cause = cause;
    Object.setPrototypeOf(this, LogInRequiredError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, LogInRequiredError);
    }
  }
}

export class UnknownAuthParamError extends Error {
  constructor(cause?: unknown) {
    let message = "Unknown auth param error";

    if (cause instanceof Error) {
      message = cause.message;
    }

    if (typeof cause === "string") {
      message = cause;
    }
    super(message);
    this.cause = cause;
    Object.setPrototypeOf(this, UnknownAuthParamError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnknownAuthParamError);
    }
  }
}

export class UserSessionChangedError extends Error {
  constructor(cause?: unknown) {
    let message = "User session changed";

    if (cause instanceof Error) {
      message = cause.message;
    }

    if (typeof cause === "string") {
      message = cause;
    }
    super(message);
    this.cause = cause;
    Object.setPrototypeOf(this, UserSessionChangedError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UserSessionChangedError);
    }
  }
}

export class InvalidResponseError extends Error {
  constructor(cause?: unknown) {
    let message = "Invalid response";

    if (cause instanceof Error) {
      message = cause.message;
    }

    if (typeof cause === "string") {
      message = cause;
    }
    super(message);
    this.cause = cause;
    Object.setPrototypeOf(this, InvalidResponseError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidResponseError);
    }
  }
}
