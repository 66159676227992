import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";

export interface DrawerTransitionProps extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean;
  duration?: number;
  afterEnter?: () => void;
  afterExit?: () => void;
  // Changes in dependencies trigger re-calculation of the drawer dimensions
  deps?: object;
}

const DrawerTransition: React.FC<PropsWithChildren<DrawerTransitionProps>> = ({
  open,
  deps = {},
  duration = 250,
  afterEnter,
  afterExit,
  children,
  ...props
}) => {
  const contentRef = useRef<null | HTMLDivElement>(null);
  const [currentTimeout, setCurrentTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [state, setState] = useState<"entering" | "exiting" | "exited" | "entered">("exited");
  const [height, setHeight] = useState<string>();

  const transitionStyle = useMemo(
    () => ({
      transitionDuration: `${duration}ms`,
      transitionTimingFunction: "ease-in-out",
      height: state === "entering" || state === "entered" ? height : "0px",
      opacity: state === "entering" || state === "entered" ? 1 : 0,
    }),
    [duration, state, height],
  );

  const handleResize = useCallback(() => {
    if (contentRef.current) {
      // Store element style to be used in full-height style tag
      setHeight(`${contentRef.current.clientHeight}px`);
    }
  }, [contentRef]);

  const handleEnter = useCallback(() => {
    setState("entering");

    return setTimeout(() => {
      try {
        setState("entered");

        if (afterEnter) {
          afterEnter();
        }
      } catch (err) {
        // ignore
      }
    }, duration);
  }, [duration, afterEnter]);

  const handleExit = useCallback(() => {
    setState("exiting");

    return setTimeout(() => {
      try {
        setState("exited");

        if (afterExit) {
          afterExit();
        }
      } catch (err) {
        // ignore
      }
    }, duration);
  }, [duration, afterExit]);

  // Keep up with open state
  useEffect(() => {
    if (open && (state === "exited" || state === "exiting")) {
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }
      setCurrentTimeout(handleEnter());
    } else if (!open && (state === "entering" || state === "entered")) {
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }
      setCurrentTimeout(handleExit());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, state, handleEnter, handleExit]);

  // Keep up with window resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  // Keep up element content changes
  useEffect(() => {
    handleResize();
  }, [contentRef, handleResize, deps]);

  return (
    <div
      aria-hidden={!open}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      style={transitionStyle}
      className={clsx("transition-all overflow-y-hidden", props.className)}
    >
      <div ref={contentRef}>{children}</div>
    </div>
  );
};

export default DrawerTransition;
