import { useEffect, useState } from "react";
import { BillingInfo } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useUsername } from "./useUsername";

/**
 * Get user billing information
 */
export const useGetBillingInformation = () => {
  const lensPlatformClient = useLensPlatformClient();
  const username = useUsername();

  const [billingInfo, setBillingInfo] = useState<BillingInfo>();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let cancelled = false;

    if (username) {
      setLoading(true);

      lensPlatformClient.user
        .getUserBillingInformation(username)
        .then((info: BillingInfo) => {
          if (cancelled === false) {
            setBillingInfo(info);
          }
        })
        .catch((error: any) => {
          if (cancelled === false) {
            setError(error);
            setBillingInfo(undefined);
          }
        })
        .finally(() => {
          if (cancelled === false) {
            setLoading(false);
          }
        });
    }

    return () => {
      cancelled = true;
    };
  }, [username, lensPlatformClient]);

  return { error, billingInfo, loading };
};
