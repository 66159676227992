/**
 * Trim an uuid string into "first 6 chars ...last 6 chars"
 */
export const trimUUID = (uuid?: string) => {
  const length = uuid?.length ?? 0;

  if (length > 13) {
    return `${uuid?.substring(0, 6)}...${uuid?.substring(length - 6, length)}`;
  }

  return uuid ?? "";
};
