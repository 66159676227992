import type { FileExtensions } from "src/services/downloadURI";
import { OSFileExtensionsPairs } from "src/services/downloadURI";
import { useOS } from "./useOS";

/**
 * Get file extension by OS
 * or returns a URL to download page if can't detect OS
 */
export const useFileExtension = (): FileExtensions => {
  const os = useOS();

  return OSFileExtensionsPairs[os].fileExtension;
};
