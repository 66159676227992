import {
  type BusinessUserSubscriptionSeat,
  type BusinessUserWithSeats,
} from "lens-platform-sdk/dist/cjs/BusinessService";
import { ContextMenuItem } from "src/components/ContextMenu/ContextMenu";
import ContextMenuCell from "src/components/TableCells/ContextMenuCell";
import { useChangeBusinessUserRole } from "src/hooks/useChangeBusinessUserRole";
import { BusinessContext } from "src/components/Business/Base";
import { useDeleteBusinessUser } from "src/hooks/useDeleteBusinessUser";
import { useDeActivateBusinessUserSubscription } from "src/hooks/useDeActivateBusinessUserSubscription";
import { useContext } from "react";
import { getUserSubscriptions } from "src/services/getUserSubscription";

import { useConfirmDialog } from "src/components/ConfirmDialog/ConfirmDialog";
import { nonExpiredSeats } from "src/services/nonExpiredSeats";
import { lastestActivatedAtBusinessUserSubscriptionSeat } from "src/services/lastestActivatedAtSeat";
import { useAuth } from "src/hooks/useAuth";

type Props = {
  user: BusinessUserWithSeats;
  userSubscriptions: ReturnType<typeof getUserSubscriptions>;
  businessHasOnlyOneAdmin: boolean;
  businessHasAvailableSeats: boolean;
  openAssignSubscriptionModal: (email: string) => void;
};

export const UserContextMenu = ({
  user,
  userSubscriptions,
  businessHasOnlyOneAdmin,
  businessHasAvailableSeats,
  openAssignSubscriptionModal,
}: Props) => {
  const auth = useAuth();
  const { businessId } = useContext(BusinessContext);
  const { changeBusinessUserRole } = useChangeBusinessUserRole(businessId);
  const { deleteBusinessUser } = useDeleteBusinessUser(businessId);
  const { deActivateBusinessUserSubscription } = useDeActivateBusinessUserSubscription(businessId);
  const { ConfirmDialog, confirm } = useConfirmDialog();
  const seats = user.seats.filter(nonExpiredSeats);
  const latestActivatedAtSeat: undefined | BusinessUserSubscriptionSeat = seats.reduce(
    lastestActivatedAtBusinessUserSubscriptionSeat,
    seats[0],
  );
  const isOfflineSeat = Boolean(latestActivatedAtSeat?.offline);
  const userHasSeat = Boolean(latestActivatedAtSeat);
  const userIsTheOnlyAdmin = user.role === "Administrator" && businessHasOnlyOneAdmin;

  const businessUserId = user.businessUserId;
  const userSubscriptionId = userSubscriptions[0]?.usedSeats.find((usedSeat) => usedSeat.user?.id === user.id)?.id;
  const username = user.username;
  const canPromoteToAdmin = user.role === "Member" && typeof businessUserId === "string";

  const isSelf = auth?.tokenParsed?.sub === user.id;
  const canRevokeAdmin =
    user.role === "Administrator" && !businessHasOnlyOneAdmin && typeof businessUserId === "string" && !isSelf;
  const canAssignSubscription = !userHasSeat && businessHasAvailableSeats;
  const canUnassignSubscription = userHasSeat && typeof userSubscriptionId === "string" && typeof username === "string";
  // can't remove the user if it's the only admin or has a subscription seat (has to unassign the seat first)
  const canRemoveUser = !userHasSeat && !userIsTheOnlyAdmin && typeof businessUserId === "string" && !isSelf;
  const canDoSomething =
    canPromoteToAdmin || canRevokeAdmin || canAssignSubscription || canUnassignSubscription || canRemoveUser;

  if (!canDoSomething) {
    // not returning null to avoid layout shifts
    return <ContextMenuCell toggleButtonProps={{ disabled: true }}></ContextMenuCell>;
  }

  return (
    <>
      <ContextMenuCell toggleButtonProps={{ title: "Toggle context menu" }}>
        {canPromoteToAdmin ? (
          <ContextMenuItem
            label="Promote to Admin"
            onClick={() => {
              changeBusinessUserRole({ businessUserId, role: "Administrator" });
            }}
          />
        ) : null}
        {canRevokeAdmin ? (
          <ContextMenuItem
            label="Revoke Admin"
            onClick={() => {
              changeBusinessUserRole({ businessUserId, role: "Member" });
            }}
          />
        ) : null}
        {canAssignSubscription ? (
          <ContextMenuItem
            label="Assign Subscription"
            onClick={() => {
              if (!user.id) {
                throw new Error("User id is missing");
              }
              openAssignSubscriptionModal(user.id);
            }}
          />
        ) : null}
        {canUnassignSubscription ? (
          <ContextMenuItem
            label="Un-assign Subscription"
            onClick={() => {
              if (isOfflineSeat) {
                confirm(
                  "Are you sure you want to un-assign an offline seat?",
                  <p>
                    The associated Lens Subscription of the user will be fixed until the end of the billing cycle and
                    you cannot re-assign the seat to other users.
                  </p>,
                  { confirmText: "Confirm" },
                ).then((confirmed) => {
                  if (confirmed) {
                    deActivateBusinessUserSubscription({ businessSubscriptionId: userSubscriptionId, username });
                  }
                });
              } else {
                deActivateBusinessUserSubscription({ businessSubscriptionId: userSubscriptionId, username });
              }
            }}
          />
        ) : null}
        {canRemoveUser ? (
          <ContextMenuItem
            label="Remove"
            onClick={() => {
              confirm(
                "Are you sure you want to remove this user?",
                <p>The user will need to accept an invitation to rejoin the business.</p>,
                { confirmText: "Remove" },
              ).then((confirmed) => {
                if (confirmed) {
                  deleteBusinessUser({ businessUserId });
                }
              });
            }}
            buttonType="reset"
            buttonDiscreet
            buttonClassName={"border-none"}
          />
        ) : null}
      </ContextMenuCell>
      {ConfirmDialog}
    </>
  );
};
