import type { BusinessSubscription } from "lens-platform-sdk";
import { isActiveSeat } from "src/services/getSubscriptionSeatState";

interface SubscriptionSeatCount {
  /**
   * Total number of seats aggregated from all business's subscriptions
   */
  total: BusinessSubscription["seats"];
  /**
   * `total` - `inUse` = `available`
   */
  available: number;
  /**
   * The number of seats that is with `deactivatedAt === null` condition OR `offline === true` and
   */
  inUse: number;
}

/**
 * Get seats count of a subscription
 *
 * @returns a `SubscriptionSelectOption` object @see {@link SubscriptionSeatCount}
 */
export const getSubscriptionSeatCount = (
  businessSubscriptions?: BusinessSubscription | Array<BusinessSubscription>,
) => {
  const subscriptionSeatCount: SubscriptionSeatCount = (
    Array.isArray(businessSubscriptions) ? businessSubscriptions : [businessSubscriptions]
  ).reduce(
    (accumulator, subscription) => {
      const total = subscription?.seats ?? 0;
      const usedSeats = subscription?.usedSeats.length ?? 0;
      const active = subscription?.usedSeats.filter(isActiveSeat).length ?? 0;
      const inUseOfflineSeats = subscription?.usedSeats.filter(
        (usedSeat) =>
          usedSeat.deactivatedAt &&
          usedSeat.offline &&
          (usedSeat.expiredAt === null || new Date(usedSeat.expiredAt).getTime() > new Date().getTime()),
      );

      accumulator.total += total;
      accumulator.available += total - usedSeats;
      accumulator.inUse += active + (inUseOfflineSeats?.length ?? 0);

      return accumulator;
    },
    {
      total: 0,
      available: 0,
      inUse: 0,
    },
  );

  return subscriptionSeatCount;
};
