import React from "react";

import { Avatar, LoadingIndicator } from "@k8slens/lds";

interface Props {
  /** The data of item passed from parent <Select /> */
  item?: {
    id: string;
    label: string;
    subtitle?: string;
  };
}

/**
 * The item component to be used in `<IDSelect />`
 */
const LBIDSelectItem: React.FC<Props> = ({ item }) => {
  if (!item) {
    return <LoadingIndicator />;
  }

  const title = item.label ?? "";

  // the item is a "Lens Business ID"
  const subTitle = item.subtitle ?? "Lens Business ID";
  const avatarName = title.replace(/^@/, ""); // strip @

  return (
    <>
      <Avatar size="md" image={undefined} name={avatarName} className="lds-user-menu--avatar" />
      <div className="lds-user-menu--labels">
        <b className="lds-user-menu--username">{title}</b>
        <small className="lds-user-menu--name">{subTitle}</small>
      </div>
    </>
  );
};

export default LBIDSelectItem;
