import { type BusinessUserSubscriptionSeat } from "lens-platform-sdk/dist/cjs/BusinessService";

/**
 * return true if seat.expiredAt is null or expiredAt is in the future, AND
 * the subscription is not expired
 */
export const nonExpiredSeats = ({ expiredAt, subscription }: BusinessUserSubscriptionSeat) => {
  if (subscription.state === "expired") {
    return false;
  }

  if (expiredAt === null) {
    return true;
  }
  const now = new Date().getTime();
  const expiredAtDate = new Date(expiredAt ?? "");

  return expiredAtDate.getTime() > now;
};
