import { LensSDKException, LensPlatformClient } from "lens-platform-sdk";
import { useMutation, useQuery } from "@tanstack/react-query";

import { queryClient } from "src/queryClient";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { useAnalytics } from "src/hooks/useAnalytics";

type BusinessSCIMToken = Awaited<ReturnType<LensPlatformClient["business"]["getBusinessSCIMTokens"]>>[number];

/**
 * Hooks for Business SCIM Tokens
 */
export const useBusinessSCIMToken = (businessId?: string) => {
  const lensPlatformClient = useLensPlatformClient();
  const { track } = useAnalytics();

  // Get business SSO
  const {
    isLoading: loading,
    error,
    data: scimTokens,
    refetch: refetchSCIMTokens,
  } = useQuery<BusinessSCIMToken[], LensSDKException>({
    queryKey: ["businessSCIMTokens", businessId],
    queryFn: async () => {
      if (!businessId) {
        return [];
      }
      const data = await lensPlatformClient.business.getBusinessSCIMTokens(businessId);

      return data;
    },
    enabled: Boolean(businessId),
    initialData: [],
  });

  const {
    error: errorCreatingSCIMToken,
    isPending: creatingSCIMToken,
    mutate: createSCIMToken,
  } = useMutation<BusinessSCIMToken, LensSDKException>({
    mutationFn: async () => {
      if (!businessId) {
        throw new Error("Invalid businessId");
      }

      return lensPlatformClient.business.createBusinessSCIMToken(businessId);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["businessSCIMTokens", businessId] });
      track("Business SCIM Token Created", { businessId });
    },
  });

  const {
    isPending: deletingSCIMToken,
    error: errorDeletingSCIMToken,
    mutate: deleteSCIMToken,
  } = useMutation<
    void,
    LensSDKException,
    {
      businessId: Parameters<typeof lensPlatformClient.business.deleteBusinessSCIMToken>[0];
      tokenId: Parameters<typeof lensPlatformClient.business.deleteBusinessSCIMToken>[1];
    }
  >({
    mutationFn: ({ businessId, tokenId }) => lensPlatformClient.business.deleteBusinessSCIMToken(businessId, tokenId),
    /** if returning a Promise, isSuccess will never be true */
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["businessSCIMTokens", businessId] });
      track("Business SCIM Token deleted", { businessId });
    },
  });

  return {
    // GET
    loading,
    error,
    scimTokens,
    count: scimTokens.length,
    refetchSCIMTokens,
    // CREATE (one)
    createSCIMToken,
    creatingSCIMToken,
    errorCreatingSCIMToken,
    // DELETE (one)
    deletingSCIMToken,
    errorDeletingSCIMToken,
    deleteSCIMToken,
  };
};
