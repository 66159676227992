import sum from "lodash/sum";

// Password must be true for 3 or more of the following:
const passwordRequirements = [
  // Contains upper and lower case chars
  (value: string) => value.match(/([A-Z].*[a-z])|([a-z].*[A-Z])/) !== null,
  // Contains a number
  (value: string) => value.match(/\d/) !== null,
  // Contains at least one non-alpha-numeric character
  (value: string) => value.match(/\W/) !== null,
];

export const passwordValidator = {
  message: () =>
    "Password must be >=8 characters long and has at least 2 of the following: upper and lower case characters, numbers, one special character.",
  validate: (value: string) =>
    sum(passwordRequirements.map((validator) => (validator(value) ? 1 : 0))) >= 2 && value.length >= 8,
};
