/**
 * Removes the authentication parameters from the URL.
 */
export function cleanAuthParams(): void {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.delete("code");
  searchParams.delete("state");
  searchParams.delete("session_state");
  searchParams.delete("iss");

  // @ts-expect-error .size is not in the current version of ts https://github.com/microsoft/TypeScript/issues/54466
  if (searchParams.size === 0) {
    window.history.replaceState({}, "", window.location.pathname);
  } else {
    window.history.replaceState({}, "", `${window.location.pathname}?${searchParams.toString()}`);
  }
}
