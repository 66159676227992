import { useMemo } from "react";
import { useGetLicense } from "./useGetLicense";
import { OFFLINE_ACTIVATION } from "src/constants";

/**
 * Hook to check if the user can download the offline activation code
 */
export function useCanDownloadCode() {
  const license = useGetLicense();

  const canDownloadOfflineActivationCode = useMemo(
    () => Boolean(license?.features?.includes(OFFLINE_ACTIVATION)),
    [license?.features],
  );

  return canDownloadOfflineActivationCode;
}
