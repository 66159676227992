import { useCallback, useContext, useState } from "react";
import { LoadingIndicator, Notification } from "@k8slens/lds";
import { type BusinessUpdate } from "lens-platform-sdk";

import { type NewBusiness, useBusiness } from "src/hooks/useBusiness";
import { useAnalytics } from "src/hooks/useAnalytics";

import BusinessIdForm from "src/components/BusinessIdForm/BusinessIdForm";
import { BusinessContext } from "src/components/Business/Base";
import PageHeader from "src/components/PageHeader/PageHeader";
import PageSection from "src/components/PageSection/PageSection";
import PageAction from "src/components/PageAction/PageAction";

import styles from "./page.module.css";

const updateButtonLabel = "Update Account";
const accountFields: Array<keyof NewBusiness> = [
  "name",
  "handle",
  "phoneNumber",
  "address",
  "additionalAddress",
  "city",
  "state",
  "zip",
  "country",
];

const BusinessAccount = () => {
  const { trackButtonClicked } = useAnalytics();
  const { businessId, business: initialBusiness, loading: loadingBusiness } = useContext(BusinessContext);
  const { updateBusiness, errorUpdatingBusiness, updatingBusiness, updateBusinessSuccess } = useBusiness(businessId);
  const [formValid, setFormValid] = useState(false);

  const [businessUpdate, setBusinessUpdate] = useState<BusinessUpdate>();

  const handleChange = useCallback((formData: BusinessUpdate, valid: boolean) => {
    setFormValid(valid);

    setBusinessUpdate(formData);
  }, []);

  const saveBusiness = () => {
    if (!businessUpdate) {
      return;
    }

    trackButtonClicked(updateButtonLabel);

    updateBusiness(businessUpdate);
  };

  return (
    <>
      {errorUpdatingBusiness ? (
        <Notification flashDuration={1000} level="error" message={errorUpdatingBusiness.message} type="flash" />
      ) : null}
      {updateBusinessSuccess ? (
        <Notification flashDuration={1000} level="success" message="Account updated" type="flash" />
      ) : null}
      <PageHeader
        title="Account"
        subtitle={
          <>
            Manage your Lens Business ID <strong>{`(${businessId})`}</strong> account information in here.
          </>
        }
      />
      <PageSection>
        {initialBusiness && (
          <>
            <BusinessIdForm
              title="Edit your new Lens Business ID details."
              onChange={handleChange}
              initialData={initialBusiness}
              fields={accountFields}
            />
          </>
        )}
        <PageAction
          label={updateButtonLabel}
          buttonProps={{
            disabled: !formValid,
            loading: loadingBusiness || updatingBusiness,
            onClick: saveBusiness,
            id: "save",
          }}
        />
      </PageSection>
      {(updatingBusiness || loadingBusiness) && <LoadingIndicator size="xxl" className={styles.loadingIndicator} />}
    </>
  );
};

export default BusinessAccount;
