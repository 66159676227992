import { BusinessSubscription } from "lens-platform-sdk";

export const getUserSubscriptions = (businessSubscriptions: BusinessSubscription[], userId: string) => {
  const userSubscriptions = businessSubscriptions.filter((subscription) => {
    const userSeats = subscription.usedSeats.filter((seat) => seat.user?.id === userId);

    return userSeats.length > 0;
  });

  return userSubscriptions;
};
