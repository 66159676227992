import { useBusinessUsers } from "./useBusinessUsers";

const activeThreadholdDays = 30;

export const useBusinessMAU = (businessId: string) => {
  const { loading, businessUsers, error } = useBusinessUsers(businessId);

  const activeBusinessUsers = businessUsers.filter((user) => {
    if (user.lastAccess) {
      const lastAccess = new Date(user.lastAccess);
      const now = new Date();
      const diffTime = Math.abs(now.getTime() - lastAccess.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays <= activeThreadholdDays;
    }

    return false;
  });

  return {
    mau: {
      total: businessUsers.length,
      active: activeBusinessUsers.length,
    },
    loading,
    error,
  };
};
