import React, { forwardRef, Fragment, useCallback, useMemo, useRef, useState } from "react";
import { Combobox as HeadlessCombobox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { navigation } from "@k8slens/lds-icons";

import { DefaultOption } from "src/components/Select/Select";

import styles from "./Combobox.module.css";

export type Option = DefaultOption;

const { ArrowDownIcon, ArrowUpIcon } = navigation;

const inputIsAutofilled = (input: HTMLInputElement | null) => {
  return input?.matches(":-webkit-autofill");
};

type OptionRenderer = (
  option: Option,
  props: {
    active: boolean;
    selected: boolean;
    disabled: boolean;
    query: string;
    options: Array<Option>;
  },
) => React.ReactNode | string;
const defaultOptionRenderer: OptionRenderer = (option) => option.label;

export interface ComboboxProps
  // Id cannot be passed to HeadlessUI ComboBox
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "onChange" | "value" | "id"> {
  allowCustomValue?: boolean;
  options: Array<Option>;
  value: Option | null | undefined;
  renderOption?: OptionRenderer;
  onChange(value: Option | null | undefined): void;
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
}

const Combobox = forwardRef<HTMLInputElement, ComboboxProps>(
  (
    {
      wrapperProps,
      options,
      allowCustomValue,
      value: selectedOption,
      renderOption: optionRenderer,
      onChange,
      disabled = false,
      ...inputProps
    }: ComboboxProps,
    ref,
  ) => {
    const [query, setQuery] = useState("");

    const listRef = useRef<null | HTMLUListElement>(null);

    const renderOption = optionRenderer || defaultOptionRenderer;

    const handleBeforeEnter = () => {
      if (!listRef?.current || listRef.current.getAttribute("data-placement") !== null) {
        return;
      }
      const clientRect = listRef.current.getBoundingClientRect();
      const bottom = clientRect?.bottom;

      // Calculate positioning
      if (bottom && bottom > window.innerHeight + window.scrollY) {
        listRef.current.setAttribute("data-placement", "top");
      } else {
        listRef.current.setAttribute("data-placement", "bottom");
      }
    };

    const handleAfterLeave = () => {
      listRef?.current?.removeAttribute("data-placement");
    };

    // Hide the options list if the input is autofilled by browser
    const isAutoFilled = inputIsAutofilled(ref && "current" in ref ? ref.current : null);

    // Handle input change
    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = e.target.value;

        if (ref && "current" in ref && ref.current && inputIsAutofilled(ref.current)) {
          // Input was autofilled by the browser
          const nextValue = ref.current.value;
          const nextOption = options.find((option) => option.label === nextValue);

          if (nextOption) {
            // If the autofilled value is in the options, select it
            onChange(nextOption);
          } else {
            // …otherwise, clear the autofilled value
            setQuery("");
            ref.current.value = "";
          }
        } else {
          setQuery(newQuery);
        }
      },
      [ref, options, setQuery, onChange],
    );

    const filteredOptions = useMemo(() => {
      if (query === "") {
        return options;
      }

      return options.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()));
    }, [options, query]);

    return (
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...wrapperProps}
        className={clsx("lds-input--wrapper", styles.wrapper, wrapperProps?.className)}
      >
        <HeadlessCombobox defaultValue={selectedOption} onChange={onChange} nullable disabled={disabled}>
          {({ open }) => (
            <>
              <HeadlessCombobox.Input
                ref={ref}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...inputProps}
                className={clsx("lds-input", styles.autocomplete, inputProps?.className || "")}
                onChange={handleInputChange}
                defaultValue={undefined}
                displayValue={(option: Option) => (option ? option.label : "")}
              />
              <HeadlessCombobox.Button className={styles.dropDown}>
                {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </HeadlessCombobox.Button>
              <Transition
                as={Fragment}
                beforeEnter={handleBeforeEnter}
                afterLeave={handleAfterLeave}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className={styles.listWrapper}>
                  {open && !isAutoFilled ? (
                    <HeadlessCombobox.Options ref={listRef} className={styles.options} static>
                      {allowCustomValue && query.length > 0 && (
                        <HeadlessCombobox.Option
                          value={{ id: null, title: query }}
                          className={({ active, selected }) =>
                            clsx({ [styles.active]: active, [styles.selected]: selected })
                          }
                        >
                          {query}
                        </HeadlessCombobox.Option>
                      )}
                      {filteredOptions.map((option) => (
                        <HeadlessCombobox.Option key={option.id} value={option} as={Fragment}>
                          {(props) => (
                            <li
                              className={clsx({
                                [styles.active]: props.active,
                                [styles.selected]: props.selected,
                              })}
                            >
                              {renderOption(option, { ...props, query, options })}
                            </li>
                          )}
                        </HeadlessCombobox.Option>
                      ))}
                    </HeadlessCombobox.Options>
                  ) : null}
                </div>
              </Transition>
            </>
          )}
        </HeadlessCombobox>
      </div>
    );
  },
);

export default Combobox;
