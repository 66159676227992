import { useContext } from "react";

import { ProfileContext } from "src/providers/profile-provider";

import PublicLayout from "src/components/PublicLayout/PublicLayout";
import LinkButton from "src/components/Button/TrackedRouterButton";

import { ThankYouFooter } from "./ThankYouFooter";

import styles from "./ThankYou.module.css";

const panelProps = {
  footerProps: {
    type: "centered",
  },
};

export const ThankYou: React.FC = () => {
  const { profile } = useContext(ProfileContext);

  return (
    <PublicLayout
      title="Thank you for your Lens Subscription!"
      wrapperClassName={styles.thankYou}
      pageFooter={<ThankYouFooter />}
      panelProps={panelProps}
      header={
        <p className={styles.subtitle}>
          Subscription added to your Lens ID: <strong data-testid="thank-you-username">{profile?.username}</strong>
        </p>
      }
      footer={<LinkButton to="/lens-launcher" target="_blank" label="Open Lens Desktop" primary />}
    >
      <img src="/static/media/thank-you-for-purchase-hero.svg" alt="Thank you!" />
    </PublicLayout>
  );
};
