import { type License } from "src/components/pages/Home/license";
import { useAuth } from "./useAuth";

export const useGetLicense = () => {
  const { tokenParsed } = useAuth();

  if (tokenParsed && tokenParsed.license_type && tokenParsed.license_iat) {
    return {
      type: tokenParsed.license_type,
      features: tokenParsed.license_features,
      // license_exp is in seconds, Date constructor expects milliseconds
      expiration: tokenParsed.license_exp ? new Date(tokenParsed.license_exp * 1000) : undefined,
      // license_iat is in seconds, Date constructor expects milliseconds
      issuedAt: tokenParsed.license_iat ? new Date(tokenParsed.license_iat * 1000) : undefined,
      trial: Boolean(tokenParsed.license_trial),
    } as License;
  }

  return null;
};
