import { useCallback, useContext, useState } from "react";
import { ProfileContext } from "src/providers/profile-provider";
import Layout from "src/components/Layout/Layout";
import { useGetUserLinkedAccounts } from "src/hooks/useGetUserLinkedAccounts";
import { ConnectedAccountsTable } from "./Connected-acounts-table";
import Button from "src/components/Button/TrackedButton";
import { FormInput } from "@k8slens/lds-form";
import { ButtonBar, Modal, Notification } from "@k8slens/lds";
import { LensSDKException } from "lens-platform-sdk";
import { defaultErrorParser } from "src/components/FormComponents/FormSelect";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { validateEmail } from "src/services/email";

import PageHeader from "src/components/PageHeader/PageHeader";
import PageSection from "src/components/PageSection/PageSection";
import PageAction from "src/components/PageAction/PageAction";

import styles from "./page.module.css";

export const ConnectedAcounts = () => {
  const { profile } = useContext(ProfileContext);
  const flashDuration = 1500;
  const [isUsernameModalOpen, setIsUsernameModalOpen] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const lensPlatformClient = useLensPlatformClient();
  const [error, setError] = useState<LensSDKException | null>(null);
  const {
    userLinkedAccounts,
    deleteUserLinkedAccount,
    errorDeleteUserLinkedAccount,
    loadingUserLinkedAccounts,
    deleteUserLinkedAccountLoading,
  } = useGetUserLinkedAccounts();

  const linkProviderAccount = useCallback(
    async (providerAlias: string) => {
      const connectLink = await lensPlatformClient.sso.getSSOProviderConnectionLink(providerAlias, "lensCloudFrontend");
      const target = "_blank";

      if (connectLink.link) {
        window.open(connectLink.link, target);
      }
    },
    [lensPlatformClient],
  );

  const validateBusinessEmail = useCallback((value: string) => {
    if (!validateEmail(value)) {
      return "patternMismatch";
    }

    return undefined;
  }, []);

  const handleInputError = useCallback((value: string, error: string) => {
    if (error === "patternMismatch") {
      return "Invalid email address";
    }

    return defaultErrorParser(
      value,
      error,
      {
        minLength: 3,
      },
      "",
    );
  }, []);

  const handleConnectBusinessAccount = async () => {
    if (emailAddress) {
      try {
        const domain = emailAddress.split("@")[1];
        const businessSSO = await lensPlatformClient.sso.getSSOByDomain(domain);

        if (businessSSO && businessSSO.identityProviderID) {
          await linkProviderAccount(businessSSO.identityProviderID);
        }
      } catch (error) {
        if (error instanceof LensSDKException) {
          setError(error);
        }
      }
    }
  };

  const openEditUsernameModal = () => {
    setIsUsernameModalOpen(true);
  };

  const handleCloseUsernameModal = () => {
    setIsUsernameModalOpen(false);
  };

  const onUnlinkAccount = (identityProviderAlias: string | undefined) => {
    if (!identityProviderAlias) {
      return;
    }

    deleteUserLinkedAccount(identityProviderAlias);
  };

  return (
    <Layout className="bg-secondary" profile={profile}>
      {(errorDeleteUserLinkedAccount || error) && (
        <Notification
          flashDuration={flashDuration}
          level="error"
          message={errorDeleteUserLinkedAccount?.message || error?.message}
          type="flash"
        />
      )}
      <PageHeader
        title="Connected Accounts"
        subtitle="Connect your existing third-party service accounts with Lens ID."
      />
      <PageSection>
        <ConnectedAccountsTable
          loading={loadingUserLinkedAccounts || deleteUserLinkedAccountLoading}
          accounts={userLinkedAccounts}
          onDeleteAccount={onUnlinkAccount}
        />
        <PageAction
          label="Connect New Account"
          buttonProps={{
            onClick: openEditUsernameModal,
          }}
        />
      </PageSection>

      <Modal
        title="Connect New Account"
        size="md"
        onClose={handleCloseUsernameModal}
        isOpen={isUsernameModalOpen}
        className={styles.modalWithForm}
        contentComponent="form"
        footer={
          <>
            <Button label="Cancel" id="cancel-connect-account" onClick={handleCloseUsernameModal} />
            <Button
              label="Connect account"
              disabled={!emailValid}
              id="connect-account"
              onClick={handleConnectBusinessAccount}
              primary
            />
          </>
        }
      >
        <div className={styles.social}>
          <p>Choose social account</p>
          <ButtonBar className={styles.socialButtons} type="grid">
            <Button
              label="GitHub"
              onClick={(e) => {
                e.preventDefault();
                linkProviderAccount("github");
              }}
              type="primary"
              discreet
            />
            <Button
              label="Google"
              onClick={(e) => {
                e.preventDefault();
                linkProviderAccount("google");
              }}
              type="primary"
              discreet
            />
          </ButtonBar>
        </div>
        <div className={styles.businessEmail}>
          <p>Connect company account</p>
          <FormInput
            label="Email Address"
            id="email"
            name="email"
            value={emailAddress}
            validate={validateBusinessEmail}
            errorParser={handleInputError}
            onChange={(value, valid) => {
              setEmailAddress(value);
              setEmailValid(valid);
            }}
          />
        </div>
      </Modal>
    </Layout>
  );
};
