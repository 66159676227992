import { Switch, Notification } from "@k8slens/lds";
import { useBusinessSCIMToken } from "src/hooks/useBusinessSCIMToken";
import styles from "./SCIMSwitch.module.css";
import { useParams } from "react-router-dom";
import { useBusinessSSO } from "src/hooks/useBusinessSSO";
import { useEffect, useState } from "react";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";
import PageSection from "src/components/PageSection/PageSection";

export const SCIMSwitch = () => {
  const { businessId } = useParams<{ businessId?: string }>();
  const [checked, setChecked] = useState(false);
  const {
    scimTokens,
    createSCIMToken,
    errorCreatingSCIMToken,
    creatingSCIMToken,
    errorDeletingSCIMToken,
    deleteSCIMToken,
    deletingSCIMToken,
  } = useBusinessSCIMToken(businessId);
  const hasSCIMToken = scimTokens.length > 0;
  const { businessSSO } = useBusinessSSO(businessId);
  const hasSSOConfig = Boolean(businessSSO?.config);
  const disabledSCIMSwitch = !hasSSOConfig || creatingSCIMToken || deletingSCIMToken;

  useEffect(() => {
    if (hasSCIMToken) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [hasSCIMToken]);

  return (
    <PageSection className={styles.scimSwitchWrapper}>
      <section className="self-center">
        <h3 className="lds-form-label lds-form-field--label lds-form-switch-group--group-label">SCIM Provisioning</h3>
        <p className="leading-normal">
          Automatically provision and de-provision users and groups from your identity provider. Use{" "}
          <TrackedAnchor
            href="https://docs.k8slens.dev/lens-id/lens-business-id/sso-scim/"
            target="_blank"
            rel="noreferrer"
          >
            step-by-step setup guide
          </TrackedAnchor>
          .
        </p>
        {errorCreatingSCIMToken ? (
          <Notification level="error" message={errorCreatingSCIMToken.message} type="closable" />
        ) : null}
        {errorDeletingSCIMToken ? (
          <Notification level="error" message={errorDeletingSCIMToken.message} type="closable" />
        ) : null}
      </section>
      <Switch
        className={styles.switch}
        disabled={disabledSCIMSwitch}
        title={hasSSOConfig ? "Disable SCIM will remove all SCIM tokens" : "Enable SSO before configuring SCIM"}
        onChange={(newchecked) => {
          setChecked(newchecked);

          // remove all tokens if unchecked
          if (newchecked === false && scimTokens.length > 0 && businessId) {
            for (const token of scimTokens) {
              deleteSCIMToken({
                businessId,
                tokenId: token.id,
              });
            }
          }

          // create a new token if checked
          if (newchecked === true && scimTokens.length === 0) {
            createSCIMToken();
          }
        }}
        checked={checked}
      />
    </PageSection>
  );
};
