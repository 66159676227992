import { useParams } from "react-router-dom";

export const useBusinessIdParam = () => {
  const { businessId } = useParams<{ businessId?: string }>();

  if (!businessId) {
    throw new Error(
      "useBusinessIdParam hooks need to be used with a route that has a businessId param, e.g. <Route path='/business/:businessId >",
    );
  }

  return businessId;
};
