import { useContext } from "react";
import clsx from "clsx";
import { LoadingIndicator, Notification } from "@k8slens/lds";

import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";
import { useBusinessSSO } from "src/hooks/useBusinessSSO";
import { useIsEnterpriseFeaturesEnabled } from "src/hooks/useIsEnterpriseFeaturesEnabled";

import { BusinessContext } from "src/components/Business/Base";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";
import PageSection from "src/components/PageSection/PageSection";

import { BusinessSSO } from "./BusinessSSO";

import styles from "./SingleSignOnConfig.module.css";

export const SingleSignOnConfig = () => {
  const { businessId } = useContext(BusinessContext);

  const { loading: loadingBusinessSubscriptions, businessSubscriptions } = useGetBusinessSubscriptions(businessId);
  const { enterpriseFeaturesEnabled } = useIsEnterpriseFeaturesEnabled(
    loadingBusinessSubscriptions ? undefined : businessSubscriptions,
  );
  const { deletingBusinessSSO } = useBusinessSSO(businessId);
  const loading = loadingBusinessSubscriptions || deletingBusinessSSO;

  return (
    <>
      {!enterpriseFeaturesEnabled ? (
        <Notification
          level="info"
          className={styles.enterpriseAccNotification}
          message={
            <>
              {"Please "}
              <TrackedAnchor href="https://k8slens.dev/purchase-request" target="_blank" rel="noreferrer">
                contact sales
              </TrackedAnchor>
              {" to purchase Lens Enterprise subscription."}
            </>
          }
        />
      ) : null}
      {loading ? (
        <LoadingIndicator size="2xl" />
      ) : (
        <PageSection
          className={clsx({
            [styles.disabledSection]: !enterpriseFeaturesEnabled,
          })}
        >
          {<BusinessSSO />}
        </PageSection>
      )}
    </>
  );
};
