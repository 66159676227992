import { memo } from "react";
import clsx from "clsx";

import styles from "./LicenseImage.module.css";
import { planMetadata } from "src/services/plans";

/**
 * Keys that can be used to index into textContent
 */
export type LicenseImageTextKey = keyof typeof textContent;

interface Props {
  type: LicenseImageTextKey;
  title?: string;
  className?: string;
  description?: string;
}

const textContent = {
  ["personal" as const]: {
    type: planMetadata.personal.nameShort,
    title: planMetadata.personal.licenseName,
    description: planMetadata.personal.licenseDescription,
  },
  ["pro" as const]: {
    type: planMetadata.pro.nameShort,
    title: planMetadata.pro.licenseName,
    description: planMetadata.pro.licenseDescription,
  },
  ["proTrial" as const]: {
    type: planMetadata.proTrial.nameShort,
    title: planMetadata.proTrial.licenseName,
    description: planMetadata.proTrial.licenseDescription,
  },
  ["enterprise" as const]: {
    type: planMetadata.enterprise.nameShort,
    title: planMetadata.enterprise.licenseName,
    description: planMetadata.pro.licenseDescription,
  },
};

const LicenseImage: React.FC<Props> = ({ type, className, title, description }) => (
  <div className={clsx(styles.licenseImage, type, className)} aria-hidden="true">
    <svg viewBox="86 86 340 340" fill="white">
      <path d="M242.6,426h130.9l-30.2-128.7L242.6,426z" />
      <path d="M86,352.5V426h137.6l57.5-73.5H86z" />
      <path d="M273.1,167L426,241.4v-148L273.1,167z" />
      <path d="M388.9,426H426V258l-86.4-42.1L388.9,426z" />
      <path d="M406.9,86H216.4l-23,102.7L406.9,86z" />
      <path d="M86,195.1v142.5h113.3L86,195.1z" />
      <path d="M201.1,86H86v85.1l75,94.3L201.1,86z" />
    </svg>
    <div className={styles.description}>
      <h3>{title || textContent[type].title || textContent[type].type}</h3>
      <p>{typeof description === "string" ? description : textContent[type].description}</p>
    </div>
  </div>
);

export default memo(LicenseImage);
