import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { queryClient } from "src/queryClient";
import { useMutation } from "@tanstack/react-query";
import type { BusinessSubscription, LensSDKException } from "lens-platform-sdk";
import { useAnalytics } from "./useAnalytics";

type UsedSeat = BusinessSubscription["usedSeats"][number];

export const useDeActivateBusinessUserSubscription = (businessId?: string) => {
  const lensPlatformClient = useLensPlatformClient();
  const { track } = useAnalytics();

  const {
    isPending: loading,
    error,
    mutate: deActivateBusinessUserSubscription,
  } = useMutation<
    UsedSeat,
    LensSDKException,
    {
      businessSubscriptionId: string;
      username: string;
    }
  >({
    mutationFn: async ({ businessSubscriptionId, username }) => {
      if (!businessId) {
        throw new Error("Business ID is required");
      }

      const deactivated = await lensPlatformClient.business.deActivateBusinessUserSubscription({
        businessId,
        businessSubscriptionId,
        username,
      });

      track("Seat unassigned", { seatId: businessSubscriptionId });

      return deactivated;
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["businessSubscriptions", businessId] });
      await queryClient.invalidateQueries({ queryKey: ["businessInvitations", businessId] });
      await queryClient.invalidateQueries({ queryKey: ["businessUsers", businessId] });
    },
  });

  return {
    deActivateBusinessUserSubscription,
    error,
    loading,
  };
};
