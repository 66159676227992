import { useEffect, useState, useRef, useCallback } from "react";
import { LensPlatformClient, LensSDKException } from "lens-platform-sdk";
import { useAnalytics } from "src/hooks/useAnalytics";
import { useLensPlatformClient } from "./useLensPlatformClient";

type ActionResponse = Awaited<ReturnType<LensPlatformClient["user"]["sendVerificationEmail"]>>;

/**
 * Hook for triggering a secondary email verification action.
 */
export const useSendVerificationEmail = () => {
  const lensPlatformClient = useLensPlatformClient();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<ActionResponse>();
  const [error, setError] = useState<LensSDKException>();
  const { track, trackError } = useAnalytics();
  // Keep up with mounted/unmounted state
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const sendVerificationEmail = useCallback(
    async (email: string) => {
      let lensSDKException: LensSDKException | undefined;

      try {
        if (mounted.current) {
          setError(undefined);
          setLoading(true);
        }
        const response = await lensPlatformClient.user.sendVerificationEmail(email);

        if (mounted.current) {
          track("Verification Email Sent");
          setResponse(response);
        }
      } catch (exception) {
        if (exception instanceof LensSDKException) {
          trackError("Verification Email Sent Failed");
          setError(exception);
          lensSDKException = exception;
        }
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }

      return lensSDKException;
    },
    [lensPlatformClient.user, track, trackError],
  );

  return {
    sendVerificationEmail,
    error,
    response,
    loading,
  };
};
