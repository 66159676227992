import { LoadingIndicator } from "@k8slens/lds";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { sanitizeUrl } from "@braintree/sanitize-url";
import { useLocalStorage } from "usehooks-ts";
import { getRedirectAuthUri, isAuthorizedRedirectUri } from "src/services/getRedirectAuthToLensDesktopUrl";
import { legacyKeycloakIframeRedirectLocalStorageKey } from "src/constants";

export function RedirectToLensDesktop() {
  const [lensDesktopRedirectUri] = useLocalStorage(legacyKeycloakIframeRedirectLocalStorageKey, "");
  const location = useLocation();

  useEffect(() => {
    if (lensDesktopRedirectUri) {
      const redirectAuthUri = getRedirectAuthUri(location.search, lensDesktopRedirectUri);

      if (isAuthorizedRedirectUri(redirectAuthUri)) {
        window.location.replace(sanitizeUrl(redirectAuthUri));
      }
    }
  }, [location.search, lensDesktopRedirectUri]);

  return <LoadingIndicator size="2xl" />;
}
