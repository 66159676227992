import { Button } from "@k8slens/lds";
import { misc, navigation } from "@k8slens/lds-icons";
import { dateToString } from "src/utils/date";
import styles from "./PersonalSubscription.module.css";
import { useHistory } from "react-router-dom";
import { openUrl } from "src/utils/url";
import { useSubscriptionBillingUrls } from "src/hooks/useSubscriptionBillingUrls";
import { useGetSubscriptionSeats } from "src/hooks/useGetSubscriptionSeats";
import { useUsername } from "src/hooks/useUsername";
import { useGetLicense } from "src/hooks/useGetLicense";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { useAuth } from "src/hooks/useAuth";

const { CreditCardIcon, EventIcon } = misc;
const { RepeatIcon } = navigation;

export const PersonalBilling = () => {
  const license = useGetLicense();
  const history = useHistory();
  const username = useUsername();
  const platformClient = useLensPlatformClient();
  const { updateToken } = useAuth();
  const handleCancelPersonalSubscription = async () => {
    await platformClient.user.deactivateSubscriptionSeat({
      username,
      license: { subscriptionId: "personal" },
    });
    await updateToken();
  };
  const { currentSubscriptionSeatWithBillingPeriod } = useGetSubscriptionSeats();
  const { accountUrl } = useSubscriptionBillingUrls(username, currentSubscriptionSeatWithBillingPeriod?.subscriptionId);

  const handleLicenseChange = () => {
    if (!accountUrl) {
      history.push("/subscribe");

      return;
    }
    openUrl(accountUrl, true);
  };

  return (
    <div className={styles.billing}>
      <ul>
        <li>
          <CreditCardIcon size="sm" />
          No Payment Method Required
        </li>
        <li>
          <RepeatIcon size="sm" />
          Automatic Renewal (Annual)
        </li>
        {license ? (
          <li>
            <EventIcon size="sm" />
            Next Renewal: {dateToString(license.expiration)}
          </li>
        ) : null}
      </ul>
      <div className={styles.buttons}>
        <Button label="Cancel Subscription" primary discreet onClick={() => handleCancelPersonalSubscription()} />
        <Button
          label="Change Lens Subscription"
          primary
          onClick={() => {
            handleLicenseChange();
          }}
        />
      </div>
    </div>
  );
};
