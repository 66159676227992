import { useCallback } from "react";
import { BillingInfo, LensSDKException, NotFoundException } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useQuery } from "@tanstack/react-query";

/**
 * Get business billing information
 */
export const useGetBusinessBillingInformation = (businessId: string | undefined) => {
  const lensPlatformClient = useLensPlatformClient();

  // See https://tkdodo.eu/blog/react-query-and-type-script#type-safety-with-the-enabled-option
  const fetchBillingInfo = useCallback(
    (id?: string) =>
      typeof id === "undefined"
        ? Promise.reject(new Error("Invalid businessId"))
        : lensPlatformClient.business.geBusinessBillingInformation(id),
    [lensPlatformClient],
  );

  const {
    isLoading: loading,
    error,
    data: billingInfo,
  } = useQuery<BillingInfo, LensSDKException>({
    queryKey: ["businessBillingInfo", businessId, fetchBillingInfo],
    queryFn: () => fetchBillingInfo(businessId),
    retry: (_, error) => {
      if (error instanceof NotFoundException) {
        return false;
      }

      return true;
    },
    enabled: Boolean(businessId),
  });

  return { error, billingInfo, loading };
};
