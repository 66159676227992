import { Lozenge } from "@k8slens/lds";
import { type Icon } from "@k8slens/lds-icons/lib/es/Icon/Icon.d";

import Avatar from "src/components/Avatar/Avatar";

import styles from "src/components/TableCells/AvatarTableCell.module.css";

interface AvatarTableCellProps {
  /** render as `@name` */
  name: string;
  /** show role after username if provided */
  role?: string;
  /** hidden if undefined, renders under the `@name` line */
  subtitle?: string;
  /** hidden if undefined, used when using `AvatarTableCell` for user */
  email?: string;
  /** optional tootip text for hovering over username and fullname */
  tooltip?: string;
  /** pass to the onClick/onKeyDown props of the outmost `<div role="button" />` */
  onClick?: () => void;
  /** true if wants to render name with `@`, e.g. `@name`, default is true */
  nameWithAtSign?: boolean;
}

interface AvatarProps {
  image?: string | Icon | null;
}

/**
 * Renders avatar, name with `@` and subtitle (if not `undefined`)
 */
export const AvatarTableCell = ({
  name,
  nameWithAtSign,
  subtitle,
  email,
  tooltip,
  image,
  onClick,
  role,
}: AvatarTableCellProps & AvatarProps) => {
  const title = tooltip ?? (email ? `${subtitle} (${email})` : subtitle);

  const withAtSign = nameWithAtSign ?? true;

  return (
    <div
      className={styles.lensID}
      onClick={onClick}
      onKeyDown={onClick ? ({ key }) => key === "Enter" && onClick() : undefined}
      role="button"
      tabIndex={0}
    >
      <Avatar image={image} name={name} className={styles.lensIDAvator} />
      <div className={styles.lensIDUserBlock}>
        <div className={styles.lensIDUsername} title={title}>
          {withAtSign ? `@${name}` : `${name}`}
          {role ? (
            <Lozenge className="ml-md" textTransform="uppercase">
              {role}
            </Lozenge>
          ) : null}
        </div>
        {subtitle ? (
          <div className={styles.lendIDSubtitle} title={title}>
            {subtitle}
          </div>
        ) : null}
      </div>
    </div>
  );
};

interface PendingUserTableCellProps {
  email: string;
}

/**
 * For pending user, only show email
 */
export const PendingUserTableCell = ({ email }: PendingUserTableCellProps) => (
  <div className={styles.lensIDEmail}>{email}</div>
);
