import { LensPlatformClient } from "lens-platform-sdk";
import { useMemo } from "react";

import { backend, keycloakRealmName, keycloakUrl } from "src/constants";
import { useAuth } from "./useAuth";

export const useLensPlatformClient = () => {
  const auth = useAuth();

  const lensPlatformClient = useMemo(
    () =>
      new LensPlatformClient({
        getAccessToken: async () => auth.token ?? "",
        keycloakRealm: keycloakRealmName,
        keyCloakAddress: keycloakUrl,
        apiEndpointAddress: backend ?? "",
      }),
    [auth.token],
  );

  return lensPlatformClient;
};
