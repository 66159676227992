import { Analytics, MAU, SeatCount } from "./Analytics";
import { WhatNext, SetupBillingInfo, InviteUser, ConfigureSSO, ConfigureSCIM } from "./WhatNext";
import PageHeader from "src/components/PageHeader/PageHeader";
import { Catalog } from "./Catalog";

import styles from "./page.module.css";
import { useBusinessIdParam } from "src/hooks/useBusinessIdParam";

export function BusinessHome() {
  const businessId = useBusinessIdParam();

  return (
    <>
      <PageHeader
        title="Welcome to your Lens Business ID"
        subtitle={
          <span>
            Your home for managing Lens Subscriptions and settings for organization <strong>{`(${businessId})`}</strong>
            .
          </span>
        }
      />
      <div className={styles.intro}>
        <Analytics>
          <SeatCount />
          <MAU />
        </Analytics>
        <WhatNext>
          <SetupBillingInfo />
          <InviteUser />
          <ConfigureSSO />
          <ConfigureSCIM />
        </WhatNext>
      </div>
      <Catalog />
    </>
  );
}
