import {
  NotFoundException,
  LensSDKException,
  Business,
  SSOType,
  BusinessSsoDto,
  BusinessSSOWithIDPDetails,
} from "lens-platform-sdk";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { queryClient } from "src/queryClient";
import { useAnalytics } from "./useAnalytics";

export type SSOTypeOption = {
  id: SSOType.SAML | SSOType.OIDC;
  label: string;
};

export const ssoTypeOptions: SSOTypeOption[] = [
  {
    id: SSOType.SAML,
    label: SSOType.SAML.toUpperCase(),
  },
  {
    id: SSOType.OIDC,
    label: SSOType.OIDC.toUpperCase(),
  },
];

/**
 * Hooks for Business SSO
 */
export const useBusinessSSO = (businessId?: string) => {
  const lensPlatformClient = useLensPlatformClient();
  const { track } = useAnalytics();
  const fetchBusinessSSO = useCallback(
    (id?: string) =>
      typeof id === "undefined"
        ? Promise.reject(new Error("Invalid businessId"))
        : lensPlatformClient.business.getBusinessSSO(id),
    [lensPlatformClient],
  );

  // Get business SSO
  const {
    isLoading: loadingBusinessSSO,
    error: errorLoadingBusinessSSO,
    data: businessSSO,
    refetch: refetchBusinessSSO,
  } = useQuery<BusinessSSOWithIDPDetails | null, LensSDKException>({
    queryKey: ["businessSSO", businessId],
    queryFn: () => fetchBusinessSSO(businessId),
    retry: (_, error) => {
      if (error instanceof NotFoundException) {
        return false;
      }

      return true;
    },
    enabled: Boolean(businessId),
  });

  // Create business SSO
  const {
    isPending: creatingBusinessSSO,
    isSuccess: createBusinessSuccessSSOSuccess,
    error: errorCreatingBusinessSSO,
    mutate: createBusinessSSO,
  } = useMutation<
    BusinessSSOWithIDPDetails | undefined,
    LensSDKException,
    { businessId: Business["id"]; ssoSettings: BusinessSsoDto }
  >({
    mutationFn: async ({ businessId, ssoSettings }) =>
      lensPlatformClient.business.createBusinessSSO(businessId, ssoSettings),

    onSuccess: async () => {
      track("Business SSO created", { businessId });

      await queryClient.invalidateQueries({ queryKey: ["businessSSO", businessId] });
    },
  });

  const {
    isPending: deletingBusinessSSO,
    error: errorDeletingBusinessSSO,
    mutate: deleteBusinessSSO,
  } = useMutation({
    mutationFn: async (businessId: string) => lensPlatformClient.business.removeBusinessSSO(businessId),

    onSuccess: async () => {
      track("Business SSO deleted", { businessId });

      await queryClient.invalidateQueries({ queryKey: ["businessSSO", businessId] });

      queryClient.setQueryData(["businessSSO", businessId], null);
    },
  });

  return {
    // GET (one)
    loadingBusinessSSO,
    errorLoadingBusinessSSO,
    businessSSO,
    refetchBusinessSSO,
    // Create (one)
    createBusinessSSO,
    creatingBusinessSSO,
    errorCreatingBusinessSSO,
    createBusinessSuccessSSOSuccess,
    // Delete (one)
    deleteBusinessSSO,
    deletingBusinessSSO,
    errorDeletingBusinessSSO,
  };
};
