/**
 * Wait x ms and resolve, or reject with error(if provided)
 */
export const wait = (ms: number, error?: Error) =>
  new Promise<undefined>((resolve, reject) => {
    setTimeout(() => {
      if (error) {
        reject(error);
      } else {
        resolve(undefined);
      }
    }, ms);
  });
