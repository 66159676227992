import { backend } from "src/constants";
import { invitationKeyParameter } from "src/hooks/useInvitationKey";

/**
 * Accept email invitation
 * @param invitationId ID of the invitation
 * @param invitationKey the invitation-key query parameter
 * @param token the access token
 */
export const acceptEmailInvitation = async (invitationId: string, invitationKey: string, token: string) => {
  const response = await fetch(
    `${backend}/invitations/${invitationId}?${invitationKeyParameter}=${encodeURIComponent(invitationKey)}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        state: "accepted",
      }),
    },
  );

  if (!response.ok) {
    const json = await response.json();

    throw new Error(json?.message);
  }
};
