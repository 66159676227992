import { ComponentType } from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";

import styles from "./Tabs.module.css";

type ExtractProps<T> = T extends ComponentType<infer P> ? P : T;

export type TabPanelsProps = ExtractProps<(typeof Tab)["Panels"]>;

export const TabPanels: React.FC<TabPanelsProps> = ({ className, children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tab.Panels {...props} className={clsx(styles.tabPanels, className)}>
    {children}
  </Tab.Panels>
);
