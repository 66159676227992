import { Switch, Notification } from "@k8slens/lds";
import { useBusiness } from "src/hooks/useBusiness";
import styles from "./AutoJoinSwitch.module.css";
import { useContext } from "react";
import { BusinessContext } from "./Base";
import TrackedLink from "src/components//TrackedLink/TrackedLink";

export const AutoJoinSwitch = () => {
  const { businessId, business } = useContext(BusinessContext);
  const { updateBusiness, updatingBusiness, errorUpdatingBusiness } = useBusiness(businessId);
  const checked = Boolean(business?.automaticSeatAssignment);

  return (
    <div className={styles.autoJoinSwitchWrapper}>
      <section className="self-center">
        <h3 className="lds-form-label lds-form-field--label lds-form-switch-group--group-label">
          Automatically assign seats
        </h3>
        <p className="lds-form-switch-group--description">
          Enable automatic seat assignment to streamline the process of assigning seats to new users logging in via SSO,
          SCIM or being invited manually. Without this feature enabled, users will appear on the{" "}
          <TrackedLink className={styles.link} to={`/business/${businessId}/users`}>
            <span>user list</span>
          </TrackedLink>{" "}
          as members without assigned seats. Manual seat assignment will be required.
        </p>
        <p className="lds-form-switch-group--description">
          You can at any time remove automatically assigned seats from your users.
        </p>
        {errorUpdatingBusiness ? (
          <Notification level="error" message={errorUpdatingBusiness.message} type="closable" className="my-5" />
        ) : null}
      </section>
      <Switch
        className={styles.switch}
        disabled={updatingBusiness}
        onChange={(newchecked) => {
          if (!business) {
            return;
          }
          updateBusiness({
            automaticSeatAssignment: newchecked,
          });
        }}
        checked={checked}
      />
    </div>
  );
};
