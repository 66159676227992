import { Business, LensPlatformClient, LensSDKException, NotFoundException } from "lens-platform-sdk";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "src/queryClient";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useAnalytics } from "./useAnalytics";

export type ChildBusiness = Awaited<ReturnType<LensPlatformClient["business"]["getChildren"]>>[number];

/**
 * Hooks for read and delete business (Lens Business IDs) children.
 */
export const useBusinessChildren = (businessId?: Business["id"]) => {
  const lensPlatformClient = useLensPlatformClient();
  const { track } = useAnalytics();

  const {
    isLoading: loadingBusinessChildren,
    error: errorLoadingBusinessChildren,
    data: businessChildren,
    refetch: refetchBusinessChildren,
  } = useQuery<ChildBusiness[], LensSDKException>({
    queryKey: ["businessChildren", businessId],
    queryFn: async () => {
      if (!businessId) {
        return [];
      }

      try {
        const children = await lensPlatformClient.business.getChildren(businessId);

        return children;
      } catch (error) {
        if (error instanceof NotFoundException) {
          return [];
        }
        throw error;
      }
    },
    initialData: [],
  });

  const {
    isPending: removingAllBusinessChildren,
    isSuccess: removingAllBusinessChildrenSuccess,
    error: errorRemovingAllBusinessChildren,
    mutate: removeAllBusinessChildren,
  } = useMutation({
    mutationFn: async (parentBusinessId: Business["id"]) =>
      lensPlatformClient.business.removeAllChildren(parentBusinessId),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["businessChildren", businessId] });
      track("BusinessID children removed", { businessId });
    },
  });

  const {
    isPending: removingOneBusinessChild,
    isSuccess: removingOneBusinessChildSuccess,
    error: errorremovingOneBusinessChild,
    mutate: removeOneBusinessChild,
  } = useMutation<Business[], LensSDKException, { parentBusinessId: Business["id"]; childBusinessId: Business["id"] }>({
    mutationFn: async ({ parentBusinessId, childBusinessId }) =>
      lensPlatformClient.business.removeOneChild(parentBusinessId, childBusinessId),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["businessChildren", businessId] });
      track("BusinessID child removed", { businessId });
    },
  });

  return {
    loadingBusinessChildren,
    errorLoadingBusinessChildren,
    businessChildren,
    refetchBusinessChildren,
    removingAllBusinessChildren,
    removingAllBusinessChildrenSuccess,
    errorRemovingAllBusinessChildren,
    removeAllBusinessChildren,
    removingOneBusinessChild,
    removingOneBusinessChildSuccess,
    errorremovingOneBusinessChild,
    removeOneBusinessChild,
  };
};
