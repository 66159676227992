import { Button, Modal } from "@k8slens/lds";
import { useCallback, useState } from "react";

import { useAnalytics } from "src/hooks/useAnalytics";

import TrackedButton from "src/components/Button/TrackedButton";

const purchaseRequestUrl = "https://k8slens.dev/purchase-request";

export function useUpgradeToEnterpriseModal() {
  const { track } = useAnalytics();
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    track("Upgrade to Lens Enterprise Modal Opened");
    setIsOpen(true);
  };
  const close = () => setIsOpen(false);

  const upgrade = useCallback(() => {
    window.open(purchaseRequestUrl, "_blank", "noopener,noreferrer");
    close();
  }, [close]);

  return {
    isOpen,
    open,
    close,
    Modal: (
      <Modal
        size="md"
        isOpen={isOpen}
        onClose={close}
        title="Upgrade to Lens Enterprise"
        footer={
          <>
            <Button onClick={close} label="Cancel" />
            <div></div>
            <TrackedButton type="primary" onClick={upgrade} label="Contact Sales" />
          </>
        }
      >
        <p>
          The Single Sign-On (SSO) and System for Cross-domain Identity Management (SCIM) features are available
          exclusively on our Lens Enterprise Plan. To gain access to these advanced capabilities, please upgrade your
          subscription.
        </p>
      </Modal>
    ),
  };
}
