import { useEffect, useState } from "react";
import { Business } from "lens-platform-sdk";

import { useLensPlatformClient } from "./useLensPlatformClient";

/**
 * Get the billing page token for a user
 */
export const useBillingPageToken = (username: string | undefined) => {
  const lensPlatformClient = useLensPlatformClient();

  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let cancelled = false;

    setToken(null);
    setError(null);

    if (username) {
      lensPlatformClient.user
        .getBillingPageToken(username)
        .then((billingPageToken) => {
          if (cancelled === false) {
            setToken(billingPageToken.hostedLoginToken);
          }
        })
        .catch((error: any) => {
          if (cancelled === false) {
            setError(error);
          }
        });
    }

    return () => {
      cancelled = true;
    };
  }, [username, lensPlatformClient]);

  return { error, token };
};

/**
 * Get the billing page token for a business
 */
export const useBusinessBillingPageToken = (businessId?: Business["id"] | undefined) => {
  const lensPlatformClient = useLensPlatformClient();

  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let cancelled = false;

    setToken(null);
    setError(null);

    if (businessId) {
      lensPlatformClient.business
        .getBillingPageToken(businessId)
        .then((billingPageToken) => {
          if (cancelled === false) {
            setToken(billingPageToken.hostedLoginToken);
          }
        })
        .catch((error: any) => {
          if (cancelled === false) {
            setError(error);
          }
        });
    }

    return () => {
      cancelled = true;
    };
  }, [businessId, lensPlatformClient]);

  return { error, token };
};
