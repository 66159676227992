import clsx from "clsx";
import React, { memo, PropsWithChildren } from "react";
import styles from "./Spinner.module.scss";

export interface SpinnerProps extends React.HTMLProps<any> {
  singleColor?: boolean;
  center?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ children, singleColor, center, className }) => (
  <div
    className={clsx(styles.SpinnerArea, {
      [styles.center]: center,
    })}
  >
    <div
      className={clsx(className, styles.Spinner, {
        [styles.singleColor]: singleColor,
      })}
    />
    {children}
  </div>
);

export default memo<PropsWithChildren<SpinnerProps>>(Spinner);
