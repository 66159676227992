import { useMemo } from "react";
import { createColumnHelper, useReactTable, getCoreRowModel, getSortedRowModel } from "@tanstack/react-table";
import { action } from "@k8slens/lds-icons";
import { useHistory, useParams } from "react-router-dom";
import { Notification } from "@k8slens/lds";

import { type ParentBusiness, useBusinessParent } from "src/hooks/useBusinessParent";
import { useBusiness } from "src/hooks/useBusiness";
import { useTrackedTableSort } from "src/hooks/useTrackedTableSort";

import Table from "src/components/Table/Table";
import { ContextMenuItem } from "src/components/ContextMenu/ContextMenu";
import TrackedLink from "src/components/TrackedLink/TrackedLink";
import { subscriptionIdColDef } from "src/components/TableCells/SubscriptionIdCell";
import { AvatarTableCell } from "src/components/TableCells/AvatarTableCell";
import ContextMenuCell, { contextMenuColDef } from "src/components/TableCells/ContextMenuCell";

const { DeleteIcon } = action;

const parentTableColumnHelper = createColumnHelper<ParentBusiness>();

const caption = "Parent account";

export const ParentTable = () => {
  const { businessId: childBusinessId } = useParams<{ businessId: string }>();
  const { businesses } = useBusiness();
  const history = useHistory();

  const {
    businessParent,
    loadingBusinessParent,
    removeBusinessParent,
    errorRemovingBusinessParent,
    removingBusinessParent,
    removingBusinessParentSuccess,
    delaying,
  } = useBusinessParent(childBusinessId, {
    mutation: 5000, // add delay to show the success notification
  });

  const parentTableColumns = useMemo(
    () => [
      parentTableColumnHelper.accessor("id", {
        ...subscriptionIdColDef,
        meta: { ...subscriptionIdColDef.meta, text: "tertiary" },
        header: () => <span>ID</span>,
      }),
      parentTableColumnHelper.accessor("name", {
        header: () => <span>Lens Business ID</span>,
        cell: ({ getValue, row }) => {
          const isAdmin = businesses.find((business) => business.id === row.original.id) !== undefined;

          return (
            <AvatarTableCell
              name={getValue() ?? "N/A"}
              subtitle="Lens Business ID"
              onClick={() => {
                if (isAdmin) {
                  history.push(`/business/${row.original.id}`);
                }
              }}
            />
          );
        },
      }),
      parentTableColumnHelper.accessor("department", {
        header: () => <span>Department</span>,
        cell: ({ getValue }) => <div>{getValue() ?? "N/A"}</div>,
        meta: { text: "tertiary" },
      }),
      parentTableColumnHelper.accessor("email", {
        header: () => <span>Contact</span>,
        cell: ({ getValue }) => <div>{getValue() ?? "N/A"}</div>,
        meta: { text: "tertiary" },
      }),
      parentTableColumnHelper.accessor("id", {
        ...contextMenuColDef,
        cell: () => (
          <ContextMenuCell toggleButtonProps={{ title: "Toggle context menu for parent" }}>
            <ContextMenuItem label="Remove" icon={DeleteIcon} onClick={() => removeBusinessParent(childBusinessId)} />
          </ContextMenuCell>
        ),
      }),
    ],
    [childBusinessId, removeBusinessParent, businesses, history],
  );

  const { sorting, onSortingChange } = useTrackedTableSort({ table: caption });

  const parentTable = useReactTable({
    data: businessParent,
    columns: parentTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange,
  });
  const parentTableRowModel = parentTable.getRowModel();
  const parentTableRows = useMemo(() => parentTableRowModel.rows, [parentTableRowModel]);

  return (
    <>
      {errorRemovingBusinessParent ? (
        <div className="mt-1 mb-1">
          <Notification level="error" message={errorRemovingBusinessParent.message} type="closable" />
        </div>
      ) : null}
      {removingBusinessParentSuccess ? (
        <div className="mt-1 mb-1">
          <Notification
            level="success"
            message={
              <span>
                Your account is now detached from the parent, all charges will be directly applied to this account,
                please make sure your{" "}
                <TrackedLink to={`/business/${childBusinessId}/billing-details`}>billing information</TrackedLink> is up
                to date.
              </span>
            }
            type="closable"
          />
        </div>
      ) : null}
      <Table<ParentBusiness>
        caption={caption}
        loading={loadingBusinessParent || removingBusinessParent || delaying}
        rows={parentTableRows}
        columns={parentTable.getAllColumns()}
        headerGroups={parentTable.getHeaderGroups()}
        noDataText="No parent account"
      />
    </>
  );
};
