import { useHistory } from "react-router-dom";
import { type NotificationProps } from "@k8slens/lds/lib/es/Notification/Notification";

import Button from "src/components/Button/TrackedButton";
import LinkButton from "src/components/Button/TrackedRouterButton";
import PublicLayout from "src/components/PublicLayout/PublicLayout";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

import { useQuery } from "src/hooks/useQuery";
import { useCallback, useMemo } from "react";
import { useVerifySecondaryEmail } from "src/hooks/useVerifySecondaryEmail";
import decode from "jwt-decode";

import styles from "./page.module.css";

const panelProps = {
  footerProps: {
    type: "grid",
    gridSize: 4,
  },
  contentProps: {
    className: styles.content,
  },
};
const notificationProps: Partial<NotificationProps> = { level: "error" };

export const VerifySecondaryEmail = () => {
  const history = useHistory();
  const { verifySecondaryEmail, error } = useVerifySecondaryEmail();
  const query = useQuery();
  const token = useMemo(() => query.get("token"), [query]);
  const tokenPayload = useMemo(() => {
    if (token) {
      try {
        return decode<{ username: string; email: string }>(token);
      } catch {
        return undefined;
      }
    }

    return undefined;
  }, [token]);

  const verify = useCallback(async () => {
    if (token) {
      const exception = await verifySecondaryEmail(token);

      if (!exception) {
        history.push("/emails");
      }
    }
  }, [token, history, verifySecondaryEmail]);

  const showError = !(token && tokenPayload);

  const errorMessage = useMemo(() => {
    if (error) {
      return error ? error.message || "Unknown Error" : undefined;
    } else if (showError) {
      return "Something went wrong";
    }

    return undefined;
  }, [error, showError]);

  return (
    <PublicLayout
      title={`Verify ${tokenPayload?.email ?? "Email"}`}
      size="lg"
      panelProps={panelProps}
      notification={errorMessage}
      notificationProps={notificationProps}
      footer={
        <>
          <LinkButton to="/home" label="Cancel" discreet />
          <div />
          <div />
          <Button label="Verify" onClick={verify} loadingStateOnPromise primary disabled={!token} />
        </>
      }
    >
      <>
        {!showError && "Click the button below to verify the email address."}
        {showError && (
          <div className={styles.error}>
            <p>There seems to be some issues with your verification token.</p>
            <p>
              Try re-opening the link in your email or trigger the verification process again. If that doesn&apos;t
              help, please contact us at <TrackedAnchor href="mailto:info@k8slens.dev">info@k8slens.dev.</TrackedAnchor>
            </p>
          </div>
        )}
      </>
    </PublicLayout>
  );
};
