import { START_TIME } from "src/constants";
import { getUploadCount } from "./useUploadAvatar";

/**
 * Appends to the provided url a short encoded url parameter
 * based on the application start time and the avatar upload count.
 * Handles avatar update issues caused by browser cache (unchanging image url).
 * Replaces previously used random urls to avoid loading image on each render
 *
 * @param url - avatar url
 */
const useAvatarUrl = (url: undefined | string) => {
  const uploadCount = getUploadCount();
  const counter = START_TIME + uploadCount * Math.pow(10, 12);

  if (!url) {
    return undefined;
  }

  return `${url}?${counter.toString(36)}`;
};

export default useAvatarUrl;
