import { useAnalytics } from "src/hooks/useAnalytics";

import styles from "./OpenModalButton.module.css";

interface Props {
  handleClick: () => void;
  label?: string;
}

const OpenModalButton = ({ handleClick, label }: Props) => {
  const { trackButtonClicked } = useAnalytics();
  const openModalButtonLabel = label ?? "Create Lens Business ID…";

  return (
    <button
      aria-label={openModalButtonLabel}
      type="button"
      onClick={() => {
        trackButtonClicked(openModalButtonLabel);
        handleClick();
      }}
      className={styles.openModalButton}
    >
      {openModalButtonLabel}
    </button>
  );
};

export default OpenModalButton;
