import { PropsWithChildren, ReactNode, useCallback, useEffect, useRef } from "react";
import clsx from "clsx";
import { Listbox, Transition } from "@headlessui/react";
import { Button } from "@k8slens/lds";
import { navigation } from "@k8slens/lds-icons";

import styles from "./Select.module.css";

const { ArrowDownIcon, ArrowUpIcon } = navigation;

export type DefaultOption = { id: string; label: string };

interface Props<T> {
  id?: string;
  "aria-label"?: string;
  options: Array<T>;
  value: T | undefined;
  onChange(d: T): void;
  placeholder?: string;
  name?: string;
  className?: string;
  /** `className` to be passed (and merged with default className) to `<Listbox.Options />` */
  optionsClassName?: string;
  /** `className` to be passed (and merged with default className) to `<Listbox.Option />` */
  optionClassName?: string | ((state: { active: boolean; selected: boolean; disabled: boolean }) => string);
  inline?: boolean;
  discreet?: boolean;
  loading?: boolean;
  disabled?: boolean;
  /** tooltip of the select element */
  title?: string;
  renderContent?(d: T | undefined, placeholder?: string): ReactNode;
  renderOption?(d: T, optionIndex: number): ReactNode;
  /** Render at after the last option. @remarks Only render when `options.length > 0` */
  renderAfterLastOption?(): ReactNode;
  /** Render when `options.length ==== 0` */
  renderEmptyOption?(): ReactNode;
  /** Set to false to unlock the dropdown if there is only one option */
  lockIfSingleOption?: boolean;
}

export interface SelectProps<T> extends Props<T> {
  buttonRef?: React.MutableRefObject<HTMLButtonElement | null>;
}

export interface SelectPropsWithButtonRenderer<T> extends Props<T> {
  renderButton(d: T | undefined, open: boolean, placeholder?: string): ReactNode;
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>;
}

export default function Select<T extends DefaultOption>({
  id,
  "aria-label": ariaLabel,
  value,
  options,
  onChange,
  placeholder = "",
  name,
  inline,
  discreet,
  loading,
  disabled,
  className,
  optionsClassName,
  optionClassName,
  renderContent = (d: T | undefined, placeholder?: string) => (d ? d.label : placeholder),
  renderAfterLastOption,
  renderEmptyOption,
  lockIfSingleOption,
  title,
  children,
  ...selectProps
}: PropsWithChildren<SelectProps<T>> | PropsWithChildren<SelectPropsWithButtonRenderer<T>>) {
  const localButtonRef = useRef<null | HTMLButtonElement>(null);
  const listRef = useRef<null | HTMLUListElement>(null);

  // Make dropdown "disabled" if there is only one option to choose from
  const locked = lockIfSingleOption !== false && options.length === 1 && typeof renderAfterLastOption !== "function";

  const handleBeforeEnter = () => {
    if (!listRef?.current || listRef.current.getAttribute("data-placement") !== null) {
      return;
    }
    const clientRect = listRef.current.getBoundingClientRect();
    const bottom = clientRect?.bottom;

    const buttonHeight = localButtonRef.current?.getBoundingClientRect().height || 0;

    // Calculate positioning
    if (bottom && bottom > window.innerHeight + window.scrollY) {
      listRef.current.setAttribute("data-placement", "top");
      listRef.current.style.bottom = `${buttonHeight}px`;
    } else {
      listRef.current.setAttribute("data-placement", "bottom");
      listRef.current.style.bottom = "auto";
    }
  };

  const handleAfterLeave = () => {
    listRef?.current?.removeAttribute("data-placement");
  };

  const defaultButtonRenderer = useCallback(
    (value: T | undefined, open: boolean, placeholder?: string) => (
      <Listbox.Button
        id={id}
        ref={selectProps.buttonRef ? selectProps.buttonRef : localButtonRef}
        as={Button}
        // `label` type is not properly overridden qith HeadlessUI `as` so we need to use any
        label={renderContent(value, placeholder) as any}
        aria-label={ariaLabel}
        loading={loading}
        discreet
      >
        {open ? <ArrowUpIcon className={styles.dropDownIcon} /> : <ArrowDownIcon className={styles.dropDownIcon} />}
      </Listbox.Button>
    ),
    [selectProps, id, loading, ariaLabel, renderContent],
  );

  // Track external button ref
  useEffect(() => {
    if (selectProps.buttonRef?.current) {
      localButtonRef.current = selectProps.buttonRef?.current;
    }
  }, [selectProps.buttonRef]);

  const renderButton = "renderButton" in selectProps ? selectProps.renderButton : defaultButtonRenderer;
  const renderOption =
    typeof selectProps.renderOption === "function"
      ? selectProps.renderOption
      : (d: T, _: number, placeholder: string) => renderContent(d, placeholder);

  return (
    <div
      className={clsx(styles.selectWrapper, className, {
        [styles.inline]: inline,
        [styles.discreet]: discreet,
        [styles.locked]: locked,
      })}
      title={title}
    >
      <Listbox value={value} onChange={onChange} name={name} disabled={disabled}>
        {({ open }) => (
          <>
            {children}
            <div className={clsx(styles.select)}>
              {renderButton(value, open, placeholder)}
              <Transition
                // Tests fail when unmount={true}
                unmount={false}
                beforeEnter={handleBeforeEnter}
                afterLeave={handleAfterLeave}
                enter="transition duration-75 ease-in-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-100 ease-in-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Listbox.Options ref={listRef} className={clsx(styles.selectOptions, optionsClassName)}>
                  {options.length > 0 ? (
                    options.map((option, index) => (
                      <Listbox.Option
                        key={option.id}
                        value={option}
                        className={(state) =>
                          clsx(
                            {
                              [styles.active]: state.active,
                              // TODO: Remove comparison to current value when `@headlessui/react` fix this bug
                              [styles.selected]: state.selected || value?.id === option.id,
                            },
                            (typeof optionClassName === "function"
                              ? optionClassName({
                                  ...state,
                                  // TODO: Remove comparison to current value when `@headlessui/react` fix this bug
                                  selected: state.selected || value?.id === option.id,
                                })
                              : optionClassName) ?? "",
                          )
                        }
                      >
                        {renderOption(option, index, placeholder)}
                      </Listbox.Option>
                    ))
                  ) : (
                    <>{typeof renderEmptyOption === "function" && loading !== true ? renderEmptyOption() : null}</>
                  )}
                  {typeof renderAfterLastOption === "function" && options.length > 0 ? renderAfterLastOption() : null}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
