import { useContext, useMemo } from "react";
import { LoadingIndicator, Notification } from "@k8slens/lds";
import clsx from "clsx";

import { useBusinessParent } from "src/hooks/useBusinessParent";
import { useIsControlCenterEnabled } from "src/hooks/useIsControlCenterEnabled";
import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";

import { BusinessContext } from "src/components/Business/Base";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

import { Children } from "./Children";
import { Parent } from "./Parent";

import styles from "./AccountHierarchy.module.css";

const AccountHierarchy = () => {
  const { businessId, business } = useContext(BusinessContext);
  const { loading: loadingSubscriptions, businessSubscriptions } = useGetBusinessSubscriptions(businessId);
  const { controlCenterEnabled, loading } = useIsControlCenterEnabled(
    loadingSubscriptions ? undefined : businessSubscriptions,
  );

  const { businessParent } = useBusinessParent(businessId);
  const hasParent = useMemo(() => businessParent.length > 0, [businessParent]);

  return (
    <div className={styles.accountHierarchy}>
      <header>
        <h2 className={clsx("lds-h2-caps", styles.welcome)}>Account Hierarchy</h2>
        <p>
          {/* eslint-disable-next-line max-len */}
          Account hierarchy provides the ability for a parent Lens Business ID to pay the charges for children Lens
          Business IDs. This allows large organizations with multiple departments or software resellers with multiple
          customers to consolidate the billing to the parent organization while allowing the child to manage the Lens
          Business ID.
        </p>
      </header>
      {loading && <LoadingIndicator size="2xl" />}
      {!loading && (
        <>
          {!controlCenterEnabled && (
            <Notification
              level="info"
              className={styles.enterpriseAccNotification}
              message={
                <>
                  {"Please "}
                  <TrackedAnchor href="https://k8slens.dev/purchase-request" target="_blank" rel="noreferrer">
                    contact sales
                  </TrackedAnchor>
                  {" to access Lens Control Center features."}
                </>
              }
            />
          )}
          <section
            className={clsx({
              [styles.disabledSection]: !controlCenterEnabled || business?.external,
            })}
          >
            {/* if the business has a parent, it won't/can't have children accounts */}
            {hasParent ? <Parent /> : <Children />}
          </section>
        </>
      )}
    </div>
  );
};

export default AccountHierarchy;
