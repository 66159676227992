import { ComponentType, ReactNode } from "react";
import { Tab as HeadlessTab } from "@headlessui/react";
import clsx from "clsx";

import styles from "./Tabs.module.css";

type ExtractProps<T> = T extends ComponentType<infer P> ? P : T;

export type TabProps = ExtractProps<typeof HeadlessTab> & {
  label: string | ReactNode;
};

export const Tab: React.FC<TabProps> = ({ label, className, children, ...props }) => (
  <HeadlessTab
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    className={({ selected }: { selected: boolean }) =>
      clsx(styles.tab, className, {
        [styles.selected]: selected,
      })
    }
  >
    {label}
  </HeadlessTab>
);
