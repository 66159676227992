import { UploadAvatarResponse } from "lens-platform-sdk/dist/cjs/UserService";
import { User } from "lens-platform-sdk";
import { Avatar } from "@k8slens/lds";
import clsx from "clsx";

import { getUserAvatar } from "src/utils/user";
import useAvatarUrl from "src/hooks/useAvatarUrl";

import { AvatarUpload } from "src/components/AvatarUpload/AvatarUpload";

import styles from "./AvatarFormComponent.module.css";

interface Props {
  user: User;
  loading?: boolean;
  onAvatarUpload?: (file: File) => Promise<UploadAvatarResponse | undefined>;
  onAvatarError?: (error: Error) => void;
}

const AvatarFormComponent = ({ user, loading, onAvatarUpload, onAvatarError }: Props) => {
  const avatarImage = getUserAvatar(user);
  const avatar = useAvatarUrl(avatarImage && !loading ? avatarImage : undefined);

  return (
    <div>
      <span className="lds-form-label lds-form-field--label">Profile Image</span>
      <div className={clsx("lds-input--wrapper", styles.avatarField)}>
        <Avatar name={user.username ?? ""} size="xl" image={avatar} />
        {onAvatarUpload ? (
          <AvatarUpload
            className={styles.avatarUploadForm}
            loading={Boolean(loading)}
            onAvatarUpload={onAvatarUpload}
            onAvatarError={onAvatarError}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AvatarFormComponent;
