import { type UserBusinessRole } from "lens-platform-sdk";

export const selectRoleLabel = "Assign Role";

export interface RoleOption {
  id: UserBusinessRole;
  label: UserBusinessRole;
}

export const roleOptions: RoleOption[] = [
  { id: "Member", label: "Member" },
  { id: "Administrator", label: "Administrator" },
];
