import { FormSelect } from "src/components/FormComponents/FormSelect";
import { RoleOption, roleOptions, selectRoleLabel } from "./role";

interface Props {
  value?: RoleOption;
  onChange: (value?: RoleOption) => void;
  className?: string;
}

export function RoleSelect({ value, onChange, className }: Props) {
  return (
    <FormSelect
      name="role-selection"
      label={selectRoleLabel}
      loading={false}
      value={value}
      onChange={(value) => {
        onChange(value);
      }}
      options={roleOptions}
      renderContent={(value) => <span>{value?.label ?? ""}</span>}
      className={className}
      wrapperProps={{
        className: "!min-h-min",
      }}
    />
  );
}
