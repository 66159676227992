import clsx from "clsx";
import { navigation } from "@k8slens/lds-icons";

import { ProductName } from "./ProductName";

import styles from "./TopBar.module.css";

const { MenuIcon } = navigation;

export default function TopBar({
  className,
  title,
  username,
  burgerRef,
}: {
  className?: string;
  title: string | React.ReactNode;
  username: string | React.ReactNode;
  burgerRef?: React.MutableRefObject<HTMLDivElement | null>;
}) {
  return (
    <div className={clsx(styles.topbar, className)}>
      <div className={styles.userName}>
        <span>{username}</span>
      </div>
      <div className={styles.title}>
        <ProductName title={title} />
      </div>
      {burgerRef ? (
        <div className={styles.burgerContainer}>
          <div ref={burgerRef} className={styles.burger} aria-hidden role="button">
            <MenuIcon size="lg" />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
