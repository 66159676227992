import { forwardRef, ForwardedRef } from "react";
import { useLocation } from "react-router-dom";

import { useAnalytics } from "src/hooks/useAnalytics";
import { useTrackedLinkClickHandler } from "src/hooks/useTrackedLinkClickHandler";

import RouterButton, { RouterButtonProps } from "./RouterButton";

const TrackedRouterButton = forwardRef<HTMLAnchorElement, RouterButtonProps>(
  (
    { to, href, onClick, label, children, disabled, ...props }: RouterButtonProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => {
    const location = useLocation<unknown>();
    const { trackButtonClicked } = useAnalytics();

    const handleClick = useTrackedLinkClickHandler<unknown>({
      to,
      href,
      onClick,
      children,
      disabled,
      location,
      elementType: "Button",
      label,
      tracker: trackButtonClicked,
    });

    return (
      <RouterButton
        ref={ref}
        to={to}
        href={href}
        onClick={handleClick}
        label={label}
        disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </RouterButton>
    );
  },
);

export default TrackedRouterButton;
