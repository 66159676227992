export const getRedirectAuthUri = (search: string, lensDesktopRedirectUri: string) => {
  const searchParams = new URLSearchParams(search);

  const state = searchParams.get("state");
  const sessionState = searchParams.get("session_state");
  const iss = searchParams.get("iss");
  const code = searchParams.get("code");

  const forwardSearchParams = new URLSearchParams({
    state: state ?? "",
    sessionState: sessionState ?? "",
    iss: iss ?? "",
    code: code ?? "",
  });

  return `${lensDesktopRedirectUri}?${forwardSearchParams.toString()}`;
};

const minPortNumber = 1;
const maxPortNumber = 65535;

export function isAuthorizedRedirectUri(redirectUri: string) {
  try {
    const lensDesktopRedirectUrl = new URL(redirectUri);

    if (lensDesktopRedirectUrl.hostname === "localhost" && parseInt(lensDesktopRedirectUrl.port)) {
      const port = parseInt(lensDesktopRedirectUrl.port);

      return minPortNumber <= port && port <= maxPortNumber;
    }
  } catch {
    return false;
  }

  return false;
}
