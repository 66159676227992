import { useMemo } from "react";
import { useQuery } from "./useQuery";

/**
 * Get the invitation token from url search query `?businessChildInvitationToken=value`
 */
export const useBusinessChildInvitationToken = () => {
  const query = useQuery();

  const businessChildInvitationToken = useMemo(() => query.get("businessChildInvitationToken"), [query]);

  return businessChildInvitationToken;
};
