import { useEffect, useState, useRef, useCallback } from "react";
import { LensSDKException } from "lens-platform-sdk";
import { Invoice } from "lens-platform-sdk/dist/cjs/UserService";
import { useLensPlatformClient } from "./useLensPlatformClient";

/**
 * Get business invoices
 */
export const useGetBusinessInvoices = (businessId?: string) => {
  const lensPlatformClient = useLensPlatformClient();

  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const [error, setError] = useState<LensSDKException | null>(null);
  const [loading, setLoading] = useState(false);

  // Keep up with mounted/unmounted state
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const getInvoices = useCallback(async () => {
    try {
      if (mounted.current) {
        setLoading(true);
      }

      if (businessId) {
        const response = await lensPlatformClient.business.getBusinessInvoices(businessId);

        if (mounted.current) {
          setInvoices(response);
          setError(null);
        }
      }
    } catch (exception) {
      if (mounted.current && exception instanceof LensSDKException) {
        setError(exception);
      }
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [lensPlatformClient.business, businessId]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  return {
    loading,
    error,
    invoices,
    reload: getInvoices,
  };
};
