import clsx from "clsx";
import { type CellContext, type ColumnMeta } from "@tanstack/table-core";

import { trimUUID } from "src/services/trimUUID";

import styles from "./SubscriptionIdCell.module.css";

const SubscriptionIdCell = <T, R extends string | null | undefined>({
  getValue,
  className,
}: CellContext<T, R> & { className?: string }) => {
  const value = getValue() || "";

  return (
    <span className={clsx(styles.subscriptionId, className)} title={value}>
      {value ? trimUUID(value) : null}
    </span>
  );
};

export const subscriptionIdMeta: ColumnMeta<unknown, unknown> | undefined = { primary: true };

export const subscriptionIdColDef = {
  meta: subscriptionIdMeta,
  header: () => <span>Subscription ID</span>,
  cell: SubscriptionIdCell,
};

export default SubscriptionIdCell;
