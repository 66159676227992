import { useMemo } from "react";
import type { BusinessInvitation, BusinessInvitationState } from "lens-platform-sdk";
import { LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useQuery } from "@tanstack/react-query";

/**
 * invitations with role="Administrator" to the business
 */
export type BusinessAdminInvitation = BusinessInvitation & {
  role: "Administrator";
};

/**
 * Get user's business entity (Lens Business IDs)'s invitations by business entity ID.
 */
export function useGetBusinessInvitations(businessId?: string, businessInvitationState?: BusinessInvitationState) {
  const lensPlatformClient = useLensPlatformClient();

  const {
    isLoading: loading,
    error,
    data: businessInvitations,
    refetch,
  } = useQuery<BusinessInvitation[], LensSDKException>({
    queryKey: ["businessInvitations", businessId],
    queryFn: async () => {
      if (!businessId) {
        throw new Error("Invalid Lens Business ID");
      }
      const invitations = await lensPlatformClient.business.getInvitations(businessId);

      return invitations;
    },
    initialData: [],
  });

  const filteredBusinessInvitations = useMemo(
    () =>
      businessInvitationState
        ? businessInvitations.filter((invitation) => invitation.state === businessInvitationState)
        : businessInvitations,
    [businessInvitationState, businessInvitations],
  );

  return {
    loading,
    error,
    businessInvitations: filteredBusinessInvitations,
    reload: refetch,
  };
}
