import { Business, LensSDKException, BusinessHierarchyInvitation, NotFoundException } from "lens-platform-sdk";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "src/queryClient";
import { useState } from "react";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useAnalytics } from "./useAnalytics";

export const businessChildrenInvitationQueryKey = "businessChildrenInvitation";

/**
 * Hooks for create, cancel, read business (Lens Business IDs) child invitations.
 */
export const useBusinessChildInvitation = (businessId?: Business["id"]) => {
  const lensPlatformClient = useLensPlatformClient();
  const { track } = useAnalytics();

  const {
    isLoading: loadingBusinessChildrenInvitations,
    error: errorLoadingBusinessChildrenInvitations,
    data: businessChildrenInvitations,
    refetch: refetchBusinessChildrenInvitation,
  } = useQuery<BusinessHierarchyInvitation[], LensSDKException>({
    queryKey: [businessChildrenInvitationQueryKey, businessId],
    queryFn: async () => {
      if (!businessId) {
        return [];
      }

      try {
        const invitations = await lensPlatformClient.business.getManyChildrenInvitation(businessId);

        return invitations;
      } catch (error) {
        if (error instanceof NotFoundException) {
          return [];
        }
        throw error;
      }
    },
    initialData: [],
  });

  const [newlyCreatedChildInvitation, setNewlyCreatedChildInvitation] = useState<BusinessHierarchyInvitation>();
  const {
    isPending: creatingChildInvitation,
    isSuccess: creatingChildInvitationSuccess,
    error: errorCreatingChildInvitation,
    mutate: createChildInvitation,
  } = useMutation<BusinessHierarchyInvitation, LensSDKException, Business["id"]>({
    mutationFn: async (parentBusinessId: Business["id"]) =>
      lensPlatformClient.business.createChildInvitation(parentBusinessId),

    onSuccess: async (invitation) => {
      await queryClient.invalidateQueries({
        queryKey: [businessChildrenInvitationQueryKey, businessId],
      });
      track("BusinessID child invitation created", { businessId });
      setNewlyCreatedChildInvitation(invitation);
    },
  });

  const {
    isPending: cancelingChildInvitation,
    isSuccess: cancelingChildInvitationSuccess,
    error: errorCancelingChildInvitation,
    mutate: cancelChildInvitation,
  } = useMutation<
    BusinessHierarchyInvitation,
    LensSDKException,
    { parentBusinessId: Business["id"]; invitationId: BusinessHierarchyInvitation["id"] }
  >({
    mutationFn: async ({ parentBusinessId, invitationId }) =>
      lensPlatformClient.business.updateOneChildInvitation(parentBusinessId, invitationId, "canceled"),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [businessChildrenInvitationQueryKey, businessId],
      });
      track("BusinessID child removed", { businessId });
    },
  });

  const {
    isPending: acceptingChildInvitation,
    isSuccess: acceptingChildInvitationSuccess,
    error: errorAcceptingChildInvitation,
    mutate: acceptChildInvitation,
  } = useMutation<
    BusinessHierarchyInvitation,
    LensSDKException,
    {
      childBusinessId: Business["id"];
      token: BusinessHierarchyInvitation["token"];
    }
  >({
    mutationFn: async ({ childBusinessId, token }) =>
      lensPlatformClient.business.acceptChildInvitation(childBusinessId, "accepted", token),

    onSuccess: async (_, { childBusinessId }) => {
      await queryClient.invalidateQueries({
        queryKey: [businessChildrenInvitationQueryKey, businessId],
      });
      track("BusinessID child invitation accepted", { businessId: childBusinessId });
    },
  });

  const {
    isPending: confirmingChildInvitation,
    isSuccess: confirmingChildInvitationSuccess,
    error: errorConfirmingChildInvitation,
    mutate: confirmChildInvitation,
  } = useMutation<
    BusinessHierarchyInvitation,
    LensSDKException,
    {
      childBusinessId: Business["id"];
      token: BusinessHierarchyInvitation["token"];
    }
  >({
    mutationFn: async ({ childBusinessId, token }) =>
      lensPlatformClient.business.acceptChildInvitation(childBusinessId, "confirmed", token),

    onSuccess: async (_, { childBusinessId }) => {
      await queryClient.invalidateQueries({
        queryKey: [businessChildrenInvitationQueryKey, businessId],
      });
      track("BusinessID child invitation confirmed", { businessId: childBusinessId });
    },
  });

  const {
    isPending: rejectingChildInvitation,
    isSuccess: rejectingChildInvitationSuccess,
    error: errorRejectingChildInvitation,
    mutate: rejectChildInvitation,
  } = useMutation<
    BusinessHierarchyInvitation,
    LensSDKException,
    {
      childBusinessId: Business["id"];
      token: BusinessHierarchyInvitation["token"];
    }
  >({
    mutationFn: async ({ childBusinessId, token }) =>
      lensPlatformClient.business.acceptChildInvitation(childBusinessId, "rejected", token),

    onSuccess: async (_, { childBusinessId }) => {
      await queryClient.invalidateQueries({
        queryKey: [businessChildrenInvitationQueryKey, businessId],
      });
      track("BusinessID child invitation rejected", { businessId: childBusinessId });
    },
  });

  return {
    loadingBusinessChildrenInvitations,
    errorLoadingBusinessChildrenInvitations,
    businessChildrenInvitations,
    refetchBusinessChildrenInvitation,
    creatingChildInvitation,
    creatingChildInvitationSuccess,
    errorCreatingChildInvitation,
    createChildInvitation,
    newlyCreatedChildInvitation,
    cancelingChildInvitation,
    cancelingChildInvitationSuccess,
    errorCancelingChildInvitation,
    cancelChildInvitation,
    acceptingChildInvitation,
    acceptingChildInvitationSuccess,
    errorAcceptingChildInvitation,
    acceptChildInvitation,
    confirmingChildInvitation,
    confirmingChildInvitationSuccess,
    confirmChildInvitation,
    errorConfirmingChildInvitation,
    rejectingChildInvitation,
    rejectingChildInvitationSuccess,
    errorRejectingChildInvitation,
    rejectChildInvitation,
  };
};
