import { useContext } from "react";
import { ProfileContext } from "src/providers/profile-provider";
import { useAuth } from "./useAuth";
import { DecodedAccessToken } from "src/components/pages/Home/license";

const fallBackUsername = (tokenParsed: DecodedAccessToken | undefined) => {
  if (tokenParsed) {
    // `tokenParsed.preferred_username` is from Github
    // most users are sign-up from Github, prefer Github username
    // `tokenParsed.name` seems to be the same as `tokenParsed.given_name`
    const username = tokenParsed.preferred_username ?? tokenParsed.name ?? tokenParsed.given_name ?? "";

    return username;
  }

  return "";
};

export const useUsernameFromToken = () => {
  const { tokenParsed } = useAuth();

  return fallBackUsername(tokenParsed);
};

export const useUsername = () => {
  const { profile } = useContext(ProfileContext);

  const usernameFromToken = useUsernameFromToken();

  // fallback to the username in token if the profile is still loading
  return profile?.username ? profile?.username : usernameFromToken;
};
