import React, { useContext, useMemo } from "react";
import { FormInput } from "@k8slens/lds-form";

import { useBusinessSSO } from "src/hooks/useBusinessSSO";

import { BusinessContext } from "src/components/Business/Base";
import PageAction from "src/components/PageAction/PageAction";

import styles from "./SamlSSOProviderForm.module.css";

interface Props {
  onSubmit: () => void;
  samlSSOServiceUrl: InputState;
  samlSSOIdpEntityId: InputState;
  dispatch: React.Dispatch<{
    key: SAMLFormFieldKey;
    value: SAMLFormField[SAMLFormFieldKey]["value"];
    valid: SAMLFormField[SAMLFormFieldKey]["valid"];
  }>;
}

type InputState = {
  value: string;
  valid: boolean;
};

export type SAMLFormField = {
  samlSSOServiceUrl: { value: string; valid: boolean };
  samlSSOIdpEntityId: { value: string; valid: boolean };
};

export type SAMLFormFieldKey = keyof SAMLFormField;

export const SamlSSOProviderForm = ({ onSubmit, samlSSOServiceUrl, samlSSOIdpEntityId, dispatch }: Props) => {
  const { businessId } = useContext(BusinessContext);
  const { creatingBusinessSSO } = useBusinessSSO(businessId);

  const valid = useMemo(
    () => [samlSSOServiceUrl, samlSSOIdpEntityId].every(({ valid }) => valid !== false),
    [samlSSOServiceUrl, samlSSOIdpEntityId],
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onSubmit();
  };

  return (
    <form aria-label="Saml SSO Provider settings" onSubmit={handleSubmit}>
      <fieldset>
        <FormInput
          wrapperProps={{ className: styles.singleSignOnServiceUrl }}
          label="SSO Service URL"
          type="url"
          name="singleSignOnServiceUrl"
          placeholder="https://accounts.google.com/o/saml2/idp?idpid=xyz"
          value={samlSSOServiceUrl.value}
          onChange={(value, valid) => dispatch({ key: "samlSSOServiceUrl", value, valid })}
          required
        />
        <FormInput
          wrapperProps={{ className: styles.idpEntityId }}
          label="Identity provider Entity ID"
          type="url"
          name="idpEntityId"
          placeholder="https://accounts.google.com/o/saml2?idpid=xyz"
          value={samlSSOIdpEntityId.value}
          onChange={(value, valid) => dispatch({ key: "samlSSOIdpEntityId", value, valid })}
          required
        />
        <PageAction
          label="Save single sign-on settings"
          buttonProps={{
            onClick: handleSubmit,
            disabled: !valid,
            loading: creatingBusinessSSO,
            textTransform: "capitalize",
          }}
        />
      </fieldset>
    </form>
  );
};
