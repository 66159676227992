import { useMemo } from "react";
import { useGetBusinessInvitations } from "./useGetBusinessInvitations";

export const useGetPendingBusinessSubscriptionInvitations = (businessId: string, subscriptionId: string) => {
  const { businessInvitations } = useGetBusinessInvitations(businessId);

  return useMemo(
    () =>
      businessInvitations.filter(
        (invitation) => invitation.subscriptionId === subscriptionId && invitation.state === "pending",
      ),
    [businessInvitations, subscriptionId],
  );
};
