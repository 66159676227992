import {
  keycloakAuthResponseMode,
  keycloakAuthResponseType,
  keycloakAuthScope,
  keycloakOpenIdConnectRegistrationEndpoint,
} from "src/constants";

export const createRegistrationUrl = (signUpClientId: string, signUpRedirectUri: string) => {
  const params = new URLSearchParams({
    client_id: signUpClientId,
    redirect_uri: signUpRedirectUri,
    response_type: keycloakAuthResponseType,
    response_mode: keycloakAuthResponseMode,
    scope: keycloakAuthScope,
  });

  return `${keycloakOpenIdConnectRegistrationEndpoint}?${params.toString()}`;
};
