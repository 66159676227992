import { useEffect, useState } from "react";
import useLocalStorageState from "use-local-storage-state";
import { ButtonBar } from "@k8slens/lds";
import { navigation } from "@k8slens/lds-icons";

import { UserBusinessSSOConnection } from "src/hooks/useGetUserSSO";
import { useAnalytics } from "src/hooks/useAnalytics";

import Button from "src/components/Button/TrackedButton";
import DrawerTransition from "src/components/DrawerTransition/DrawerTransition";

import styles from "./UserSSOBanner.module.css";

const { CloseIcon } = navigation;

export interface UserSSOBannerProps {
  userSSOLinks: UserBusinessSSOConnection[] | undefined;
}

export const UserSSOBanner = ({ userSSOLinks }: UserSSOBannerProps) => {
  const { track } = useAnalytics();
  const [ssoBanners, setSSOBanners] = useState<UserBusinessSSOConnection[]>([]);

  const [userClosedSSOBanners, setUserClosedSSOBanners] = useLocalStorageState<{
    [key: string]: number;
  }>("userClosedSSOBanners", {
    defaultValue: {},
  });

  const handleCloseSSOBanner = (userSSO: UserBusinessSSOConnection) => {
    if (!userSSO.sso?.identityProviderID) {
      return;
    }

    const id = userSSO.sso?.identityProviderID;

    track("User SSO Banner Closed", { id });
    setUserClosedSSOBanners((closedBanners) => {
      return {
        ...closedBanners,
        [id]: new Date().getTime(),
      };
    });
  };

  const handleAuthenticate = (userSSO: UserBusinessSSOConnection) => {
    window.open(userSSO.link, "_blank");
  };

  useEffect(() => {
    if (userSSOLinks) {
      const banners = userSSOLinks.filter(
        (connection) => connection.sso?.identityProviderID && !userClosedSSOBanners[connection.sso.identityProviderID],
      );

      setSSOBanners(banners);
    }
  }, [userClosedSSOBanners, userSSOLinks]);

  return (
    <DrawerTransition className={styles.transition} open={Boolean(ssoBanners.length)}>
      <div role="region" aria-labelledby="sso-notification" className={styles.ssoBanner}>
        <article className={styles.content}>
          <h2 id="sso-notification">
            Single sign-on has been enabled for <span className="capitalize">{ssoBanners[0]?.name}</span>
          </h2>
          <p>
            Single sign-on allows you to sign-in to access this organization using your company's preferred identity
            provider.
          </p>
        </article>
        <ButtonBar className={styles.buttonBar} role="footer">
          <Button
            label="Authenticate"
            onClick={() => handleAuthenticate(ssoBanners[0])}
            loadingStateOnPromise
            primary
            discreet
          />
        </ButtonBar>
        <button
          className={styles.closeButton}
          aria-label="Remind me later"
          title="Remind me later"
          onClick={() => handleCloseSSOBanner(ssoBanners[0])}
        >
          <CloseIcon />
        </button>
      </div>
    </DrawerTransition>
  );
};
