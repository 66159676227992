import LinkButton from "src/components/Button/TrackedRouterButton";
import PublicLayout from "src/components/PublicLayout/PublicLayout";

import sadRobot from "src/img/sad-robot.png";

const panelProps = {
  footerProps: {
    type: "centered",
  },
};

export const NotFound = () => (
  <PublicLayout
    title="Page not found"
    panelProps={panelProps}
    footer={<LinkButton to="/home" label="Return to home page" primary />}
  >
    <img src={sadRobot} alt="Page not found" />
  </PublicLayout>
);
