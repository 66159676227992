import Spinner from "src/components/Spinner/Spinner";
import Page from "src/components/Page/Page";
import { useDiscourseUrl } from "src/hooks/useDiscourseUrl";
import styles from "./page.module.css";

export const DiscourseSSO = () => {
  const { isLoading, data: discourseUrl, error, isSuccess } = useDiscourseUrl();

  if (isSuccess && discourseUrl) {
    // eslint-disable-next-line xss/no-location-href-assign
    window.location.href = discourseUrl;

    return (
      <Loading>
        <span className={styles.message}>Launching Lens Forums...</span>
      </Loading>
    );
  }

  if (isLoading) {
    return (
      <Loading>
        <span className={styles.message}>Loading...</span>
      </Loading>
    );
  }

  return (
    <Loading>
      <span className={styles.errorMessage}>{error?.message ?? "Unknown error"}</span>
    </Loading>
  );
};

function Loading({ children }: { children: React.ReactElement }) {
  return (
    <Page>
      <Spinner center>{children}</Spinner>
    </Page>
  );
}
