import { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { type NotificationProps } from "@k8slens/lds/lib/es/Notification/Notification";

import { getPlanData, getPlanName, PlanCode } from "src/services/plans";
import { useLicenseActivation } from "src/hooks/useActivateLicense";
import { ProfileContext } from "src/providers/profile-provider";
import { useAnalytics } from "src/hooks/useAnalytics";
import { useBusinessInvitation } from "src/hooks/useBusinessInvitation";
import { useQuery } from "src/hooks/useQuery";
import { useAuth } from "src/hooks/useAuth";

import Button from "src/components/Button/TrackedButton";
import LinkButton from "src/components/Button/TrackedRouterButton";
import LicenseImage from "src/components/LicenseImage/LicenseImage";
import PublicLayout from "src/components/PublicLayout/PublicLayout";
import FeatureList from "src/components/FeatureList/FeatureList";
import CurrentUser from "src/components/CurrentUser/CurrentUser";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

import styles from "./page.module.css";

const panelProps = {
  footerProps: {
    type: "grid",
    gridSize: 4,
  },
  contentProps: {
    className: styles.content,
  },
};
const notificationProps: Partial<NotificationProps> = { level: "error", type: "flash" };

export const Activate: React.FC = () => {
  const { track, trackError } = useAnalytics();
  const history = useHistory();
  const { licenseError, activateBusinessSubscription } = useLicenseActivation();
  const { updateToken } = useAuth();
  const { profile } = useContext(ProfileContext);
  const query = useQuery();
  const { businessId, invitationId, subscriptionId, planCode } = useMemo(() => {
    return {
      businessId: query.get("businessId"),
      invitationId: query.get("invitationId"),
      subscriptionId: query.get("subscriptionId"),
      planCode: query.get("planCode"),
    };
  }, [query]);
  const { businessInvitation } = useBusinessInvitation(businessId, invitationId);
  const type = "pro";

  const username = profile?.username;

  const handleActivate = async () => {
    if (businessId && invitationId && subscriptionId) {
      const active = await activateBusinessSubscription(subscriptionId, invitationId, businessId);

      if (active) {
        track("Lens Business Subscription Activated", {
          subscriptionId,
        });
        await updateToken();
        history.push("/home");

        return true;
      }
      trackError("Lens Business Subscription Activation Failed", {
        subscriptionId,
      });

      return true;
    }

    return false;
  };

  const planData = getPlanData(planCode as PlanCode);
  const invalidKey = !invitationId || !subscriptionId || !planCode;

  const errorMessage = useMemo(() => {
    if (licenseError) {
      return licenseError;
    } else if (invalidKey) {
      return "Something went wrong";
    }

    return undefined;
  }, [licenseError, invalidKey]);

  return (
    <PublicLayout
      title="Lens Subscription Activation"
      size="xl"
      pageFooter={<CurrentUser />}
      header={
        !invalidKey && (
          <p>
            You have been assigned with <strong>{getPlanName(planCode)}</strong> Lens Subscription by{" "}
            <strong>{businessInvitation?.business?.name}</strong>.
          </p>
        )
      }
      panelProps={panelProps}
      notification={errorMessage}
      notificationProps={notificationProps}
      footer={
        <>
          <LinkButton to="/home" label="Cancel" discreet />
          <div />
          <div />
          <Button
            label="Accept"
            onClick={handleActivate}
            loadingStateOnPromise
            disabled={invalidKey || !username}
            primary
          />
        </>
      }
    >
      <>
        {invalidKey && (
          <div className={styles.error}>
            <p>There seems to be some issues with your activation key.</p>
            <p>
              Try re-opening the link in your activation email. If that doesn&apos;t help, please contact us at{" "}
              <TrackedAnchor href="mailto:info@k8slens.dev">info@k8slens.dev.</TrackedAnchor>
            </p>
          </div>
        )}
        {!invalidKey && (
          <div className={styles.planInfo}>
            <div>
              <LicenseImage type={planData.type} />
            </div>
            <FeatureList title={`Included in Lens ${planData.nameShort}`} features={planData.features} />
          </div>
        )}
      </>
    </PublicLayout>
  );
};
