/**
 * Returns true if the current URL has auth parameters for sign-in redirect.
 */
export function hasAuthErrorParams(location = window.location) {
  // response_mode: query
  let searchParams = new URLSearchParams(location.search);

  if (searchParams.get("error")) {
    return true;
  }

  // response_mode: fragment
  searchParams = new URLSearchParams(location.hash.replace("#", "?"));

  if (searchParams.get("error")) {
    return true;
  }

  return false;
}
