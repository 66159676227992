import { useMutation } from "@tanstack/react-query";
import { queryClient } from "src/queryClient";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { LensSDKException } from "lens-platform-sdk";
import { UsedSeat } from "lens-platform-sdk/dist/cjs/BusinessService";

export const useActivateBusinessUserSubscription = (businessId?: string, onSuccess?: (usedSeat: UsedSeat) => void) => {
  const lensPlatformClient = useLensPlatformClient();

  const {
    isPending: loading,
    error,
    mutate: activate,
    data: usedSeat,
  } = useMutation<
    UsedSeat,
    LensSDKException,
    {
      businessId: string;
      businessSubscriptionId?: string;
      businessInvitationId?: string;
      userId?: string;
    }
  >({
    mutationFn: async ({ businessId, businessSubscriptionId, businessInvitationId, userId }) => {
      const usedSeat = await lensPlatformClient.business.activateBusinessUserSubscription({
        businessId,
        businessSubscriptionId,
        businessInvitationId,
        userId,
      });

      return usedSeat;
    },

    onSuccess: async (usedSeat) => {
      await queryClient.invalidateQueries({ queryKey: ["businessSubscriptions", businessId] });
      await queryClient.invalidateQueries({ queryKey: ["businessUsers", businessId] });
      onSuccess?.(usedSeat);
    },
  });

  return {
    loading,
    error,
    usedSeat,
    activate,
  };
};
