import { useQuery } from "@tanstack/react-query";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { LensSDKException } from "lens-platform-sdk";
import { getUserAvatar } from "src/utils/user";

export const useUserAvatar = () => {
  const lensPlatformClient = useLensPlatformClient();

  const {
    isLoading: loadingUserAvatar,
    error: errorLoadingUserAvatar,
    data: userAvatar,
  } = useQuery<string, LensSDKException>({
    queryKey: ["user-avatar"],
    queryFn: async () => {
      const username = await lensPlatformClient.user.getUsername();
      const profile = await lensPlatformClient.user.getOne(
        {
          username,
        },
        "join=userAttributes",
      );

      return getUserAvatar(profile) ?? "";
    },
  });

  return { loadingUserAvatar, errorLoadingUserAvatar, userAvatar };
};
