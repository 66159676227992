import { Children, ReactNode } from "react";

export const childrenToString = (children: ReactNode): string => {
  try {
    const arr = Children.map(children, (child) => {
      if (typeof child === "string") {
        return child;
      }

      if (child !== null && typeof child === "object" && "props" in child) {
        return childrenToString(child.props.children);
      }

      return "";
    });

    return arr?.join("") || "";
  } catch (e) {
    return "";
  }
};
