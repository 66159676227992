import { ReactNode, useMemo } from "react";
import { matchPath, NavLinkProps, useLocation } from "react-router-dom";

import { SideNavItem } from "@k8slens/lds";
import type { SideNavItemProps } from "@k8slens/lds/lib/es/SideNav/SideNavItem";

import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";
import TrackedLink from "src/components/TrackedLink/TrackedLink";

export interface Props<T extends object = {}> extends Omit<NavLinkProps<T>, "title"> {
  to: NavLinkProps<T>["to"];
  title: string | ReactNode;
  disabled?: boolean;
  openInNewTab?: boolean;
  externalLink?: false;
}

export interface ExternalLinkProps {
  href: string;
  title: string | ReactNode;
  disabled?: boolean;
  externalLink?: boolean;
}

function MainNavigationLink<T extends object = {}>({
  title,
  disabled,
  externalLink,
  ...props
}: Props<T> | ExternalLinkProps) {
  const location = useLocation<T>();

  type ComponentWithProps<Component extends "a" | typeof TrackedLink<T> | typeof TrackedAnchor> = {
    component: Component;
    itemProps: SideNavItemProps<Component>;
  };

  const matches = useMemo(() => {
    if ("to" in props && typeof props.to === "object") {
      return matchPath(location.pathname, { ...props.to, exact: Boolean(props.exact) });
    }

    if ("to" in props && typeof props.to === "string") {
      return matchPath(location.pathname, { path: props.to, exact: Boolean(props.exact) });
    }

    return null;
  }, [location.pathname, props]);

  const { component, itemProps } = useMemo(() => {
    const isExternalLink = ("openInNewTab" in props && props.openInNewTab) || "href" in props;
    const externalLinkProps = {
      target: "_blank",
      rel: "noreferrer",
      role: "link",
    };

    if (disabled) {
      // Link without href is not focusable
      return {
        component: "a",
        itemProps: {
          disabled,
          externalLink: typeof externalLink === "boolean" ? externalLink : isExternalLink,
          componentProps: isExternalLink
            ? {
                ...externalLinkProps,
              }
            : {},
        },
      } as ComponentWithProps<"a">;
    }

    if ("to" in props) {
      // Router link
      return {
        component: TrackedLink,
        itemProps: {
          active: Boolean(matches),
          disabled,
          externalLink: typeof externalLink === "boolean" ? externalLink : isExternalLink,
          componentProps: {
            to: props.to,
            ...(isExternalLink
              ? {
                  ...externalLinkProps,
                }
              : {}),
          },
        },
      } as ComponentWithProps<typeof TrackedLink<T>>;
    }

    // External link
    return {
      component: TrackedAnchor,
      itemProps: {
        externalLink: typeof externalLink === "boolean" ? externalLink : isExternalLink,
        componentProps: {
          href: props.href,
          ...externalLinkProps,
        },
      },
    } as ComponentWithProps<typeof TrackedAnchor>;
  }, [disabled, props, matches, externalLink]);

  return (
    <SideNavItem
      disabled={disabled}
      component={component}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...itemProps}
    >
      {title}
    </SideNavItem>
  );
}

// Fake SideNavItem to suppress warnings for now
MainNavigationLink.displayName = "SideNavItem";

export default MainNavigationLink;
