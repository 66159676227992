import type { BusinessSubscription } from "lens-platform-sdk";

import { isLensEnterprisePlan, isLensProPlan } from "src/services/plans";

export type AssignableSubscription = BusinessSubscription & { state: "active" | "canceled" };
export type UnassignableSubscription = BusinessSubscription & {
  state: "expired" | "failed" | "future" | "pause";
};
export const unassignableSubscriptionStates = ["expired", "failed", "future", "pause"];

/**
 * @returns true if user can assign seat from give `Subscription`
 */
export const isAssignableSubscription = (subscription: BusinessSubscription): subscription is AssignableSubscription =>
  (isLensProPlan(subscription.planCode) || isLensEnterprisePlan(subscription.planCode)) &&
  (subscription.state === "active" || subscription.state === "canceled");

/**
 * @returns true if user can NOT assign seat from give `Subscription`
 */
export const isUnassignableSubscription = (
  subscription: BusinessSubscription,
): subscription is UnassignableSubscription => unassignableSubscriptionStates.includes(subscription.state);
