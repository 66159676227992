import { memo } from "react";
import clsx from "clsx";

import styles from "./FeatureList.module.css";

export interface Props {
  title?: string;
  className?: string;
  titleAs?: React.ElementType<any>;
  features: Array<string>;
}

const FeatureList: React.FC<Props> = ({ features, className, titleAs: Title = "h4", title }) => (
  <div className={clsx(styles.featureList, className)}>
    {title && <Title className={styles.title}>{title}</Title>}
    <ul>
      {features.map((feature) => (
        <li key={feature}>{feature}</li>
      ))}
    </ul>
  </div>
);

export default memo(FeatureList);
