import { useState, useEffect, useRef } from "react";
import { misc } from "@k8slens/lds-icons";

import styles from "./VisibilityToggle.module.css";

const { VisibilityIcon, VisibilityOffIcon } = misc;

export const VisibilityToggle = ({
  onClick,
  onHide,
  autoHide,
  autoHideInMs,
  buttonProps,
}: {
  onClick: () => void;
  /** use with `autoHide`, callback when hiding is triggered */
  onHide?: () => void;
  /** if true, sets icon to visibility off and triggers `onHide` callback after 10000ms */
  autoHide: boolean;
  /** only applies if `autoHide` is true */
  autoHideInMs?: number;
  /** props applies to the inner <button /> */
  buttonProps?: Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "onClick">;
}) => {
  const iconTimeout = useRef<number>();
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    if (autoHide) {
      clearTimeout(iconTimeout.current);
      iconTimeout.current = window.setTimeout(() => {
        setVisible(false);
        onHide?.();
      }, autoHideInMs ?? 10000);
    }

    setVisible(true);

    onClick();
  };

  useEffect(
    () => () => {
      clearTimeout(iconTimeout.current);
    },
    [],
  );

  return (
    <button className={styles.button} title={visible ? "Hide" : "Show"} onClick={handleClick} {...buttonProps}>
      {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </button>
  );
};
