import { useState, useEffect } from "react";
import { binaries, OSFileExtensionsPairs } from "src/services/downloadURI";
import { dateToString } from "src/utils/date";
import { useFileExtension } from "./useFileExtension";
import { useOS } from "./useOS";

interface Response {
  version: string;
  files: Array<{ url: string; sha512: string; size: number }>;
  path: string;
  sha512: string;
  releaseDate: string;
}

export interface BinaryInfo {
  version: string;
  releaseDate: string;
  binaries: typeof binaries;
  recommended: string;
  btnText: string;
}

/**
 *
 * A custom hook to be used to create "Download Lens" button.
 *
 * @param binaryYAMLEndpoint - the endpoint to fetch the binary yaml, for beta version
 * https://api.k8slens.dev/binaries/beta.yaml, latest https://api.k8slens.dev/binaries/beta.yaml
 * @returns `fetching` - fetching the binaryInfo; `error` - the error when fetching the binaryInfo
 * `binaryInfo` an object with version, releaseDate, btnText to show on button, and download()
 * to be called when click the button
 *
 * @example
 * ```tsx
 * const url = "https://api.k8slens.dev/binaries/latest.yaml"
 * const Component = () => {
 *   const { isFetching, binaryInfo, error } = useBinaryInfo(url);
 *   return (
 *     <div>
 *       {isFetching && <span>Loading</span>}
 *       {error && <span>{error.message}</span>}
 *       {binaryInfo && <span>{binaryInfo.version}</span>}
 *       {binaryInfo && <button onClick={binaryInfo.download}>{binaryInfo.btnText}</button>}
 *     </div>
 *   )
 * }
 * ```
 */
export const useBinaryInfo = (binaryYAMLEndpoint: string) => {
  const [isFetching, setIsFetching] = useState<Boolean>(false);
  const [binaryInfo, setBinaryInfo] = useState<BinaryInfo>();
  const [error, setError] = useState<unknown>();
  const fileExtension = useFileExtension();
  const os = useOS();
  const osDisplayName = OSFileExtensionsPairs[os].displayName;

  useEffect(() => {
    async function fetchBinaryInfo() {
      try {
        setIsFetching(true);
        const res = await fetch(binaryYAMLEndpoint, { method: "GET" });
        const { parse } = await import(/* webpackChunkName: "yaml" */ "yaml");
        const { version, releaseDate } = parse(await res.text()) as unknown as Response;

        setBinaryInfo({
          version,
          releaseDate: dateToString(new Date(releaseDate)),
          binaries,
          recommended: osDisplayName,
          btnText: `Download for ${osDisplayName}`,
        });
      } catch (error) {
        setBinaryInfo(undefined);
        setError(error);
      } finally {
        setIsFetching(false);
      }
    }
    fetchBinaryInfo();

    return () => {
      setIsFetching(false);
      setError(undefined);
    };
  }, [fileExtension, binaryYAMLEndpoint, osDisplayName, os]);

  return {
    isFetching,
    binaryInfo,
    error,
  };
};
