import type { SubscriptionSeat } from "lens-platform-sdk";
import { lastestActivatedAtSeatSubscriptionSeat } from "./lastestActivatedAtSeat";

/**
 * Return the `SubscriptionSeat` that has lastest `createdAt` date
 */
export const getCurrentSubscriptionSeat = (subscriptionSeats: SubscriptionSeat[]) => {
  if (subscriptionSeats.length === 0) {
    return undefined;
  }

  return subscriptionSeats.reduce(lastestActivatedAtSeatSubscriptionSeat, subscriptionSeats[0]);
};
