import { useCallback, useState } from "react";
import { SortingState, Updater, OnChangeFn } from "@tanstack/react-table";
import { useAnalytics } from "src/hooks/useAnalytics";

export const useTrackedTableSort = ({ table, initialSort = [] }: { table: string; initialSort?: SortingState }) => {
  const { track } = useAnalytics();
  const [sorting, setSorting] = useState<SortingState>(initialSort);

  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue: Updater<SortingState> | SortingState) => {
      let state: SortingState;

      if (typeof updaterOrValue === "function") {
        state = updaterOrValue(sorting);
      } else {
        state = updaterOrValue;
      }
      setSorting(updaterOrValue);
      track("Table Sorting Updated", { state, table });
    },
    [sorting, track, table],
  );

  return {
    sorting,
    onSortingChange,
  };
};
