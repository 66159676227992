import { parse, stringifyUrl } from "query-string";

export const getDiscourseUrl = (sso: string, sig: string, location: { search: string }) => {
  const queryParams = parse(location.search);

  return stringifyUrl({
    url: queryParams.return_sso_url as string,
    query: {
      sso,
      sig,
    },
  });
};
