import { useCallback } from "react";

import { useAnalytics } from "src/hooks/useAnalytics";
import { useAuth } from "src/hooks/useAuth";

export const useParseInvitationError = () => {
  const { logout } = useAuth();
  const { trackButtonClicked } = useAnalytics();

  const handleLogout = useCallback(() => {
    trackButtonClicked("Sign In");
    logout();

    return false;
  }, [trackButtonClicked, logout]);

  return (error: string) => {
    if (error.includes("Please Sign In")) {
      const parts = error.split("Please Sign In");

      return (
        <>
          {parts[0]} Please{" "}
          <a href="/" role="button" onClick={handleLogout}>
            Sign In
          </a>
          {parts[1]}
        </>
      );
    }

    return error;
  };
};
