import { Route } from "react-router-dom";
import type { RouteProps } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import { LoadingIndicator } from "@k8slens/lds";
import { useCallback, useEffect } from "react";
import { hasAuthParams } from "src/services/hasAuthParams";
import { InvalidResponseError, LogInRequiredError, SignInRedirectError } from "src/services/authErrors";

const PrivateRoute = (props: RouteProps) => {
  const { authenticated, isNavigating, initialized, login, error } = useAuth();

  const loginWithParams = useCallback(() => {
    if (!hasAuthParams() && !authenticated && isNavigating !== true && initialized === true) {
      // href should have all pass through query params
      login(window.location.href);
    }
  }, [authenticated, initialized, isNavigating, login]);

  useEffect(() => {
    let retryLogin: NodeJS.Timeout;

    if (
      error instanceof InvalidResponseError ||
      error instanceof SignInRedirectError ||
      error instanceof LogInRequiredError
    ) {
      // If there is an error loading config from auth server, retry login every 3 seconds
      retryLogin = setTimeout(() => {
        loginWithParams();
      }, 3000);
    } else {
      loginWithParams();
    }

    return () => clearTimeout(retryLogin);
  }, [error, loginWithParams]);

  if (authenticated) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Route {...props} />
    );
  }

  return <LoadingIndicator size="2xl" />;
};

export default PrivateRoute;
