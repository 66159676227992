import { LoadingIndicator } from "@k8slens/lds";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { activateProTrialPath } from "src/constants";
import { useAuth } from "src/hooks/useAuth";

export function RedirectToRegistration() {
  const history = useHistory();
  const { signUp } = useAuth();
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    const signUpRedirectUri = `${window.location.origin}${activateProTrialPath}`;

    if (!redirected) {
      // redirect to the idp registration page
      signUp(history, signUpRedirectUri);
      setRedirected(true);
    }
  }, [history, signUp, redirected]);

  return <LoadingIndicator size="2xl" />;
}
