import React, { forwardRef } from "react";
import { Button } from "@k8slens/lds";
import { type ButtonProps, type ButtonPropsWithLabelNode } from "@k8slens/lds/lib/es/Button/Button.d";

import { useAnalytics } from "src/hooks/useAnalytics";
import { childrenToString } from "src/utils/childrenToString";

const TrackedButton = forwardRef<HTMLButtonElement, ButtonProps | ButtonPropsWithLabelNode>(
  ({ children, onClick, disabled, ...props }, ref) => {
    const { trackButtonClicked } = useAnalytics();

    return (
      <Button
        ref={ref}
        disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          if (disabled) {
            return undefined;
          }

          if (typeof onClick === "function") {
            let label: string;

            if (typeof props.label === "string") {
              label = props.label;
            } else if (props.label) {
              label = childrenToString(props.label);
            } else if (props["aria-label"]) {
              label = props["aria-label"];
            } else {
              label = "[Button]";
            }
            trackButtonClicked(label);

            return onClick(e);
          }

          return undefined;
        }}
      >
        {children}
      </Button>
    );
  },
);

export default TrackedButton;
