import { Business } from "lens-platform-sdk";

import { getAccountUrl, getBillingInfoUrl } from "src/services/billing/billingUrl";
import { useBillingPageToken, useBusinessBillingPageToken } from "./useBillingPageToken";

/**
 * Get billing urls for a user
 */
export const useBillingPageUrl = (username: string | undefined) => {
  const { error, token } = useBillingPageToken(username);
  const accountUrl = getAccountUrl(token);
  const billingInfoUrl = getBillingInfoUrl(token);

  return { error, accountUrl, billingInfoUrl };
};

/**
 * Get billing urls for a business
 */
export const useBusinessBillingPageUrl = (businessId?: Business["id"] | undefined) => {
  const { error, token } = useBusinessBillingPageToken(businessId);
  const billingInfoUrl = getBillingInfoUrl(token);
  const accountUrl = getAccountUrl(token);

  return { error, accountUrl, billingInfoUrl };
};
