import { forwardRef, ReactElement, ForwardedRef } from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";

import { useAnalytics } from "src/hooks/useAnalytics";
import { useTrackedLinkClickHandler } from "src/hooks/useTrackedLinkClickHandler";

const ComponentWithRef = <S extends object | unknown>(
  { to, href, onClick, children, ...props }: LinkProps<S>,
  ref: ForwardedRef<HTMLAnchorElement>,
) => {
  const location = useLocation<S>();
  const { trackLinkClicked } = useAnalytics();

  const handleClick = useTrackedLinkClickHandler({
    to,
    href,
    onClick,
    children,
    location,
    elementType: "Link",
    tracker: trackLinkClicked,
  });

  return (
    <Link
      ref={ref}
      to={to}
      href={href}
      onClick={handleClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Link>
  );
};

// eslint-disable-next-line xss/no-mixed-html
const TrackedLink = forwardRef(ComponentWithRef) as <S extends object>(
  p: LinkProps<S> & { ref?: ForwardedRef<HTMLAnchorElement> },
) => ReactElement;

export default TrackedLink;
