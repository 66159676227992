import type { PropsWithChildren } from "react";
import clsx from "clsx";

import styles from "./PageSection.module.css";

type Props = {
  id?: string;
  className?: string;
};

const PageSection = ({ id, className, children }: PropsWithChildren<Props>) => (
  <section id={id} className={clsx(styles.pageSection, className)}>
    {children}
  </section>
);

export default PageSection;
