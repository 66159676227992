import { useCallback, useMemo, useState } from "react";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  CellContext,
} from "@tanstack/react-table";
import { Button } from "@k8slens/lds";
import { base } from "@k8slens/lds-icons";
import { BusinessJoinRequestWithCreatedBy } from "lens-platform-sdk";

import { getUserAvatar } from "src/utils/user";
import { getUserFullname } from "src/services/getUserFullname";
import { useTrackedTableSort } from "src/hooks/useTrackedTableSort";

import Table from "src/components/Table/Table";
import ActionsCell, { actionsColDef } from "src/components/TableCells/ActionsCell";
import { dateColDef } from "src/components/TableCells/DateCell";
import { AvatarTableCell, PendingUserTableCell } from "src/components/TableCells/AvatarTableCell";

const { WarningIcon } = base;

const tableColumnHelper = createColumnHelper<BusinessJoinRequestWithCreatedBy>();

const caption = "Join Requests";

export interface RequestProps {
  requests?: Array<BusinessJoinRequestWithCreatedBy>;
  loading?: boolean;
  updateRequest: (d: { id: string; state: "accepted" | "rejected" | "canceled" }) => Promise<any>;
}

type ActionsProps = {
  request: BusinessJoinRequestWithCreatedBy;
  updateRequest: (d: { id: string; state: "accepted" | "rejected" | "canceled" }) => Promise<any>;
};

const Actions = ({ request, updateRequest: _updateRequest }: ActionsProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const updateRequest = useCallback(
    async (data: { id: string; state: "accepted" | "rejected" | "canceled" }) => {
      setLoading(true);
      setError(false);

      try {
        await _updateRequest(data);
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    },
    [_updateRequest, setLoading],
  );

  const rejectInvite = useCallback(
    () => updateRequest({ id: request.id, state: "rejected" }),
    [updateRequest, request],
  );
  const approveInvite = useCallback(
    () => updateRequest({ id: request.id, state: "accepted" }),
    [updateRequest, request],
  );

  return (
    <ActionsCell end>
      {error ? (
        <span title="Something went wrong! Please, try again later.">
          <WarningIcon size="lg" color="caution" />
        </span>
      ) : null}
      <Button
        size="xs"
        label="Reject"
        onClick={() => rejectInvite()}
        loadingStateOnPromise
        disabled={loading}
        primary
        discreet
      ></Button>
      <Button
        size="xs"
        label="Approve"
        onClick={() => approveInvite()}
        loadingStateOnPromise
        disabled={loading}
        primary
      ></Button>
    </ActionsCell>
  );
};

const Requests = ({ requests, updateRequest, loading }: RequestProps) => {
  const tableColumns = [
    tableColumnHelper.accessor("createdBy", {
      header: () => <span>Email</span>,
      cell: ({ getValue }) => {
        const user = getValue();

        const { username, email } = user;
        const userAvatar = getUserAvatar(user);

        // User is invited to the business, but not yet have a Lens ID account
        const userNotHaveAccount = email && !username;

        if (userNotHaveAccount) {
          return <PendingUserTableCell email={email} />;
        }

        const fullname = getUserFullname(user);

        return <AvatarTableCell image={userAvatar} name={username ?? ""} subtitle={fullname} />;
      },
      meta: { primary: true },
    }),
    tableColumnHelper.accessor("createdAt", {
      ...dateColDef,
      header: () => <span>Requested At</span>,
    }),
    tableColumnHelper.accessor("id", {
      ...actionsColDef,
      meta: { align: "end" },
      cell: useCallback(
        ({ row }: CellContext<BusinessJoinRequestWithCreatedBy, "string">) => (
          <Actions request={row.original} updateRequest={updateRequest} />
        ),
        [updateRequest],
      ),
    }),
  ];
  const { sorting, onSortingChange } = useTrackedTableSort({
    table: caption,
    initialSort: [{ id: "createdAt", desc: true }],
  });

  const pendingUserTable = useReactTable({
    data: requests || [],
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange,
  });
  const rowModel = pendingUserTable.getRowModel();
  const rows = useMemo(() => rowModel.rows, [rowModel]);

  return (
    <Table<BusinessJoinRequestWithCreatedBy>
      caption={caption}
      loading={loading}
      rows={rows}
      columns={pendingUserTable.getAllColumns()}
      headerGroups={pendingUserTable.getHeaderGroups()}
      noDataText="No pending requests on your Lens Business ID!"
      borderless={false}
    />
  );
};

export default Requests;
