import { useMemo } from "react";
import { useGetBusinessSubscriptions } from "./useGetBusinessSubscriptions";

export const useGetBusinessSubscription = (businessId: string, subscriptionId: string) => {
  const { businessSubscriptions, loading, reload } = useGetBusinessSubscriptions(businessId);

  return {
    subscription: useMemo(
      () => businessSubscriptions.find((subscription) => subscription.id === subscriptionId),
      [subscriptionId, businessSubscriptions],
    ),
    loading,
    reload,
  };
};
