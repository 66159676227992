import React, { PropsWithChildren, useCallback, useEffect, useRef } from "react";

import { FormField, common } from "@k8slens/lds-form";
import { type FormFieldComponentProps } from "@k8slens/lds-form/lib/es/FormField/FormField";
import { type Validator, type CustomValidityState } from "@k8slens/lds-form/lib/es/common/validation.d";

import Combobox, { ComboboxProps } from "src/components/Combobox/Combobox";

const { useFieldIds, useInputErrorProps, useFormComponentData, defaultErrorParser: ldsDefaultErrorParser } = common;

export const defaultErrorParser = ldsDefaultErrorParser;

type ValueType = ComboboxProps["value"];

type CustomInputProps = Omit<ComboboxProps, "onChange" | "name" | "value"> & {
  name: string;
};

interface Props<CustomError extends string> extends CustomInputProps, FormFieldComponentProps<ValueType, CustomError> {
  value: ComboboxProps["value"];
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
}

const handleValidation = <T extends ValueType, CustomError extends string>(
  value: T,
  // TODO: Fix types
  inputProps: CustomInputProps,
  validate?: Validator<any, CustomError>,
) => {
  // Valid unless explicitly invalidated
  const validity: CustomValidityState<CustomError> = { valid: true };

  if (inputProps.required && !value) {
    validity.valid = false;
    validity.valueMissing = true;
  }

  if (inputProps.minLength && (!value || value.label.length < inputProps.minLength)) {
    validity.valid = false;
    validity.tooShort = true;
  }

  if (inputProps.maxLength && (!value || value.label.length > inputProps.maxLength)) {
    validity.valid = false;
    validity.tooLong = true;
  }

  if (inputProps.pattern && (!value || !value.label.match(new RegExp(inputProps.pattern)))) {
    validity.valid = false;
    validity.patternMismatch = true;
  }

  if (validate) {
    // Get errors from custom validator function
    const result = validate(value, validity, inputProps.name, inputProps);

    if (typeof result === "string") {
      validity[result] = true;
      validity.valid = false;
    } else if (result) {
      result.forEach((key) => {
        validity[key] = true;
      });
      validity.valid = false;
    }
  }

  return validity;
};

/**
 * Input wrapped in Form Field
 *
 * Handles validation and errors with accessible label and error notifications.
 */
export function FormCombobox<CustomError extends string>({
  label,
  wrapperProps,
  labelProps,
  validate,
  errorParser = ldsDefaultErrorParser,
  errorProps: errorComponentProps,
  value,
  onChange,
  ...inputProps
}: PropsWithChildren<Props<CustomError>>) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const id = inputRef.current?.id;
  const { labelId, errorId } = useFieldIds();

  const { currentValue, setCurrentValue, errors } = useFormComponentData<ValueType, CustomError>({
    value,
    errorParser,
    constraints: inputProps,
    label,
  });
  const errorProps = useInputErrorProps({ id: errorId, errors });

  /**
   * Value changed
   */
  const handleChange = useCallback(
    (value: ValueType) => {
      const validity = handleValidation(value, inputProps, validate);

      setCurrentValue({
        value,
        validity: { ...validity },
      });
      // TODO: Fix types
      (onChange as any)(value, validity.valid);
    },
    [validate, onChange, setCurrentValue, inputProps],
  );

  /**
   * Validator changed
   */
  useEffect(() => {
    if (currentValue.validity) {
      // Revalidate when validator function changes and field is dirty (has validity object)
      const validity = handleValidation(currentValue.value, inputProps, validate);

      const wasValid = currentValue.validity?.valid;
      const isValid = validity.valid;

      if (wasValid !== isValid) {
        setCurrentValue((current) => ({
          ...current,
          validity,
        }));
        // TODO: Fix types
        (onChange as any)(currentValue.value, isValid);
      }
    }
  }, [validate, value, currentValue, setCurrentValue, onChange, inputProps]);

  return (
    <FormField
      id={id}
      label={label}
      labelId={labelId}
      errorId={errorId}
      errors={errors}
      required={inputProps.required}
      labelProps={labelProps}
      errorProps={errorComponentProps}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...wrapperProps}
    >
      <Combobox
        ref={inputRef}
        aria-labelledby={labelId}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...errorProps}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
        value={currentValue?.value}
        onChange={handleChange}
      />
    </FormField>
  );
}
