import { type LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { queryClient } from "src/queryClient";
import { useMutation } from "@tanstack/react-query";

export const useDeleteBusinessUser = (businessId?: string) => {
  const lensPlatformClient = useLensPlatformClient();

  const {
    isPending: loading,
    error,
    mutate: deleteBusinessUser,
  } = useMutation<
    void,
    LensSDKException,
    {
      businessUserId: string;
    }
  >({
    mutationFn: async ({ businessUserId }) => {
      if (!businessId) {
        throw new Error("Business ID is required");
      }

      if (!businessUserId) {
        throw new Error("Business User ID is required");
      }
      await lensPlatformClient.business.deleteBusinessUser(businessId, businessUserId);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["businessUsers", businessId] });
    },
  });

  return {
    deleteBusinessUser,
    error,
    loading,
  };
};
