import { Fragment, PropsWithChildren, ReactNode, useMemo } from "react";
import clsx from "clsx";
import { base } from "@k8slens/lds-icons";
import { ButtonBar, Notification, Panel } from "@k8slens/lds";
import { type NotificationProps } from "@k8slens/lds/lib/es/Notification/Notification";
import { type PanelProps } from "@k8slens/lds/lib/es/Panel/Panel";

import styles from "./PublicLayout.module.css";

type Size = "sm" | "md" | "lg" | "xl";

const { LogoIcon } = base;

type Props = {
  title?: string | ReactNode;
  footer?: ReactNode;
  wrapperClassName?: string;
  notification?: string | ReactNode;
  notificationProps?: Omit<NotificationProps, "message">;
  header?: ReactNode;
  pageFooter?: ReactNode;
  aside?: ReactNode;
  panelProps?: Omit<
    PanelProps<
      typeof Fragment,
      "header",
      typeof Fragment,
      typeof ButtonBar | keyof JSX.IntrinsicElements | React.ElementType
    >,
    "headerComponent" | "contentComponent" | "header" | "footer"
  >;
  size?: Size;
};

const PublicLayout = ({
  title,
  header,
  wrapperClassName,
  notification,
  notificationProps,
  footer,
  pageFooter,
  aside,
  panelProps: { className = "", footerComponent = ButtonBar, footerProps, contentProps, ...panelProps } = {
    contentProps: {},
  },
  size = "md",
  children,
}: PropsWithChildren<Props>) => (
  <div className={clsx(styles.layout, { [styles[size]]: size })}>
    <div className={clsx(styles.wrapper, wrapperClassName)}>
      <main>
        <Panel
          className={clsx(styles.panel, className || "", {
            [styles.withPageFooter]: pageFooter,
          })}
          header={
            <div
              className={clsx(styles.header, {
                [styles.withTitle]: title || header,
              })}
            >
              <LogoIcon color="accent" size={32} />
              <h1 className={styles.logoText}>Lens</h1>
              {title ? <h2 className="lds-h2">{title}</h2> : null}
              {header}
            </div>
          }
          footer={footer}
          footerComponent={footerComponent}
          footerProps={useMemo(
            () => ({
              as: "footer",
              ...footerProps,
            }),
            [footerProps],
          )}
          contentComponent={Fragment}
          {...panelProps}
        >
          <>{notification ? <Notification {...notificationProps} message={notification}></Notification> : null}</>
          <div {...contentProps} className={clsx("lds-panel--content", contentProps?.className)}>
            {children}
          </div>
        </Panel>
        {aside}
      </main>
      {pageFooter && <footer role="contentinfo">{pageFooter}</footer>}
    </div>
  </div>
);

export default PublicLayout;
