import { BusinessSubscription } from "lens-platform-sdk";

/**
 * Temporary place for checking if the account has enterprise features access.
 */
export const useIsEnterpriseFeaturesEnabled = (businessSubscriptions?: Array<BusinessSubscription>) => {
  if (!businessSubscriptions) {
    return {
      enterpriseFeaturesEnabled: undefined,
      loading: true,
    };
  }

  return {
    enterpriseFeaturesEnabled: businessSubscriptions.some(
      (subscription) =>
        (subscription.planCode === "enterprise-monthly" ||
          subscription.planCode === "enterprise-annual" ||
          subscription.planCode === "control-center-monthly" ||
          subscription.planCode === "control-center-annual") &&
        (subscription.state === "active" || subscription.state === "canceled"),
    ),
    loading: false,
  };
};
