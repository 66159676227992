export type FieldValue<T> = {
  value: T;
  valid?: boolean;
};

export type FieldValueMap<T extends object> = {
  [Key in keyof T]: FieldValue<T[Key]>;
};

export type FieldDefault<T, R> = FieldValue<R> & {
  transform?: (value: T) => R;
};

export type FieldDefaultMap<T extends object, R extends { [Key in keyof T]: any }> = {
  [Key in keyof T]: FieldDefault<T[Key], R[Key]>;
};

function getInitialDataForField<T extends object, Key extends keyof T, R extends { [Key in keyof T]: any }>(
  field: Key,
  initialData: T | undefined,
  { value, valid, transform }: FieldDefault<T[Key], R[Key]>,
): FieldValue<R> {
  if (initialData && field in initialData) {
    return {
      value: transform ? transform(initialData[field]) : (initialData[field] as unknown as R[Key]),
      valid: true,
    };
  }
  const d: FieldValue<R> = { value };

  if (typeof valid === "boolean") {
    d.valid = valid;
  }

  return d;
}

export function dataToFieldValues<Data extends Object, R extends { [Key in keyof Data]: any }>(
  validData: Data | undefined,
  defaults: FieldDefaultMap<Data, R>,
) {
  return Object.entries(defaults).reduce(
    (o, [key, value]) => ({
      ...o,
      [key]: getInitialDataForField(key as keyof Data, validData, value),
    }),
    {} as FieldValueMap<R>,
  );
}
