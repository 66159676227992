import { Modal } from "@k8slens/lds";
import Button from "../Button/TrackedButton";

export interface DownloadOfflineActivationCodeModalProps {
  isOpened: boolean;
  onCancel: () => void;
  onDownload: () => void;
}

export const DownloadOfflineActivationCodeModal = ({
  onDownload,
  isOpened,
  onCancel,
}: DownloadOfflineActivationCodeModalProps) => (
  <Modal
    title="Download Activation Code"
    size="md"
    onClose={onCancel}
    isOpen={isOpened}
    footer={
      <>
        <Button label="Cancel" id="cancel-download" onClick={onCancel} />
        <Button label="Download" id="Download" onClick={onDownload} primary />
      </>
    }
  >
    <p>
      The Activation Code is meant for using Lens Desktop in environments where online validation for Lens Subscriptions
      is not available. By downloading the Activation Code, the associated Lens Subscription will be fixed to you and no
      changes will be possible until the end of the subscription term.
    </p>
  </Modal>
);
