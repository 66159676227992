import { ComponentType } from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";

import styles from "./Tabs.module.css";

type ExtractProps<T> = T extends ComponentType<infer P> ? P : T;

export type TabListProps = ExtractProps<(typeof Tab)["List"]>;

export const TabList: React.FC<TabListProps> = ({ className, children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tab.List {...props} className={clsx(styles.tabList, className)}>
    {children}
  </Tab.List>
);
