import { useGetBusinessBillingInformation } from "./useGetBusinessBillingInfo";

export const useHasBillingInfo = (businessId: string) => {
  const { billingInfo, loading, error } = useGetBusinessBillingInformation(businessId);

  return {
    hasBillingInfo: Boolean(billingInfo),
    loading,
    error,
  };
};
