import React, { useCallback, useEffect, useRef, useMemo, Suspense } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import type { Business, UserWithEmail } from "lens-platform-sdk";
import { LoadingIndicator } from "@k8slens/lds";
import { MenuItem } from "@k8slens/lds/lib/es/UserMenu/UserMenuItem";
import { defaultItems } from "@k8slens/lds/lib/es/UserMenu/default-items";

import { useUsername } from "src/hooks/useUsername";
import { useAnalytics } from "src/hooks/useAnalytics";
import { getUserAvatar } from "src/utils/user";
import { PlanCode, getPlanData } from "src/services/plans";
import { getUserFullname } from "src/services/getUserFullname";
import { useGetLicense } from "src/hooks/useGetLicense";
import { useBusinessInfo } from "src/hooks/useBusinessInfo";
import { useGetSubscriptionSeats } from "src/hooks/useGetSubscriptionSeats";
import { features } from "src/utils/license";

import Header from "./Header";
import TopBar from "./TopBar";
import Sidebar from "./Sidebar";

import styles from "./Layout.module.css";
import { useAuth } from "src/hooks/useAuth";

const logoutButtonLabel = "Log Out";

const origin = window.location.origin;

export interface LayoutProps {
  className?: string;
  children: React.ReactNode;
  profile: UserWithEmail | null;
  businessId?: string;
  businessRequestCount?: number;
  business?: Business;
}

export default function Layout({
  children,
  profile,
  business,
  businessId,
  businessRequestCount,
  className,
}: LayoutProps) {
  const history = useHistory();
  const { logout } = useAuth();
  const username = useUsername();
  const { isBusinessID, showBillingDetails, reloadBusinesses, businesses, loadingBusinesses } = useBusinessInfo(
    businessId,
    username,
  );
  const { trackButtonClicked } = useAnalytics();
  const license = useGetLicense();

  /**
   * Plan and license info
   */
  const { currentSubscriptionSeat } = useGetSubscriptionSeats();

  const currentPlan = useMemo(() => {
    if (currentSubscriptionSeat?.planCode) {
      return getPlanData(currentSubscriptionSeat.planCode as PlanCode);
    }
  }, [currentSubscriptionSeat]);

  const isSupportEnabled = license?.features?.includes?.(features.support);

  const hasBusinesses = useMemo(() => {
    if (loadingBusinesses) {
      return undefined;
    }

    return businesses.length > 0;
  }, [businesses, loadingBusinesses]);

  /**
   * Handle sidebar open/close
   */
  const toggleButtonRef = useRef<null | HTMLDivElement>(null);

  /**
   * Logout
   */
  const handleLogout = useCallback(() => {
    trackButtonClicked(logoutButtonLabel);
    logout();
  }, [trackButtonClicked, logout]);

  // Update all businesses if current business has changed
  useEffect(() => {
    if (business) {
      reloadBusinesses();
    }
  }, [business, reloadBusinesses]);

  const currentUserName = useMemo(() => {
    const usernameStr = `@${profile?.username}`;
    const currentBusiness = businesses.find(({ id }) => id === businessId);

    if (currentBusiness?.id) {
      if (currentBusiness) {
        return (
          <>
            <span className={styles.lbidUserName}>{usernameStr} / </span>
            <span>{currentBusiness.name}</span>
          </>
        );
      }

      return "";
    }

    return usernameStr;
  }, [profile, businessId, businesses]);

  const user = useMemo(() => {
    if (!profile) {
      return;
    }
    const avatar = getUserAvatar(profile);

    const name = getUserFullname(profile) || "";

    return { name, username: profile.username || "", avatar };
  }, [profile]);

  const productName = isBusinessID ? "Lens Business ID" : "Lens ID";

  const userMenuItems = useMemo(() => {
    return defaultItems.map((group) => {
      return {
        ...group,
        children: group.children.reduce((arr, item) => {
          // Replace internal links with react-router-dom routes
          if (!item.external) {
            item.onClick = (e) => {
              if (item.href) {
                e.preventDefault();
                history.push(item.href.replace(window.location.origin, ""));

                return false;
              }
            };
          }

          if (item.key === "logout") {
            return arr.concat({
              ...item,
              onClick: handleLogout,
            });
          } else if (item.key === "billing" && !showBillingDetails) {
            return arr.concat({ ...item, disabled: true });
          } else if (item.key === "support" && !isSupportEnabled) {
            return arr.concat({ ...item, disabled: true });
          } else if (item.key === "business-id" && hasBusinesses === false) {
            return arr.concat({ ...item, disabled: true });
          }

          return arr.concat(item);
        }, [] as Array<MenuItem>),
      };
    });
  }, [handleLogout, showBillingDetails, isSupportEnabled, history, hasBusinesses]);

  if (!profile || !user) {
    return <LoadingIndicator size="xxl" />;
  }

  return (
    <>
      <div className={clsx(styles.layout, className)}>
        <TopBar className={styles.topbar} title={productName} username={currentUserName} burgerRef={toggleButtonRef} />
        <Header
          className={styles.header}
          productName={productName}
          handleLogout={handleLogout}
          origin={origin}
          user={user}
          userMenuItems={userMenuItems}
          licenseText={currentPlan?.licenseName}
        />

        <div className={clsx(styles.threeColumns, styles.layout)}>
          <Sidebar
            toggleButtonRef={toggleButtonRef}
            businessId={businessId}
            handleLogout={handleLogout}
            isSupportEnabled={isSupportEnabled}
            userMenuItems={userMenuItems}
            user={user}
            businessRequestCount={businessRequestCount}
            licenseText={currentPlan?.licenseName}
          />
          <main className={styles.main}>
            <Suspense fallback={<div>Loading</div>}>{children}</Suspense>
          </main>
        </div>
      </div>
    </>
  );
}
