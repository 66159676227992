import { useMemo } from "react";
import clsx from "clsx";
import initialism from "initialism";
import randomColor from "randomcolor";
import styles from "./Avatar.module.css";
import type { Icon } from "@k8slens/lds-icons/lib/cjs/Icon/Icon";

type Props = {
  name: string;
  className?: string;
  image?: string | Icon | null;
  size?: "sm" | "md";
};

const Avatar = ({ name, className, image: AvatarImage, size }: Props) => {
  const acronym = useMemo(() => initialism(name, 2), [name]);
  const avatarStyle = useMemo(
    () => ({
      backgroundColor: AvatarImage
        ? "var(--alias-color-background-base)"
        : randomColor({
            // "seed" used to persist color for same value
            seed: name,
            luminosity: "dark",
          }),
    }),
    [name, AvatarImage],
  );
  const avatarSize = size ? styles[size] : "";

  return (
    <div className={clsx(styles.avatar, className, avatarSize)} style={avatarStyle} aria-hidden>
      {AvatarImage && typeof AvatarImage === "string" && <img src={AvatarImage} alt={`${name} avatar`} />}
      {AvatarImage && typeof AvatarImage === "function" && <AvatarImage size="xxl" color="on-dark" />}
      {!AvatarImage && <span className={styles.avatarText}>{acronym}</span>}
    </div>
  );
};

export default Avatar;
