import { LoadingIndicator } from "@k8slens/lds";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { type NotificationProps } from "@k8slens/lds/lib/es/Notification/Notification";

import sadRobot from "src/img/sad-robot.png";

import { planMetadata } from "src/services/plans";
import { useActivateProTrial } from "src/hooks/useActivateProTrial";
import { useAuth } from "src/hooks/useAuth";
import { getTrialState } from "src/utils/license";

import PublicLayout from "src/components/PublicLayout/PublicLayout";
import LinkButton from "src/components/Button/TrackedRouterButton";

import { ThankYouFooter } from "../ThankYouFooter";
import { ThankYou } from "../ThankYou";
import { ProTrialUsed } from "../ProTrialUsed";

const panelProps = {
  footerProps: {
    type: "centered",
  },
};
const notificationProps: Partial<NotificationProps> = { level: "error" };

export const ActivateProTrial = () => {
  const { activateProTrial, error, isPending, isSuccess } = useActivateProTrial();
  const { authenticated, tokenParsed } = useAuth();
  const trialState = getTrialState({
    license: { type: tokenParsed?.license_type, trial: Boolean(tokenParsed?.license_trial) },
    error,
  });

  useEffect(() => {
    if (authenticated && trialState === "available") {
      activateProTrial();
    }
  }, [activateProTrial, authenticated, trialState]);

  if (trialState === "used") {
    return <ProTrialUsed />;
  }

  if (isSuccess) {
    return <ThankYou />;
  }

  if (isPending || trialState === "available") {
    return <LoadingIndicator size="2xl" />;
  }

  if (error || trialState === "error") {
    return (
      <PublicLayout
        title={`Activate ${planMetadata.proTrial.licenseName}`}
        pageFooter={<ThankYouFooter />}
        notification={error?.message ?? "Something went wrong"}
        notificationProps={notificationProps}
        panelProps={panelProps}
        footer={<LinkButton to="/home" label="Return to home page" primary />}
      >
        <img src={sadRobot} alt="Something went wrong..." />
      </PublicLayout>
    );
  }

  // trialState === "locked" or other unknown state, redirect to home
  return <Redirect to={"/"} />;
};
