import { BusinessSubscription } from "lens-platform-sdk";

/**
 * Temporary place for checking if the account has enterprise features access.
 */
export const useIsControlCenterEnabled = (businessSubscriptions?: Array<BusinessSubscription>) => {
  if (!businessSubscriptions) {
    return {
      controlCenterEnabled: undefined,
      loading: true,
    };
  }

  return {
    controlCenterEnabled: businessSubscriptions.some(
      (subscription) =>
        subscription.planCode === "control-center-monthly" || subscription.planCode === "control-center-annual",
    ),
    loading: false,
  };
};
