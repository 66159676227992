import { useState, useMemo, useEffect, useCallback } from "react";
import type { BusinessInvitation, BusinessSubscription, LensSDKException } from "lens-platform-sdk";
import { Button, Modal, Notification } from "@k8slens/lds";

import { useAnalytics } from "src/hooks/useAnalytics";
import { getPlanName } from "src/services/plans";

import Select from "src/components/Select/Select";
import { SubscriptionSelectContent } from "src/app/business/[businessId]/users/SubscriptionSelectContent";

import styles from "src/components/Business/AssignSubscriptionModal.module.css";
import { isAssignableSubscription } from "src/services/getSubscriptionState";

interface Props {
  open: boolean;
  assigningSubscription: boolean;
  assignSubscriptionError: LensSDKException | null;
  disabled: boolean;
  onModalClose: () => void;
  loadingBusinessSubscriptions: boolean;
  loadingBusinessInvitations: boolean;
  businessSubscriptions: BusinessSubscription[];
  businessInvitations: BusinessInvitation[];
  onClickAssign: (selectedBusinessSubscription: BusinessSubscription) => void;
}

const cancelButtonLabel = "Cancel";
const title = "Assign Seats";
const selectSubscriptionLabel = "Choose Lens Subscription";

/**
 * The shape of the option data in the business subscription selector
 */
interface SubscriptionOption {
  id: BusinessSubscription["id"];
  label: string;
}

/**
 * Get the shape of the option data to be used in the business subscription selector
 */
const getSubscriptionOption = (businessSubscription: BusinessSubscription): SubscriptionOption | undefined => {
  if (!businessSubscription) {
    return undefined;
  }

  return {
    id: businessSubscription.id,
    label: getPlanName(businessSubscription.planCode),
  };
};

const assignButtonLabel = "Assign";
const addNewSubscriptionLabel = "Add new Subscription";

export const AssignSubscriptionModal = ({
  open,
  assigningSubscription,
  assignSubscriptionError,
  disabled,
  onModalClose,
  loadingBusinessSubscriptions,
  loadingBusinessInvitations,
  businessSubscriptions,
  onClickAssign,
}: Props) => {
  const { track, trackButtonClicked } = useAnalytics();

  const assignableSubscriptions = useMemo(
    () => businessSubscriptions.filter(isAssignableSubscription),
    [businessSubscriptions],
  );
  const defaultOption = useMemo(() => getSubscriptionOption(assignableSubscriptions[0]), [assignableSubscriptions]);
  const [selected, setSelected] = useState<SubscriptionOption>();
  const selectedBusinessSubscription = businessSubscriptions.find(({ id }) => id === selected?.id);

  const options = useMemo<SubscriptionOption[]>(
    () =>
      assignableSubscriptions
        .map(getSubscriptionOption)
        // filter (unlikely) undefined returned from getOption
        .filter((option): option is SubscriptionOption => typeof option?.id === "string")
        // sort by label alphabetically
        .sort((a, b) => a.label.localeCompare(b.label)),
    [assignableSubscriptions],
  );

  const chooseSubscription = useCallback(
    (option: SubscriptionOption | undefined) => {
      if (!option) {
        return;
      }
      track("Subscription Selected", { subscriptionId: option.id });
      setSelected(option);
    },
    [track, setSelected],
  );

  /**
   * Reset state
   */
  const reset = useCallback(() => {
    chooseSubscription(defaultOption);
  }, [defaultOption, chooseSubscription]);

  // set the default selected business subscription
  useEffect(() => {
    chooseSubscription(defaultOption);
  }, [defaultOption, chooseSubscription]);

  const handleSubmit = () => {
    if (selectedBusinessSubscription) {
      trackButtonClicked(assignButtonLabel);
      onClickAssign(selectedBusinessSubscription);
      reset();
    }
  };

  return (
    <Modal
      title={title}
      size="md"
      contentProps={{
        className: styles.assignBusinessSubscriptionModalContent,
      }}
      className={styles.assignBusinessSubscriptionModalPanel}
      onClose={() => {
        if (!assigningSubscription) {
          onModalClose();
          reset();
        }
      }}
      isOpen={open}
      footer={
        <div className={styles.footerButtonbar}>
          <button
            aria-label={cancelButtonLabel}
            type="button"
            onClick={() => {
              if (!assigningSubscription) {
                trackButtonClicked(cancelButtonLabel);
                onModalClose();
                reset();
              }
            }}
            className="lds-button"
          >
            {cancelButtonLabel}
          </button>
          <Button
            loading={assigningSubscription || loadingBusinessSubscriptions || loadingBusinessInvitations}
            label={assignButtonLabel}
            id="assignSubscriptionButton"
            buttonType="submit"
            primary
            onClick={handleSubmit}
            disabled={disabled || !selectedBusinessSubscription}
          />
        </div>
      }
    >
      <form aria-label={title} autoComplete="off">
        {assignSubscriptionError ? (
          <Notification
            level="error"
            message={assignSubscriptionError?.message ?? "unknown"}
            type="flash"
            className={styles.errorNotification}
          />
        ) : null}
        <fieldset>
          <div className={styles.chooseLensSubscriptionLabel} aria-hidden>
            <span>{selectSubscriptionLabel}</span>
          </div>
          <Select
            aria-label={selectSubscriptionLabel}
            className={styles.selectBusinessSubscription}
            optionsClassName={styles.selectBusinessSubscriptionOptions}
            optionClassName={(state) => (state?.active === true ? styles.selectBusinessSubscriptionActive : "")}
            loading={loadingBusinessSubscriptions}
            value={selected}
            onChange={(value) => {
              chooseSubscription(value);
            }}
            options={options}
            renderContent={(value) =>
              value ? <SubscriptionSelectContent id={value.id} businessSubscriptions={businessSubscriptions} /> : ""
            }
          />
        </fieldset>
      </form>
    </Modal>
  );
};
