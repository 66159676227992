import type { LensSDKException, UserWithEmail } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

export const useFetchProfile = () => {
  const lensPlatformClient = useLensPlatformClient();

  const {
    isLoading,
    error,
    data: profile,
    refetch,
  } = useQuery<UserWithEmail, LensSDKException>({
    queryKey: ["user"],
    queryFn: async () => {
      const username = await lensPlatformClient.user.getUsername();
      const profile = await lensPlatformClient.user.getOne(
        {
          username,
        },
        "join=userAttributes",
      );

      return profile;
    },
    initialData: {
      id: "",
      username: "",
      fullname: "",
      firstName: "",
      lastName: "",
      userAttributes: [],
      email: "",
    },
  });

  const updateProfile = useCallback(() => {
    return refetch();
  }, [refetch]);

  return {
    profile,
    isProfileLoading: isLoading,
    profileError: error,
    updateProfile,
  };
};
