import { useContext } from "react";
import { ProfileContext } from "src/providers/profile-provider";
import { useBillingPageToken } from "src/hooks/useBillingPageToken";
import { useGetInvoices } from "src/hooks/useGetInvoices";
import { useGetSubscriptions } from "src/hooks/useGetSubscriptions";
import { useGetBillingInformation } from "src/hooks/useGetBillingInfo";
import { useBillingPageUrl } from "src/hooks/useBillingPageUrl";
import { Billing } from "./Billing";
import { Invoices } from "./Invoices";
import { BillingSubscriptions } from "./BillingSubscriptions";
import PageHeader from "src/components/PageHeader/PageHeader";
import PageSection from "src/components/PageSection/PageSection";

export const UserBillingDetails = () => {
  const { profile } = useContext(ProfileContext);
  const { invoices, loading } = useGetInvoices(profile?.username);
  const { subscriptions, loading: loadingSubscriptions } = useGetSubscriptions(profile?.username);
  const { billingInfoUrl } = useBillingPageUrl(profile?.username);
  const { billingInfo } = useGetBillingInformation();
  const { token } = useBillingPageToken(profile?.username);

  return (
    <>
      <PageHeader title="Billing details" subtitle="Manage your billing, subscriptions and payment methods here." />
      <PageSection>
        <PageHeader type="sub" title="Payment method" subtitle="Manage payment method for your Lens Subscriptions." />
        <Billing billingInfo={billingInfo} billingInfoUrl={billingInfoUrl} />
      </PageSection>
      <PageSection>
        <PageHeader type="sub" title="Subscriptions" subtitle="Manage your Lens subscriptions." />
        <BillingSubscriptions subscriptions={subscriptions} billingPageToken={token} loading={loadingSubscriptions} />
      </PageSection>
      <PageSection>
        <PageHeader
          type="sub"
          title="Billing history"
          subtitle="Review you billing history and manage your invoices."
        />
        <Invoices billingPageToken={token} invoices={invoices} loading={loading} />
      </PageSection>
    </>
  );
};
