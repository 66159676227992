import { FormEvent, useCallback, useContext, useEffect, useState } from "react";
import { Modal, Notification } from "@k8slens/lds";
import { FormInput, FormField, common } from "@k8slens/lds-form";

import { useAnalytics } from "src/hooks/useAnalytics";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { passwordValidator } from "src/services/validators";
import { ProfileContext } from "src/providers/profile-provider";
import { useAuth } from "src/hooks/useAuth";

import Layout from "src/components/Layout/Layout";
import Button from "src/components/Button/TrackedButton";
import PageHeader from "src/components/PageHeader/PageHeader";
import PageSection from "src/components/PageSection/PageSection";

import styles from "./page.module.css";

const { defaultErrorParser } = common;

const changeUsernameLabel = "Change Username";
const changePasswordLabel = "Change Password";
const removeAccountLabel = "Remove Account";
const passwordLabel = "Password";

export const Account = () => {
  const { track, trackError } = useAnalytics();
  const { profile, updateProfile } = useContext(ProfileContext);
  const { logout } = useAuth();
  const lensPlatformClient = useLensPlatformClient();
  const [usernameError, setUsernameError] = useState<null | string>(null);
  const [passwordError, setPasswordError] = useState<null | string>(null);
  const [removeAccountError, setRemoveAccountError] = useState<null | string>(null);
  const [profileSuccessMessage, setProfileSuccessMessage] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const flashDuration = 3000;
  const [isUsernameModalOpen, setIsUsernameModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isRemoveAccountModalOpen, setIsRemoveAccountModalOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [passwords, setPasswords] = useState({
    password: { value: "", valid: false },
    confirmPassword: { value: "", valid: false },
  });

  useEffect(() => {
    if (profile?.username) {
      setUsername(profile.username);
    }
  }, [profile]);

  const validatePassword = useCallback((value: string) => {
    if (value?.length > 0 && !passwordValidator.validate(value as string)) {
      return "invalidPassword";
    }

    return undefined;
  }, []);

  const validateConfirmPassword = useCallback(
    (value: string) => {
      if (value.length > 0 && passwords.password.value.length > 0 && value !== passwords.password.value) {
        return "passwordsDoNotMatch";
      }

      return undefined;
    },
    [passwords.password.value],
  );

  const parsePasswordError = useCallback((value: string, error: string) => {
    if (error === "invalidPassword") {
      return passwordValidator.message();
    }

    if (error === "passwordsDoNotMatch") {
      return "Passwords don't match";
    }

    return defaultErrorParser(value, error, {}, passwordLabel);
  }, []);

  const validatePasswords = () => {
    const valid = !Object.values(passwords).some(({ valid }) => valid !== true);

    return valid;
  };

  const handleUsernameUpdate = (name: string, value: string) => {
    setUsername(value);
  };

  const handlePasswordUpdate = (name: string, value: string, valid: boolean) => {
    setPasswords((userAttributes) => ({ ...userAttributes, ...{ [name]: { value, valid } } }));
  };

  const openEditUsernameModal = () => {
    setIsUsernameModalOpen(true);
  };

  const openChangePasswordModal = () => {
    setIsPasswordModalOpen(true);
  };

  const openRemoveAccountModal = () => {
    setIsRemoveAccountModalOpen(true);
  };

  const handleCloseUsernameModal = () => {
    setIsUsernameModalOpen(false);
    setUsernameError(null);
  };

  const handleClosePasswordModal = () => {
    setIsPasswordModalOpen(false);
    setPasswordError(null);
  };

  const handleCloseRemoveAccountModal = () => {
    setIsRemoveAccountModalOpen(false);
    setRemoveAccountError(null);
  };

  const updateUsername = async (e: FormEvent) => {
    e.preventDefault();

    if (profile?.username && username) {
      setUsernameError(null);

      try {
        setIsLoading(true);

        await lensPlatformClient.user.updateOne(profile.username, { username });

        setProfileSuccessMessage("Username changed");

        updateProfile();

        track("Username updated");

        handleCloseUsernameModal();
        // remove success test
        setTimeout(() => {
          setProfileSuccessMessage(null);
        }, flashDuration);
      } catch (error) {
        trackError("Username update failed");
        setUsernameError(error instanceof Error ? error.message : "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const updatePassword = async (e: FormEvent) => {
    e.preventDefault();

    if (profile?.username) {
      setPasswordError(null);

      try {
        setIsLoading(true);

        await lensPlatformClient.user.resetPassword(profile.username, passwords.password.value);

        setProfileSuccessMessage("Password changed");

        track("Password updated");

        handleClosePasswordModal();
        // remove success test
        setTimeout(() => {
          setProfileSuccessMessage(null);
        }, flashDuration);
      } catch (error) {
        trackError("Password update failed");

        setPasswordError(error instanceof Error ? error.message : "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const removeAccount = async () => {
    if (profile?.username) {
      setRemoveAccountError(null);

      try {
        setIsLoading(true);

        await lensPlatformClient.user.deleteOne(profile.username);

        track("Account removed");

        setProfileSuccessMessage("Account removed");

        setTimeout(async () => {
          await logout();
        }, flashDuration);
      } catch (error) {
        trackError("Account removal failed");
        setRemoveAccountError(error instanceof Error ? error.message : "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Layout className="bg-secondary" profile={profile}>
      {profile && (
        <>
          {profileSuccessMessage && (
            <Notification
              flashDuration={flashDuration}
              level="success"
              message={profileSuccessMessage}
              type="flash"
              className="mb-7"
            />
          )}
          <PageHeader title="Account" subtitle="You can change your personal Lens ID account details in here." />
          <PageSection>
            <FormField label="Username" labelId="username" id="username" errorId="username">
              <Button
                className={styles.action}
                loading={isLoading}
                label={changeUsernameLabel}
                id="changeUsername"
                onClick={openEditUsernameModal}
                size="sm"
                primary
              />
            </FormField>
            <FormField label="Password" labelId="password" id="password" errorId="password">
              <Button
                className={styles.action}
                loading={isLoading}
                label={changePasswordLabel}
                id="changePassword"
                onClick={openChangePasswordModal}
                size="sm"
                primary
              />
            </FormField>
            <div>
              <FormField label="Account removal" labelId="remove-account" id="remove-account" errorId="remove-account">
                <p className={styles.description}>
                  Once removed, it will be gone forever. There’s no way to recover removed account.
                </p>
                <Button
                  className={styles.action}
                  loading={isLoading}
                  label={removeAccountLabel}
                  id="removeAccount"
                  onClick={openRemoveAccountModal}
                  primary
                  size="sm"
                  buttonType="reset"
                />
              </FormField>
            </div>
          </PageSection>
        </>
      )}
      <Modal
        title="Edit username"
        size="md"
        onClose={handleCloseUsernameModal}
        isOpen={isUsernameModalOpen}
        className={styles.modalWithForm}
        contentComponent="form"
        contentProps={{
          onSubmit: updateUsername,
        }}
        footer={
          <>
            <Button loading={isLoading} label="Cancel" id="cancel-change-account" onClick={handleCloseUsernameModal} />
            <Button loading={isLoading} label="Change" id="change-account" buttonType="submit" primary />
          </>
        }
      >
        {usernameError && (
          <Notification flashDuration={flashDuration} level="error" message={usernameError} type="flash" />
        )}
        <FormInput
          value={username}
          label="Username"
          id="usernameInput"
          name="username"
          onChange={(value) => handleUsernameUpdate("username", value)}
        />
      </Modal>

      <Modal
        title="Change Password"
        size="md"
        className={styles.modalWithForm}
        onClose={handleClosePasswordModal}
        contentComponent="form"
        contentProps={{
          onSubmit: updatePassword,
        }}
        footer={
          <>
            <Button loading={isLoading} label="Cancel" onClick={handleClosePasswordModal} />
            <Button loading={isLoading} label="Change" buttonType="submit" primary disabled={!validatePasswords()} />
          </>
        }
        isOpen={isPasswordModalOpen}
      >
        {passwordError && (
          <Notification
            className="mb-3"
            flashDuration={flashDuration}
            level="error"
            message={passwordError}
            type="flash"
          />
        )}
        <FormInput
          type="password"
          name="password"
          autoComplete="new-password"
          label={passwordLabel}
          value={passwords.password.value}
          id="passwordInput"
          onChange={(value, valid) => handlePasswordUpdate("password", value, valid)}
          validate={validatePassword}
          errorParser={parsePasswordError}
        />
        <FormInput
          type="password"
          name="password-confirm"
          autoComplete="new-password"
          label="Confirm password"
          value={passwords.confirmPassword.value}
          id="confirmPasswordInput"
          validate={validateConfirmPassword}
          errorParser={parsePasswordError}
          onChange={(value, valid) => handlePasswordUpdate("confirmPassword", value, valid)}
        />
      </Modal>

      <Modal
        title="Remove account"
        size="md"
        onClose={handleCloseRemoveAccountModal}
        isOpen={isRemoveAccountModalOpen}
        footer={
          <>
            <Button
              loading={isLoading}
              label="Cancel"
              id="cancel-remove-account"
              onClick={handleCloseRemoveAccountModal}
            />
            <Button
              loading={isLoading}
              label="Remove"
              id="remove-account"
              onClick={removeAccount}
              primary
              buttonType="reset"
            />
          </>
        }
      >
        {removeAccountError && (
          <Notification flashDuration={flashDuration} level="error" message={removeAccountError} type="flash" />
        )}
        Are you sure you want to remove your account? Once removed, it cannot be recovered.
      </Modal>
    </Layout>
  );
};
