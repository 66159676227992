import { type SubscriptionSeat, type UserWithEmail } from "lens-platform-sdk";
import { LicenseType, type SubscriptionSeatWithBillingPeriod } from "../components/pages/Home/license";

export type TrialState = "used" | "available" | "locked" | "in-use" | "error";

export const getBillingPeriod = (subscriptionSeat: SubscriptionSeat) => {
  const billingPeriod =
    subscriptionSeat.currentPeriodEndsAt && subscriptionSeat.currentPeriodStartedAt
      ? (new Date(subscriptionSeat.currentPeriodEndsAt).valueOf() -
          new Date(subscriptionSeat.currentPeriodStartedAt).valueOf()) /
        (1000 * 60 * 60 * 24)
      : null;

  switch (billingPeriod) {
    case 30:
    case 31:
    case 29:
    case 28:
      return "Monthly";
    case 365:
    case 366:
      return "Annual";
    default:
      return null;
  }
};

export const getSubscriptionSeatWithBillingPeriod = (
  subscriptionSeat: SubscriptionSeat,
): SubscriptionSeatWithBillingPeriod => ({
  ...subscriptionSeat,
  billingPeriod: subscriptionSeat ? getBillingPeriod(subscriptionSeat) : null,
});

export const getProfileSubscriptionId = (profile: UserWithEmail) =>
  profile?.userAttributes?.find((attr) => attr.name === "subscriptionId")?.value;

export const getTrialState = ({
  license,
  error,
}: {
  license: { type?: string; trial?: boolean };
  error: Error | null;
}): TrialState => {
  if (license?.trial) {
    return "in-use";
  }

  if (license?.type === LicenseType.pro) {
    return "locked";
  }

  if (error?.message?.toLowerCase().includes("trial")) {
    return "used";
  }

  if (error) {
    return "error";
  }

  return "available";
};

export const features = {
  support: "Support",
};
