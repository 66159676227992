import { useBusinessSCIMToken } from "./useBusinessSCIMToken";

export const useIsSCIMActive = (businessId: string) => {
  const { scimTokens, loading, error } = useBusinessSCIMToken(businessId);

  return {
    isSCIMActive: scimTokens.length > 0,
    loading,
    error,
  };
};
