import { Redirect } from "react-router-dom";

import { useGetBusinesses } from "src/hooks/useGetBusinesses";

const RedirectToFirstBusiness = () => {
  const { businesses, loading } = useGetBusinesses();

  if (loading) {
    return null;
  }

  if (businesses[0]?.id) {
    return <Redirect to={`/business/${businesses[0].id}`} />;
  }

  return <Redirect to={"/home"} />;
};

export default RedirectToFirstBusiness;
