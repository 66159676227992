import { Context, EventProperties } from "@segment/analytics-next";
import { useCallback, useMemo } from "react";
import { LinkProps } from "react-router-dom";

import { childrenToString } from "src/utils/childrenToString";

type LocationObject<S = unknown> = {
  pathname: string;
  search: string;
  state: S;
  hash: string;
  key?: string | undefined;
};

export const useTrackedLinkClickHandler = <S extends object | unknown>({
  disabled,
  children,
  href,
  to,
  location,
  onClick,
  elementType,
  label,
  tracker,
}: {
  location: LocationObject<S>;
  elementType: string;
  label?: string;
  disabled?: boolean;
  tracker: (title: string, payload?: EventProperties) => Promise<Context | void> | undefined;
} & Pick<LinkProps<S>, "children" | "href" | "to" | "onClick">) => {
  const title = useMemo(() => {
    if (label) {
      return label;
    }

    if (children) {
      return childrenToString(children);
    }

    return `[${elementType}]`;
  }, [children, elementType, label]);

  return useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (disabled) {
        return undefined;
      }

      let payload: {
        elementType: typeof elementType;
      } & (Partial<LocationObject<S>> | { href: string }) = { elementType };

      const toLocation = typeof to === "function" ? to(location) : to;

      if (typeof toLocation === "string") {
        payload.pathname = toLocation;
      } else if (toLocation) {
        payload = { ...payload, ...toLocation };
      } else if (href) {
        payload = { ...payload, href };
      }
      tracker(title, payload);

      if (typeof onClick === "function") {
        return onClick(e);
      }

      return undefined;
    },
    [tracker, onClick, to, href, location, elementType, disabled, title],
  );
};
