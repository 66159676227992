import { useCallback, useMemo, useState, type ReactNode } from "react";
import clsx from "clsx";
import { Button, Modal } from "@k8slens/lds";

import styles from "./ConfirmDialog.module.css";

type ConfirmDialogProps = {
  title: string;
  message?: string | ReactNode;
  confirmText?: string;
  cancelLabel?: string;
  handleClose: () => void;
  handleConfirm: () => void;
  handleCancel: () => void;
};

const ConfirmDialog = ({
  title,
  message,
  confirmText = "Ok",
  cancelLabel = "Cancel",
  handleClose,
  handleConfirm,
  handleCancel,
}: ConfirmDialogProps) => {
  return (
    <Modal
      className={clsx(styles.confirmDialog, {
        [styles.confirmDialogWithoutMessage]: !Boolean(message),
      })}
      isOpen={true}
      size="md"
      title={title}
      onClose={() => handleClose()}
      footer={
        <>
          <Button label={cancelLabel} loadingStateOnPromise onClick={handleCancel} />
          <Button label={confirmText} primary loadingStateOnPromise onClick={handleConfirm} />
        </>
      }
    >
      {message}
    </Modal>
  );
};

type ConfirmDialogConfig = Omit<
  ConfirmDialogProps,
  "title" | "message" | "handleClose" | "handleConfirm" | "handleCancel"
>;

export const useConfirmDialog = () => {
  const [promise, setPromise] = useState<null | { resolve(value: unknown): void }>(null);
  const [title, setTitle] = useState("");
  const [props, setProps] = useState<ConfirmDialogConfig>({});
  const [message, setMessage] = useState<string | ReactNode>("");
  const [visible, setVisible] = useState(false);

  const confirm = useCallback(
    (title: string, message?: string | ReactNode, props: ConfirmDialogConfig = {}) => {
      setVisible(true);
      setTitle(title);
      setMessage(message || "");
      setProps(props);

      return new Promise((resolve) => {
        setPromise({ resolve });
      });
    },
    [setPromise, setVisible],
  );

  const handleClose = useCallback(() => {
    setPromise(null);
    setVisible(false);
    setTitle("");
    setMessage("");
    setProps({});
  }, [setPromise, setVisible, setMessage, setTitle, setProps]);

  const Dialog = useMemo(() => {
    return visible ? (
      <ConfirmDialog
        title={title}
        message={message}
        handleClose={handleClose}
        handleConfirm={() => {
          promise?.resolve(true);
          handleClose();
        }}
        handleCancel={() => {
          promise?.resolve(false);
          handleClose();
        }}
        {...props}
      />
    ) : null;
  }, [visible, title, message, promise, props, handleClose]);

  return {
    ConfirmDialog: Dialog,
    confirm,
  };
};

export default ConfirmDialog;
