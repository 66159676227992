import { useState, useEffect, useCallback, useMemo, useContext } from "react";
import type { BusinessInvitation, BusinessSubscription, LensSDKException, UserBusinessRole } from "lens-platform-sdk";
import { Modal, Notification } from "@k8slens/lds";
import { FormField } from "@k8slens/lds-form";

import { useAnalytics } from "src/hooks/useAnalytics";
import { getUniqueEmailsArray, validateEmail } from "src/services/email";
import { getSubscriptionSeatCount } from "src/services/getSubscriptionSeatCount";
import { getPlanName } from "src/services/plans";

import TrackedButton from "src/components/Button/TrackedButton";
import { FormSelect } from "src/components/FormComponents/FormSelect";

import { SubscriptionSelectContent } from "./SubscriptionSelectContent";

import styles from "./AddUsersModal.module.css";
import { NoSubscriptionSelectOption } from "./NoSubscriptionSelectContent";
import { RoleOption } from "./role";
import { RoleSelect } from "./RoleSelect";
import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";
import { BusinessContext } from "src/components/Business/Base";

interface Props {
  open: boolean;
  adding: boolean;
  errorAdding?: LensSDKException | null;
  loadingBusinessInvitations: boolean;
  businessInvitations: BusinessInvitation[];
  onModalClose: () => void;
  addUsers: (
    emails: string,
    subscriptionId: string | undefined,
    role: UserBusinessRole,
  ) => Promise<LensSDKException | null>;
}

/**
 * The shape of the option data in the business subscription selector
 */
export interface SubscriptionOption {
  id: BusinessSubscription["id"];
  label: string;
}

/**
 * Get the shape of the option data to be used in the business subscription selector
 */
const getSubscriptionOption = (businessSubscription: BusinessSubscription): SubscriptionOption | undefined => {
  if (!businessSubscription) {
    return undefined;
  }

  if (businessSubscription.seats <= businessSubscription.usedSeats.length) {
    return undefined;
  }

  return {
    id: businessSubscription.id,
    label: getPlanName(businessSubscription.planCode),
  };
};

const cancelButtonLabel = "Cancel";
const title = "Invite Users";
const selectSubscriptionLabel = "Choose Lens Subscription";

const emailRequiredMessage = "Required";
const emailInvalidMessage = "Invalid email address(es)";

const addButtonLabel = "Invite";

const noSubscriptionOptionId = "no-subscription-option-id";
const noSubscriptionOptionLabel = "No Subscription";
const noSubscriptionOption = {
  id: noSubscriptionOptionId,
  label: noSubscriptionOptionLabel,
};

export const AddUsersModal = ({
  open,
  adding,
  errorAdding,
  loadingBusinessInvitations,
  onModalClose,
  addUsers,
}: Props) => {
  const { businessId } = useContext(BusinessContext);
  const { track, trackButtonClicked } = useAnalytics();

  const [selected, setSelected] = useState<SubscriptionOption | undefined>(noSubscriptionOption);
  const [selectedRole, setSelectedRole] = useState<RoleOption | undefined>({ id: "Member", label: "Member" });
  const { businessSubscriptions, loading: loadingBusinessSubscriptions } = useGetBusinessSubscriptions(businessId);
  const options = useMemo<SubscriptionOption[]>(
    () => [
      noSubscriptionOption,
      ...businessSubscriptions
        .map(getSubscriptionOption)
        // filter (unlikely) undefined returned from getOption
        .filter((option): option is SubscriptionOption => typeof option?.id === "string")
        // sort by label alphabetically
        .sort((a, b) => a.label.localeCompare(b.label)),
    ],
    [businessSubscriptions],
  );
  const chooseSubscription = useCallback(
    (option: SubscriptionOption | undefined) => {
      if (!option) {
        return;
      }
      track("Subscription Selected", { subscriptionId: option.id });
      setSelected(option);
    },
    [track, setSelected],
  );
  const chooseRole = useCallback(
    (option: RoleOption | undefined) => {
      if (!option) {
        return;
      }
      setSelectedRole(option);
    },
    [setSelectedRole],
  );

  const selectedBusinessSubscription: BusinessSubscription | undefined = businessSubscriptions.find(
    ({ id }) => id === selected?.id,
  );
  const selectedBusinessSubscriptionSeatCount = useMemo(
    () => getSubscriptionSeatCount(selectedBusinessSubscription),
    [selectedBusinessSubscription],
  );

  const [emails, setEmails] = useState<string>("");
  const [emailsValid, setEmailsValid] = useState<boolean>(false);
  const [emailsErrorMessage, setEmailsErrorMessage] = useState<string>("");

  /**
   * Return the errorMessage if the emails are invalid or undefined if valid
   */
  const emailsValidate = useCallback((emails: string) => {
    // emails is a empty string
    if (emails.length === 0) {
      return emailRequiredMessage;
    }

    const emailsArray = getUniqueEmailsArray(emails);
    const isAllEmailValid = emailsArray.every(validateEmail);

    if (!isAllEmailValid) {
      return emailInvalidMessage;
    }

    return undefined;
  }, []);

  useEffect(() => {
    const result = emailsValidate(emails);

    setEmailsValid(result === undefined);
    setEmailsErrorMessage(result ?? "");
  }, [emails, emailsValidate, selectedBusinessSubscriptionSeatCount.available]);

  // Helper message
  const emailsMessage = useMemo(() => {
    if (!selectedBusinessSubscription) {
      return `Add ${emails.split(",").length} user${emails.split(",").length > 1 ? "s" : ""} without a seat`;
    }

    if (!emailsValid) {
      return;
    }
    const users = getUniqueEmailsArray(emails);

    return `Add ${users.length} user${users.length > 1 ? "s with seats" : " with a seat"} from the ${getPlanName(
      selectedBusinessSubscription.planCode,
    )} subscription`;
  }, [emails, emailsValid, selectedBusinessSubscription]);

  /**
   * Reset state
   */
  const reset = useCallback(() => {
    setEmails("");
    setEmailsValid(false);
    setEmailsErrorMessage("");
    chooseSubscription(noSubscriptionOption);
  }, [chooseSubscription]);

  /**
   * Actions
   */
  const handleClickAdd = useCallback(async () => {
    trackButtonClicked(addButtonLabel);

    if (!selectedRole) {
      throw new Error("Role is not selected");
    }

    const exception = await addUsers(emails, selectedBusinessSubscription?.id, selectedRole.id);

    if (!exception) {
      reset();
    }
  }, [trackButtonClicked, selectedRole, addUsers, emails, selectedBusinessSubscription?.id, reset]);

  return (
    <Modal
      title={title}
      size="md"
      contentProps={{
        className: styles.addUsersModalContent,
      }}
      onClose={() => {
        if (!adding) {
          reset();
          onModalClose();
        }
      }}
      isOpen={open}
      buttonBarProps={{
        type: "grid",
        gridSize: 3,
      }}
      footer={
        <>
          <button
            aria-label={cancelButtonLabel}
            type="button"
            onClick={() => {
              if (!adding) {
                trackButtonClicked(cancelButtonLabel);
                onModalClose();
                reset();
              }
            }}
            className="lds-button"
          >
            {cancelButtonLabel}
          </button>
          <div />
          <TrackedButton
            loading={adding || loadingBusinessSubscriptions || loadingBusinessInvitations}
            label={addButtonLabel}
            buttonType="submit"
            primary
            onClick={handleClickAdd}
            disabled={emailsValid === false}
          />
        </>
      }
    >
      <form aria-label={title} autoComplete="off">
        {errorAdding ? (
          <Notification
            level="error"
            message={errorAdding?.message ?? "unknown"}
            type="flash"
            className={styles.errorNotification}
          />
        ) : null}
        <fieldset>
          {options.length > 1 ? (
            <FormSelect
              name="subscription-selection"
              label={selectSubscriptionLabel}
              loading={loadingBusinessSubscriptions}
              value={selected}
              onChange={(value) => {
                chooseSubscription(value);
              }}
              options={options}
              renderContent={(value) => {
                if (value?.id === noSubscriptionOptionId) {
                  return <NoSubscriptionSelectOption />;
                }

                return value ? (
                  <SubscriptionSelectContent id={value.id} businessSubscriptions={businessSubscriptions} />
                ) : (
                  ""
                );
              }}
              wrapperProps={{
                className: "!min-h-min",
              }}
              className={styles.subscriptionsSelectWrapper}
            />
          ) : null}
          <RoleSelect value={selectedRole} onChange={chooseRole} />
          <FormField
            id="emails"
            errorId="emailErrors"
            labelId="emailLabel"
            label="Invite users to your Lens Business ID"
            errors={emailsErrorMessage ? [emailsErrorMessage] : []}
          >
            <textarea
              value={emails}
              onChange={({ target: { value } }) => {
                setEmails(value);
              }}
              autoComplete="off"
              name="user-emails"
              placeholder="Comma separated list of Email addresses"
              className={styles.emailTextarea}
              spellCheck="false"
            />
          </FormField>
          {emailsMessage ? (
            <div className={styles.emailsMessage}>
              <span>{emailsMessage}</span>
            </div>
          ) : null}
        </fieldset>
      </form>
    </Modal>
  );
};
