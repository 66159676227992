import clsx from "clsx";
import { ProgressBar, Tooltip } from "@k8slens/lds";
import { base } from "@k8slens/lds-icons";

import { useBusinessIdParam } from "src/hooks/useBusinessIdParam";
import { useBusinessMAU } from "src/hooks/useBusinessMAU";
import { useSubscriptionSeatCount } from "src/hooks/useSubscriptionSeatCount";

import Link from "src/components/TrackedLink/TrackedLink";

import styles from "./Analytics.module.css";

const { InfoIcon } = base;

export function Analytics({ children }: { children?: React.ReactNode }) {
  return (
    <section>
      <h3 className="lds-h4">Analytics</h3>
      <div className={styles.analytics}>{children}</div>
    </section>
  );
}

export function SeatCount() {
  const businessId = useBusinessIdParam();
  const { seatCount } = useSubscriptionSeatCount(businessId);

  const noSeatsAssigned = seatCount.total > 0 && seatCount.inUse === 0;
  const seatsFull = seatCount.inUse >= seatCount.total;

  return (
    <div className={styles.analyticsBlock}>
      <div className={styles.description}>
        <span className={styles.caps}>{`${seatCount.inUse}/${seatCount.total}`} seats assigned</span>
        {noSeatsAssigned || seatsFull ? <span>-</span> : null}
        {noSeatsAssigned ? (
          <Link to={`/business/${businessId}/users`} className={clsx("primary", styles.caps)}>
            Invite users
          </Link>
        ) : null}
        {seatsFull ? (
          <Link to={`/business/${businessId}/billing-details`} className={clsx("primary", styles.caps)}>
            Add seats
          </Link>
        ) : null}
      </div>
      <ProgressBar className={styles.progressBar} value={seatCount.inUse} max={seatCount.total} type="ok" />
    </div>
  );
}

export function MAU() {
  const businessId = useBusinessIdParam();
  const { mau } = useBusinessMAU(businessId);

  return (
    <div className={styles.analyticsBlock}>
      <div className={styles.description}>
        <span className={styles.caps}>{`${mau.active}/${mau.total}`} Lens Desktop MAU</span>
        <Tooltip
          placement="right"
          tabIndex={0}
          text="This metric shows the usage of the Lens Desktop product across your Lens Business ID users over the past 30 days. Note that Lens IDs activated offline are counted as active users"
        >
          <InfoIcon className={styles.infoIcon} size="sm" />
        </Tooltip>
      </div>
      <ProgressBar className={styles.progressBar} value={mau.active} max={mau.total} type="ok" />
    </div>
  );
}
