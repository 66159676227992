import { useEffect } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { InMemoryProvider, OpenFeature, OpenFeatureProvider } from "@openfeature/react-sdk";

import { ProfileProvider } from "src/providers/profile-provider";
import { TokenProvider } from "src/providers/token-provider";
import { AnalyticsProvider } from "src/providers/analytics-provider";

import { Routes } from "./Routes";
import { isStaging, keycloakClientId, keycloakOpenIdConnectUrl } from "./constants";
import { flagConfig } from "./featureFlags";
import { queryClient } from "./queryClient";
import { AuthProvider } from "./providers/auth-provider";
import { OfflineCodeRoutes } from "./app/offline-activation-code/OfflineCodeRoutes";

interface LocationState {
  from: {
    pathname: string;
    search: string;
    hash: string;
  };
}

OpenFeature.setProvider(new InMemoryProvider(flagConfig));
const openFeatureClient = OpenFeature.getClient();

const App = () => {
  useEffect(() => {
    OpenFeature.setContext({
      isStaging: isStaging === true,
      isDevelopment: process.env.NODE_ENV === "development",
      isTest: process.env.NODE_ENV === "test",
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Error setting OpenFeature context", error);
    });
  }, []);

  // has to use native window.location instead of react-router-dom's location
  // to avoid duplicated auth provider instances
  if (window.location.pathname.includes("offline-activation-code")) {
    return <OfflineCodeRoutes />;
  }

  return (
    <OpenFeatureProvider client={openFeatureClient}>
      <AuthProvider
        config={{
          authority: keycloakOpenIdConnectUrl,
          clientId: keycloakClientId,
        }}
      >
        <TokenProvider>
          <AnalyticsProvider>
            <QueryClientProvider client={queryClient}>
              <ProfileProvider>
                <Routes />
              </ProfileProvider>
            </QueryClientProvider>
          </AnalyticsProvider>
        </TokenProvider>
      </AuthProvider>
    </OpenFeatureProvider>
  );
};

export type { LocationState };
export default App;
