import clsx from "clsx";
import { ButtonProps } from "@k8slens/lds/lib/es/Button/Button";

import TrackedButton from "src/components/Button/TrackedButton";
import TrackedAnchorButton, { type TrackedAnchorButtonProps } from "src/components/TrackedAnchor/TrackedAnchorButton";

import styles from "./PageAction.module.css";

type Props = {
  label: ButtonProps["label"];
  type?: ButtonProps["type"];
  buttonProps?: Omit<ButtonProps, "type" | "label">;
  className?: string;
  buttonComponent?: typeof TrackedButton;
};

type WithLinkProps = {
  label: TrackedAnchorButtonProps["label"];
  type?: TrackedAnchorButtonProps["type"];
  buttonProps?: Omit<TrackedAnchorButtonProps, "type" | "label">;
  className?: string;
  buttonComponent: typeof TrackedAnchorButton;
};

const PageAction = ({
  type = "primary",
  label,
  buttonProps,
  className,
  buttonComponent: Component = TrackedButton,
}: Props | WithLinkProps) => {
  return (
    <div className={clsx(styles.pageAction, className)}>
      <Component {...(buttonProps as any)} type={type} label={label} aria-label={label} size="sm" />
    </div>
  );
};

export default PageAction;
