import { BusinessUser, LensPlatformClient, LensSDKException } from "lens-platform-sdk";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "src/queryClient";
import { useLensPlatformClient } from "./useLensPlatformClient";

export const useChangeBusinessUserRole = (businessId?: string) => {
  const lensPlatformClient = useLensPlatformClient();

  const {
    isPending: loading,
    error,
    mutate: changeBusinessUserRole,
  } = useMutation<
    Awaited<ReturnType<LensPlatformClient["business"]["changeBusinessUserRole"]>>,
    LensSDKException,
    {
      businessUserId: string;
      role: BusinessUser["role"];
    }
  >({
    mutationFn: async ({ businessUserId, role }) => {
      if (!businessId) {
        throw new Error("Business ID is required");
      }

      if (!businessUserId) {
        throw new Error("Business User ID is required");
      }
      const response = await lensPlatformClient.business.changeBusinessUserRole(businessId, businessUserId, role);

      return response;
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["businessUsers", businessId] });
    },
  });

  return {
    changeBusinessUserRole,
    error,
    loading,
  };
};
