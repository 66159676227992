import { getAccountUrl, getBillingInfoUrl } from "src/services/billing/billingUrl";
import { useSubscriptionBillingPageToken } from "./useSubscriptionBillingPageToken";

/**
 * Get billing urls for a subscription
 */
export const useSubscriptionBillingUrls = (username: string | undefined, subscriptionId: string | undefined) => {
  const { error, token } = useSubscriptionBillingPageToken(username, subscriptionId);
  const accountUrl = getAccountUrl(token);
  const billingInfoUrl = getBillingInfoUrl(token);

  return { error, accountUrl, billingInfoUrl };
};
