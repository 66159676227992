import { recurly } from "src/constants";

export const getBillingInfoUrl = (token: string | null) => {
  if (token) {
    return `${recurly}/account/billing_info/edit?ht=${token}`;
  }

  return undefined;
};

export const getAccountUrl = (token: string | null) => {
  if (token) {
    return `${recurly}/account/${token}`;
  }

  return undefined;
};

export const getUpdateBillingDetailUrl = (token: string) => `${recurly}/account/billing_info/edit?ht=${token}`;

export const getAccountInvoicePDFUrl = (invoiceNumber: string, token: string | null) => {
  if (token) {
    return `${recurly}/account/invoices/${invoiceNumber}.pdf?ht=${token}`;
  }

  return undefined;
};
