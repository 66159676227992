import { useMemo } from "react";
import { getCurrentSubscriptionSeat } from "src/services/getCurrentSubscriptionSeat";
import { getSubscriptionSeatWithBillingPeriod } from "src/utils/license";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useQuery } from "@tanstack/react-query";
import { LensSDKException, SubscriptionSeat } from "lens-platform-sdk";
import { useUsername } from "./useUsername";

/**
 * Get user's subscription seats by username
 */
export const useGetSubscriptionSeats = () => {
  const username = useUsername();
  const lensPlatformClient = useLensPlatformClient();

  const {
    isLoading: loading,
    error,
    data: subscriptionSeats,
    refetch,
  } = useQuery<SubscriptionSeat[], LensSDKException>({
    queryKey: ["user-subscription-seats", username],
    queryFn: () => lensPlatformClient.user.getUserSubscriptionsSeats(username),
  });

  const currentSubscriptionSeat = useMemo(
    () => (subscriptionSeats ? getCurrentSubscriptionSeat(subscriptionSeats) : undefined),
    [subscriptionSeats],
  );
  const currentSubscriptionSeatWithBillingPeriod = useMemo(() => {
    if (currentSubscriptionSeat) {
      return getSubscriptionSeatWithBillingPeriod(currentSubscriptionSeat);
    }

    return undefined;
  }, [currentSubscriptionSeat]);

  return {
    loading,
    error,
    subscriptionSeats,
    currentSubscriptionSeat,
    currentSubscriptionSeatWithBillingPeriod,
    reload: refetch,
  };
};
