import { useCallback, useContext } from "react";
import type { Location } from "history";
import { EventProperties } from "@segment/analytics-next";
import pick from "lodash/pick";

import { AnalyticsContext } from "src/providers/analytics-provider";

const defaultOptions = { context: { direct: true } };

export const useAnalytics = () => {
  const { analytics } = useContext(AnalyticsContext);

  const pageViewed = useCallback(
    (name: string, page: Location) => analytics?.page(name, page, defaultOptions).catch(() => {}),
    [analytics],
  );

  const trackRedirect = useCallback(
    (url: string) => analytics?.track("Redirect", { url }, defaultOptions).catch(() => {}),
    [analytics],
  );

  const track = useCallback(
    (action: string, payload: EventProperties = {}) =>
      analytics?.track(action, payload, defaultOptions).catch(() => {}),
    [analytics],
  );

  const trackError = useCallback(
    (action: string, payload: EventProperties = {}) => track(`[ERROR]: ${action}`, { type: "error", ...payload }),
    [track],
  );

  const trackButtonClicked = useCallback(
    (title: string, payload: EventProperties = {}) => track("Button Clicked", { ...payload, title }),
    [track],
  );

  const trackLinkClicked = useCallback(
    (title: string, payload: EventProperties = {}) =>
      track("Link Clicked", { ...payload, title })?.then((response) => response), // TODO: Track redirect if external site,
    [track],
  );

  const trackAnchorElement = useCallback(
    (element: HTMLAnchorElement, title: string, payload: EventProperties = {}) => {
      if (!analytics?.trackLink) {
        return false;
      }

      // TODO: How to untrack if disabled?
      if (!analytics?.trackLink) {
        return false;
      }

      // TODO: How to untrack if disabled?
      const attributes = pick(element, ["href", "target", "rel", "disabled"]);

      analytics
        .trackLink(element, "Link Clicked", {
          ...attributes,
          ...payload,
          title,
        })
        .then((response) => response); // TODO: Track redirect if external site

      return true;
    },
    [analytics],
  );

  const trackFeedback = useCallback(
    ({ category, type }: { category: string; type: string }) => track("Feedback Sent", { category, type }),
    [track],
  );

  return {
    pageViewed,
    trackRedirect,
    track,
    trackError,
    trackButtonClicked,
    trackLinkClicked,
    trackAnchorElement,
    trackFeedback,
  };
};
