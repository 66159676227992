import { useCallback, useState } from "react";
import { type Business, type LensSDKException, NotFoundException, LensPlatformClient } from "lens-platform-sdk";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "src/queryClient";
import { wait } from "src/services/wait";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useAnalytics } from "./useAnalytics";

interface Delay {
  /** artificial delay in ms after query success, default is 0 */
  query?: number;
  /** artificial delay in ms for mutation success, default is 0 */
  mutation?: number;
}

export type ParentBusiness = Awaited<ReturnType<LensPlatformClient["business"]["getParent"]>>;

/**
 * Hooks for read and delete business (Lens Business IDs) parent.
 */
export const useBusinessParent = (businessId?: Business["id"] | null, delay?: Delay) => {
  const lensPlatformClient = useLensPlatformClient();
  const { track } = useAnalytics();

  const [delaying, setDelaying] = useState(false);
  const waitForDelay = useCallback(
    async (type: "query" | "mutation") => {
      setDelaying(true);

      try {
        if (delay) {
          await wait(delay[type] || 0);
        }
      } finally {
        setDelaying(false);
      }
    },
    [delay],
  );

  const {
    isLoading: loadingBusinessParent,
    error: errorLoadingBusinessParent,
    data: businessParent,
    refetch: refetchBusinessParent,
  } = useQuery<ParentBusiness[], LensSDKException>({
    queryKey: ["businessParent", businessId],
    queryFn: async () => {
      if (!businessId) {
        return [];
      }

      try {
        const parent = await lensPlatformClient.business.getParent(businessId);

        await waitForDelay("query");

        return [parent];
      } catch (error) {
        if (error instanceof NotFoundException) {
          return [];
        }
        throw error;
      }
    },
    initialData: [],
  });

  const {
    isPending: removingBusinessParent,
    isSuccess: removingBusinessParentSuccess,
    error: errorRemovingBusinessParent,
    mutate: removeBusinessParent,
  } = useMutation<void, LensSDKException, Business["id"]>({
    mutationFn: (childBusinessId: Business["id"]) => lensPlatformClient.business.removeParent(childBusinessId),
    /** if returning a Promise, isSuccess will never be true */
    onSuccess: () => {
      waitForDelay("mutation").then(() => {
        queryClient.invalidateQueries({ queryKey: ["businessParent", businessId] });
      });
      track("BusinessID parent removed", { businessId });
    },
  });

  return {
    delaying,
    loadingBusinessParent,
    errorLoadingBusinessParent,
    businessParent,
    refetchBusinessParent,
    removingBusinessParent,
    removingBusinessParentSuccess,
    errorRemovingBusinessParent,
    removeBusinessParent,
  };
};
