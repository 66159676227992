import { useCallback, useContext, useState } from "react";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { ProfileContext } from "../providers/profile-provider";

export const recurlyIdPrefix = "uuid-";

export const useLicenseActivation = () => {
  const lensPlatformClient = useLensPlatformClient();
  const [licenseError, setLicenseError] = useState<string | null>(null);
  const { profile } = useContext(ProfileContext);
  const activateLicense = useCallback(
    async (subscriptionId: string, type: "pro" | "personal" | "pro-trial") => {
      setLicenseError(null);

      if (profile?.username) {
        try {
          const activated = await lensPlatformClient.user.activateSubscriptionSeat({
            username: profile.username,
            license: {
              subscriptionId,
              type,
              ...(type === "personal" ? { personalLicenseEligibilityAccepted: true } : null),
            },
          });

          return activated;
        } catch (error: any) {
          let message = error instanceof Error || error?.message ? error.message : "";

          if (message.startsWith("Unexpected exception")) {
            message = "Something went wrong. Please try again later.";
          }
          setLicenseError(message);

          return null;
        }
      } else {
        throw new Error("Username is not defined");
      }
    },
    [profile, lensPlatformClient.user],
  );

  const activateBusinessSubscription = useCallback(
    async (subscriptionId: string, businessInvitationId: string, businessId: string) => {
      setLicenseError(null);

      try {
        const activated = await lensPlatformClient.business.activateBusinessUserSubscription({
          businessId,
          businessSubscriptionId: subscriptionId,
          businessInvitationId,
        });

        return activated;
      } catch (error: any) {
        let message = error instanceof Error || error?.message ? error.message : "";

        if (message.startsWith("Unexpected exception")) {
          message = "Something went wrong. Please try again later.";
        }
        setLicenseError(message);

        return null;
      }
    },
    [lensPlatformClient.business],
  );

  const deactivateLicense = useCallback(
    async (subscriptionId: string) => {
      setLicenseError(null);

      if (profile) {
        try {
          const deactivated = await lensPlatformClient.user.deactivateSubscriptionSeat({
            username: profile.username as string,
            license: { subscriptionId },
          });

          return deactivated;
        } catch (error: any) {
          let message = error instanceof Error || error?.message ? error.message : "";

          if (message.startsWith("Unexpected exception")) {
            message = "Something went wrong. Please try again later.";
          }
          setLicenseError(message);

          return null;
        }
      }

      return null;
    },
    [profile, lensPlatformClient.user],
  );

  return {
    activateLicense,
    deactivateLicense,
    licenseError,
    activateBusinessSubscription,
  };
};
