import * as React from "react";
import { DecodedAccessToken } from "src/components/pages/Home/license";
import { useAuth } from "src/hooks/useAuth";

export interface TokenData {
  refreshToken?: string;
  idToken?: string;
  accessToken?: string;
  token?: DecodedAccessToken;
}

export interface LicenseTokenContext extends TokenData {
  updateToken: (minValidity?: number) => void;
}

export const TokenContext = React.createContext<LicenseTokenContext>({
  updateToken: () => {},
  token: undefined,
  idToken: undefined,
  accessToken: undefined,
  refreshToken: undefined,
});

interface Props {
  children?: React.ReactNode;
}

export const TokenProvider: React.FC<Props> = ({ children }) => {
  const { updateToken, tokenParsed, idToken, token, refreshToken } = useAuth();

  return (
    <TokenContext.Provider
      value={{
        updateToken,
        token: tokenParsed,
        refreshToken,
        idToken,
        accessToken: token,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};
