import { useEffect, useState, useRef, useCallback } from "react";
import type { SubscriptionInfo } from "lens-platform-sdk";
import { LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";

/**
 * Get user's subscription by username
 */
export const useGetSubscriptions = (username?: string) => {
  const lensPlatformClient = useLensPlatformClient();

  const [subscriptions, setSubscriptions] = useState<SubscriptionInfo[]>([]);

  const [error, setError] = useState<LensSDKException | null>(null);
  const [loading, setLoading] = useState(false);

  // Keep up with mounted/unmounted state
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const getSubscriptions = useCallback(async () => {
    try {
      if (mounted.current) {
        setLoading(true);
      }

      if (username) {
        const response = await lensPlatformClient.user.getUserSubscriptions(username);

        if (mounted.current) {
          setSubscriptions(response);
          setError(null);
        }
      }
    } catch (exception) {
      if (mounted.current && exception instanceof LensSDKException) {
        setError(exception);
      }
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [lensPlatformClient.user, username]);

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  return {
    loading,
    error,
    subscriptions,
    reload: getSubscriptions,
  };
};
