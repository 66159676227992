import { LinkButton } from "@k8slens/lds";

import RouterLinkButton from "src/components/Button/TrackedRouterLinkButton";

import { useBusinessIdParam } from "src/hooks/useBusinessIdParam";
import { useIsEnterpriseFeaturesEnabled } from "src/hooks/useIsEnterpriseFeaturesEnabled";
import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";

import { useUpgradeToEnterpriseModal } from "./useUpgradeToEnterpriseModal";

import styles from "./Catalog.module.css";

export function Catalog() {
  const businessId = useBusinessIdParam();
  const { open, Modal } = useUpgradeToEnterpriseModal();
  const { loading: loadingSubscriptions, businessSubscriptions } = useGetBusinessSubscriptions(businessId);
  const { enterpriseFeaturesEnabled } = useIsEnterpriseFeaturesEnabled(
    loadingSubscriptions ? undefined : businessSubscriptions,
  );

  return (
    <div>
      <h3 className="lds-h4">Manage Business ID</h3>
      <div className={styles.catalogBlocks}>
        <section className={styles.block}>
          <p>View and edit company profile information including company name, logo and other contact details.</p>
          <RouterLinkButton to={`/business/${businessId}/profile`} size="sm" label="Edit Profile" />
        </section>
        <section className={styles.block}>
          <p>Manage information around this Business ID such as address and handle.</p>
          <RouterLinkButton to={`/business/${businessId}/account`} size="sm" label="Edit Account" />
        </section>
        <section className={styles.block}>
          <p>
            Configure how your users authenticate within the system. Set up Single Sign-On (SSO) and SCIM provisioning.
          </p>
          {enterpriseFeaturesEnabled ? (
            <RouterLinkButton to={`/business/${businessId}/auth`} size="sm" label="Edit Authentication" />
          ) : (
            <LinkButton onClick={open} size="sm" label="Edit Authentication" />
          )}
        </section>
      </div>
      <h3 className="lds-h4">Manage Subscriptions and Licenses</h3>
      <div className={styles.catalogBlocks}>
        <section className={styles.block}>
          <p>
            Manage and view billing information, invoices, subscriptions, payment methods and adjust subscriptions seat
            quantities.
          </p>
          <RouterLinkButton to={`/business/${businessId}/billing-details`} size="sm" label="Manage Billing" />
        </section>
        <section className={styles.block}>
          <p>Invite new users to your Business ID, assign them subscriptions, and manage their access roles.</p>
          <RouterLinkButton to={`/business/${businessId}/users`} size="sm" label="Manage Users" />
        </section>
        <section className={styles.block}>
          <p>
            Centralized feature management allows you to enable or disable features across all users within your Lens
            Business ID.
          </p>
          <RouterLinkButton to={`/business/${businessId}/feature-flag-settings`} size="sm" label="Manage Features" />
        </section>
      </div>
      {Modal}
    </div>
  );
}
