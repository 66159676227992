import LinkButton from "src/components/Button/TrackedRouterButton";
import { type NotificationProps } from "@k8slens/lds/lib/es/Notification/Notification";

import styles from "./ProTrialUsed.module.css";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";
import PublicLayout from "src/components/PublicLayout/PublicLayout";
import { ThankYouFooter } from "./ThankYouFooter";

const panelProps = {
  footerProps: {
    type: "centered",
  },
};

const notificationProps: Partial<NotificationProps> = {
  level: "success",
};

export const ProTrialUsed: React.FC = () => {
  return (
    <PublicLayout
      title="Thank you for your Lens Subscription!"
      size="lg"
      wrapperClassName={styles.proTrialUsed}
      pageFooter={<ThankYouFooter />}
      panelProps={panelProps}
      header={<p className={styles.subtitle}>You have already activated your trial.</p>}
      footer={<LinkButton to="/lens-launcher" target="_blank" label="Open Lens Desktop" primary />}
      notification={
        <>
          Need help convincing your boss? Need more time to onboard your teams? We’ll figure out something that works
          for you and your team.{" "}
          <TrackedAnchor href="https://public.refiner.io/s/eolx2e/9669d4a0-7a07-11ed-b065-2f290bc11892" target="_blank">
            Let us help!
          </TrackedAnchor>
        </>
      }
      notificationProps={notificationProps}
    >
      <img src="/static/media/thank-you-for-purchase-hero.svg" alt="Thank you!" />
    </PublicLayout>
  );
};
