import { ReactNode } from "react";
import clsx from "clsx";
import { flexRender, HeaderGroup, Header } from "@tanstack/react-table";

import styles from "./Table.module.css";
import { TableHeader } from "./TableHeader";

export type TableHeaderGroupProps<TData> = {
  className?: string;
  cellClassName?: string;
  headerGroup: HeaderGroup<TData>;
  renderHeaderCell?(header: Header<TData, unknown>): ReactNode;
};

const TableHeaderGroup = function <T>({
  headerGroup,
  renderHeaderCell = (header) => flexRender(header.column.columnDef.header, header.getContext()),
  className = "",
  cellClassName = "",
}: TableHeaderGroupProps<T>) {
  return (
    <tr className={clsx(styles.tableHeaderRow, className)}>
      {headerGroup.headers.map((header) => (
        <TableHeader
          key={header.id}
          header={header}
          cellClassName={cellClassName}
          renderHeaderCell={renderHeaderCell}
        />
      ))}
    </tr>
  );
};

export default TableHeaderGroup;
