import { useState, useEffect, useCallback } from "react";
import type { LensSDKException } from "lens-platform-sdk";
import { Modal, Button, Notification } from "@k8slens/lds";

import { useAnalytics } from "src/hooks/useAnalytics";

import { getUniqueEmailsArray, validateEmail } from "src/services/email";
import styles from "src/components/Business/InviteAdminMode.module.css";
import TrackedButton from "src/components/Button/TrackedButton";

interface Props {
  open: boolean;
  addingEmails: boolean;
  addEmailError?: LensSDKException;
  onModalClose: () => void;
  onClickAddEmails: (emails: string) => void;
}

const cancelButtonLabel = "Cancel";
const title = "Add Email Address";

const emailRequiredMessage = "Required";
const emailInvalidMessage = "Invalid email address(es)";

const addEmailButtonLabel = "Add";

export const AddEmailModal = ({ open, addingEmails, addEmailError, onModalClose, onClickAddEmails }: Props) => {
  const { trackButtonClicked } = useAnalytics();
  const [emails, setEmails] = useState<string>("");
  const [emailsValid, setEmailsValid] = useState<boolean>(false);
  const [emailsErrorMessage, setEmailsErrorMessage] = useState<string>("");

  /**
   * Reset state
   */
  const reset = useCallback(() => {
    setEmails("");
    setEmailsValid(false);
    setEmailsErrorMessage("");
  }, []);

  /**
   * Return the errorMessage if the emails are invalid or undefined if valid
   */
  const emailsValidate = useCallback((emails: string) => {
    // emails is a empty string
    if (emails.length === 0) {
      return emailRequiredMessage;
    }

    const emailsArray = getUniqueEmailsArray(emails);
    const isAllEmailValid = emailsArray.every(validateEmail);

    if (!isAllEmailValid) {
      return emailInvalidMessage;
    }

    return undefined;
  }, []);

  useEffect(() => {
    const result = emailsValidate(emails);

    setEmailsValid(result === undefined);
    setEmailsErrorMessage(result ?? "");
  }, [emails, emailsValidate]);

  return (
    <Modal
      title={title}
      size="md"
      contentProps={{
        className: styles.addEmailModalContent,
      }}
      onClose={() => {
        if (!addingEmails) {
          onModalClose();
          reset();
        }
      }}
      isOpen={open}
      buttonBarProps={{
        type: "grid",
        gridSize: 3,
      }}
      footer={
        <>
          <Button
            label={cancelButtonLabel}
            onClick={() => {
              if (!addingEmails) {
                trackButtonClicked(cancelButtonLabel);
                onModalClose();
                reset();
              }
            }}
          />
          <div />
          <TrackedButton
            loading={addingEmails}
            label={addEmailButtonLabel}
            id="addEmails"
            buttonType="submit"
            primary
            onClick={() => {
              trackButtonClicked(addEmailButtonLabel);
              onClickAddEmails(emails);
            }}
            disabled={emailsValid === false}
          />
        </>
      }
    >
      <form aria-label={title} autoComplete="off">
        {addEmailError ? (
          <Notification
            level="error"
            message={addEmailError?.message ?? "unknown"}
            type="flash"
            className={styles.errorNotification}
          />
        ) : null}
        <fieldset>
          <div className={styles.emailTextareaLabel}>
            <span>Add Email(s)</span>
          </div>
          <textarea
            value={emails}
            onChange={({ target: { value } }) => {
              setEmails(value);
            }}
            autoComplete="off"
            name="userEmails"
            placeholder="Comma separated list of Email addresses"
            className={styles.emailTextarea}
            spellCheck="false"
          />
          {emailsErrorMessage.length > 0 ? (
            <div className={styles.emailsErrorMessage}>
              <span>{emailsErrorMessage}</span>
            </div>
          ) : null}
          {emailsValid ? (
            <div className={styles.emailsMessage}>
              <span>{`Add ${getUniqueEmailsArray(emails).length} email(s) to your account`}</span>
            </div>
          ) : null}
        </fieldset>
      </form>
    </Modal>
  );
};
