import { useMutation } from "@tanstack/react-query";
import { useAuth } from "./useAuth";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { LensSDKException } from "lens-platform-sdk";
import { License } from "lens-platform-sdk/dist/cjs/types/types";

export const useActivateProTrial = () => {
  const { updateToken, username } = useAuth();
  const lensPlatformClient = useLensPlatformClient();

  const { isPending, isSuccess, error, mutate } = useMutation<License, LensSDKException>({
    mutationFn: async () => {
      if (!username) {
        throw new Error("Username is not defined");
      }

      const activated = await lensPlatformClient.user.activateSubscriptionSeat({
        username,
        license: {
          subscriptionId: "",
          type: "pro-trial",
        },
      });

      return activated;
    },
    onSuccess: async () => {
      await updateToken();
    },
  });

  return {
    isPending,
    isSuccess,
    error,
    activateProTrial: mutate,
  };
};
