import React, { type PropsWithChildren, useMemo, useState, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import type { Business } from "lens-platform-sdk";
import { Select } from "@k8slens/lds";

import { useBusinessInfo } from "src/hooks/useBusinessInfo";

import LBIDSelectItem from "./LBIDSelectItem";

import styles from "./LBIDSelect.module.css";
import { useUsername } from "src/hooks/useUsername";

interface Props {
  id?: string;
  className?: string;
  businessId?: string;
  sideBarOpen: boolean;
  buttonRef?: React.MutableRefObject<HTMLButtonElement | null>;
  loading?: boolean;
}

/**
 * The shape of the option data in the selector
 */
interface Option {
  id: Business["id"];
  label: string;
  subtitle?: string;
}

/**
 * The select component for the user to switch between their own account or their "Lens Business ID"
 */
const LBIDSelect: React.FC<PropsWithChildren<Props>> = ({
  businessId,
  sideBarOpen,
  className,
  loading,
  id,
  ...props
}) => {
  const history = useHistory();
  const username = useUsername();
  const { businesses } = useBusinessInfo(businessId, username);

  const _selectButtonRef = useRef<HTMLButtonElement>(null);
  const selectButtonRef = props.buttonRef ? props.buttonRef : _selectButtonRef;

  const businessOptions = useMemo(
    () => businesses.map(({ id, name, department }) => ({ id, label: name, subtitle: department })),
    [businesses],
  );

  const [selected, setSelected] = useState<Option>({ id: "", label: "", subtitle: "" });

  useEffect(() => {
    const business = businesses.find(({ id }) => id === businessId);

    if (businessId && business) {
      // set selected as the business id from /business/:businessId
      // on component mounts
      setSelected({
        id: business.id,
        label: business.name,
        subtitle: business.department,
      });
    }
  }, [businessId, businesses]);

  const handleChange = useCallback(
    (value: Option) => {
      setSelected(value);

      history.push(`/business/${value.id}`);
    },
    [history],
  );

  return (
    <Select
      id={id}
      className={clsx(styles.select, className)}
      disabled={!businessOptions.length}
      onChange={handleChange}
      value={selected}
      options={businessOptions}
      buttonRef={selectButtonRef}
      renderContent={(option) => <LBIDSelectItem item={option} />}
      loading={loading}
    />
  );
};

export default LBIDSelect;
