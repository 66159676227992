import FeatureList from "src/components/FeatureList/FeatureList";
import LicenseImage, { type LicenseImageTextKey } from "src/components/LicenseImage/LicenseImage";

import styles from "./ActiveLensSubscription.module.css";
import { LensDesktopLicenseType } from "./license";

interface Props {
  licenseType: keyof typeof LensDesktopLicenseType & LicenseImageTextKey;
  features: string[];
  billing: React.ReactNode;
}

export function ActiveLensSubscription({ licenseType, features, billing }: Props) {
  return (
    <div className={styles.subscription}>
      <LicenseImage type={licenseType} description="" className={styles.license} />
      <div>
        <FeatureList title="Included in your plan" features={features} />
      </div>
      <div>
        {billing && (
          <div className={styles.billing}>
            <div className={styles.billingList}>
              <div className={styles.billingTitle}>Billing &amp; Payment</div>
              {billing}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
