import { type AnchorHTMLAttributes, forwardRef, useRef } from "react";
import clsx from "clsx";
import type { ButtonProps } from "@k8slens/lds/lib/es/Button/Button";

import TrackedAnchor from "./TrackedAnchor";

import styles from "./TrackedAnchorButton.module.css";

export type TrackedAnchorButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  Pick<
    ButtonProps,
    | "label"
    | "type"
    | "textTransform"
    | "discreet"
    | "size"
    | "primary"
    | "disabled"
    | "icon"
    | "iconPosition"
    | "aria-label"
  >;

const TrackedAnchorButton = forwardRef<HTMLAnchorElement, TrackedAnchorButtonProps>(
  (
    {
      label,
      className,
      textTransform,
      discreet,
      primary,
      size,
      disabled,
      type,
      icon: Icn,
      iconPosition = "left",
      children,
      "aria-label": ariaLabel,
      ...props
    },
    ref,
  ) => {
    const anchorRer = useRef<HTMLAnchorElement | null>(null);

    return (
      <TrackedAnchor
        ref={(node) => {
          anchorRer.current = node;

          if (typeof ref === "function") {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        {...props}
        className={clsx(
          "lds-button",
          className,
          type,
          {
            "lds-button--uppercase": textTransform === "uppercase",
            "lds-button--capitalize": textTransform === "capitalize",
            disabled,
            "lds-button--discreet": discreet,
            primary,
            [`lds-button--${size}`]: Boolean(size),
          },
          styles.achorButton,
        )}
        aria-disabled={disabled}
        aria-label={ariaLabel || label}
      >
        {Icn && iconPosition === "left" && <Icn className="lds-button--icon" />}
        <span className="lds-button--label">{label}</span>
        {children}
        {Icn && iconPosition === "right" && <Icn className="lds-button--icon lds-button--icon-r" />}
      </TrackedAnchor>
    );
  },
);

export default TrackedAnchorButton;
