import { useEffect, useState, useRef, useCallback } from "react";
import type { SubscriptionInfo } from "lens-platform-sdk";
import { LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";

/**
 * Hook for updating business subscription seats quantity
 */
export const useUpdateBusinessSubscription = () => {
  const lensPlatformClient = useLensPlatformClient();
  const [error, setError] = useState<LensSDKException | undefined>();
  const [updating, setUpdating] = useState(false);
  const [response, setResponse] = useState<SubscriptionInfo | undefined>();

  // Keep up with mounted/unmounted state
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const updateBusinessSubscription = useCallback(
    async (businessId: string, businessSubscriptionId: string, quantity: number) => {
      try {
        if (mounted.current) {
          setUpdating(true);
          setError(undefined);
        }
        const response = await lensPlatformClient.business.changeBusinessSubscriptionSeatsQuantity({
          businessSubscriptionId,
          businessId,
          quantity,
        });

        if (mounted.current) {
          setResponse(response);
          setError(undefined);
        }

        return response;
      } catch (exception) {
        if (mounted.current && exception instanceof LensSDKException) {
          setError(exception);
        }

        return undefined;
      } finally {
        if (mounted.current) {
          setUpdating(false);
        }
      }
    },
    [lensPlatformClient.business],
  );

  return {
    updateBusinessSubscription,
    response,
    updating,
    error,
  };
};
