import { forwardRef, useMemo, type MouseEvent } from "react";
import { useHistory, LinkProps, useLocation } from "react-router-dom";
import { LinkButton } from "@k8slens/lds";
import { type LinkButtonProps } from "@k8slens/lds/lib/es/LinkButton/LinkButton";

export interface RouterLinkButtonProps extends Omit<LinkButtonProps, "href"> {
  to: LinkProps<any>["to"];
}

const RouterLinkButton = forwardRef<HTMLAnchorElement, RouterLinkButtonProps>(
  ({ to: _to, target, onClick, children, ...props }, ref) => {
    const location = useLocation();
    const history = useHistory();

    const to = useMemo(() => {
      if (typeof _to === "function") {
        return _to(location);
      }

      return _to ? _to : undefined;
    }, [location, _to]);

    const href = useMemo(() => {
      if (typeof to === "string") {
        return to;
      } else if (typeof to === "object" && to !== null) {
        const { pathname = "", search = "", hash = "" } = to;

        return `${pathname}${search}${hash}`;
      }

      return undefined;
    }, [to]);

    const handleClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
      if (onClick) {
        onClick(e);
      }

      if (!e.defaultPrevented && to) {
        e.preventDefault();
        history.push(to);
      }
    };

    return (
      <LinkButton
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        href={href}
        onClick={(e) => handleClick(e)}
      >
        {children}
      </LinkButton>
    );
  },
);

export default RouterLinkButton;
