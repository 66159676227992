import { keycloakLensDesktopClientId, keycloakOpenIdConnectUrl } from "src/constants";
import { AuthProvider } from "src/providers/auth-provider";
import { OfflineCode } from "./login/page";
import { DownloadCode } from "./download/page";
import PrivateRoute from "src/components/PrivateRoute/PrivateRoute";
import { queryClient } from "src/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import { Route, Switch, useRouteMatch } from "react-router-dom";

function Providers({ children }: { children: React.ReactNode }) {
  return (
    <AuthProvider
      config={{
        authority: keycloakOpenIdConnectUrl,
        // authenticated as the Lens Desktop client
        clientId: keycloakLensDesktopClientId,
      }}
    >
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </AuthProvider>
  );
}

function PrivateRoutes() {
  const { path } = useRouteMatch();

  return (
    <Providers>
      <Switch>
        <PrivateRoute path={`${path}/login`} exact>
          <OfflineCode />
        </PrivateRoute>
        <PrivateRoute path={`${path}/download`} exact>
          <DownloadCode />
        </PrivateRoute>
      </Switch>
    </Providers>
  );
}

export function OfflineCodeRoutes() {
  return (
    <Route path="/offline-activation-code">
      <PrivateRoutes />
    </Route>
  );
}
