import { useCallback, useState } from "react";
import { createOfflineActivationCodeDownload } from "src/utils/activation-code";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useQuery } from "@tanstack/react-query";
import { type OfflineActivationCode } from "lens-platform-sdk/dist/cjs/UserService";
import { LensSDKException } from "lens-platform-sdk";
import { useAuth } from "./useAuth";
import { useGetSubscriptionSeats } from "./useGetSubscriptionSeats";

const noSubscriptionSeatFoundErrorMessage = "No subscription seat found";

export const useDownloadActivationCode = () => {
  const { idToken, token: accessToken, refreshToken, username } = useAuth();
  const platformClient = useLensPlatformClient();
  const { currentSubscriptionSeat } = useGetSubscriptionSeats();
  const [downloaded, setDownloaded] = useState(false);

  const {
    isLoading,
    error,
    data: offlineActivationCode,
  } = useQuery<OfflineActivationCode | null, LensSDKException | Error>({
    queryKey: ["offline-activation-code", username, currentSubscriptionSeat?.id, accessToken, idToken, refreshToken],
    queryFn: async () => {
      if (!username || !accessToken || !idToken || !refreshToken) {
        return Promise.reject(new Error("Something went wrong"));
      }

      if (!currentSubscriptionSeat?.id) {
        return Promise.reject(new Error(noSubscriptionSeatFoundErrorMessage));
      }

      return platformClient.user.getSubscriptionSeatOfflineActivationCode({
        username,
        subscriptionSeatId: currentSubscriptionSeat?.id ?? "",
        activationCodeData: { accessToken, idToken, refreshToken },
      });
    },
    retry: (_, error) => {
      if (error.message === noSubscriptionSeatFoundErrorMessage) {
        return false;
      }

      return true;
    },
  });

  const downloadActivationCode: () => void = useCallback(() => {
    if (offlineActivationCode?.activationCode) {
      createOfflineActivationCodeDownload(offlineActivationCode.activationCode);
      setDownloaded(true);
    }
  }, [offlineActivationCode]);

  return {
    isLoading,
    error,
    offlineActivationCode,
    downloadActivationCode,
    downloaded,
  };
};
