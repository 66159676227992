import { LensPlatformClient, LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "src/queryClient";
import { useEffect, useRef, useState } from "react";

export const useAcceptBusinessInvitation = (
  onSuccess: (role: string, businessId: string, invitationId: string) => void,
) => {
  const lensPlatformClient = useLensPlatformClient();
  const [isNoSeat, setIsNoSeat] = useState(false);
  const timer: { current: NodeJS.Timeout | null } = useRef(null);
  const {
    isPending,
    error,
    mutate: acceptBusinessInvitation,
  } = useMutation<
    Awaited<ReturnType<LensPlatformClient["business"]["acceptInvitation"]>>,
    LensSDKException,
    {
      businessId: string;
      invitationId: string;
    }
  >({
    mutationFn: async ({ businessId, invitationId }) => {
      const invitation = await lensPlatformClient.business.acceptInvitation(businessId, invitationId);

      return invitation;
    },

    onSuccess: async ({ role }, { businessId, invitationId }) => {
      await queryClient.invalidateQueries({ queryKey: ["businessInvitations", businessId] });
      onSuccess(role, businessId, invitationId);
    },

    onError: (error, { businessId, invitationId }) => {
      if (error.message.toLocaleLowerCase().includes("you have been added")) {
        setIsNoSeat(true);
        timer.current = setTimeout(() => {
          onSuccess("unknown", businessId, invitationId);
        }, 20000);
      }
    },
  });

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return {
    acceptBusinessInvitation,
    error,
    isLoading: isPending,
    isNoSeat,
  };
};
