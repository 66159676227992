import { PropsWithChildren, ReactNode } from "react";
import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { type PanelProps } from "@k8slens/lds/lib/es/Panel/Panel.d";
import { navigation } from "@k8slens/lds-icons";

import DrawerTransition, { DrawerTransitionProps } from "src/components/DrawerTransition/DrawerTransition";

import styles from "./Aside.module.css";

const { ArrowDownIcon, ArrowUpIcon } = navigation;

export const panelProps: PanelProps<"div", "div"> = {
  wrapperComponent: "div",
  className: styles.panel,
  headerComponent: "div",
  headerProps: { className: styles.header },
  contentProps: { className: styles.content },
};

export interface AsideProps {
  closedLabel: string | ReactNode;
  openedLabel: string | ReactNode;
  transitionDeps?: DrawerTransitionProps["deps"];
  className?: string;
  panelClassName?: string;
  toggleClassName?: string;
  onToggle?(open: boolean): void;
}

const Aside = ({
  closedLabel,
  openedLabel,
  transitionDeps,
  className,
  panelClassName = "",
  toggleClassName = "",
  onToggle = () => {},
  children,
}: PropsWithChildren<AsideProps>) => (
  <Disclosure as="aside" className={className}>
    {({ open }) => (
      <>
        <DrawerTransition deps={transitionDeps} className={styles.transition} open={open} aria-hidden={false}>
          <Disclosure.Panel
            static
            className={clsx(styles.asideBlock, panelClassName, {
              [styles.open]: open,
            })}
          >
            {children}
          </Disclosure.Panel>
        </DrawerTransition>
        <Disclosure.Button
          onClick={() => onToggle(!open)}
          className={clsx(styles.toggle, toggleClassName, {
            [styles.open]: open,
          })}
        >
          {open && openedLabel}
          {open && <ArrowUpIcon className={styles.icon} color="on-dark" />}
          {!open && closedLabel}
          {!open && <ArrowDownIcon className={styles.icon} color="on-dark" />}
        </Disclosure.Button>
      </>
    )}
  </Disclosure>
);

export default Aside;
