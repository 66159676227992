import { backend } from "src/constants";

export const OSFileExtensionsPairs = {
  Macintosh: {
    displayName: "Mac OS Intel Chip (.dmg)",
    fileExtension: "dmg",
  },
  MacintoshAppleSilicon: {
    displayName: "Mac OS Apple Silicon (.dmg)",
    fileExtension: "-arm64.dmg",
  },
  Windows: {
    displayName: "Windows x64 (.exe)",
    fileExtension: "exe",
  },
  Ubuntu: {
    displayName: "Linux x64 (Snapcraft)",
    fileExtension: "amd64.snap",
  },
  CentOS: {
    displayName: "Linux x64 (.rpm)",
    fileExtension: "x86_64.rpm",
  },
  Debian: {
    displayName: "Linux x64 (.deb)",
    fileExtension: "amd64.deb",
  },
  Linux: {
    displayName: "Linux x64 (AppImage)",
    fileExtension: "x86_64.AppImage",
  },
  Unknown: {
    displayName: "Others",
    fileExtension: null,
  },
} as const;

export const channel = "latest";
export const binaryInfoRootUrl = backend;
export const binaryYAMLEndpoint = `${binaryInfoRootUrl}/binaries/${channel}.yml`;
export const installationPage = "https://k8slens.dev";
export const binaries = Object.values(OSFileExtensionsPairs).map(({ displayName, fileExtension }) => {
  // If extension starts with "-", e.g. "-arm64.dmg", separator is not needed
  const extensionSeparator = String(fileExtension).startsWith("-") ? "" : ".";

  return {
    displayName,
    fileExtension,
    url: fileExtension
      ? `${binaryInfoRootUrl}/binaries/${channel}${extensionSeparator}${fileExtension}`
      : installationPage,
  };
});

export type OperatingSystems = keyof typeof OSFileExtensionsPairs;
export type FileExtensions = (typeof OSFileExtensionsPairs)[OperatingSystems]["fileExtension"];
export type OperatingSystemDisplayName = (typeof OSFileExtensionsPairs)[OperatingSystems]["displayName"];
