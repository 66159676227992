import { useCallback, useEffect, useRef, useState } from "react";
import { LensPlatformClient, LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { useAnalytics } from "src/hooks/useAnalytics";

type ActionResponse = Awaited<ReturnType<LensPlatformClient["business"]["deleteInvitation"]>>;

export const useDeleteBusinessInvitation = () => {
  const lensPlatformClient = useLensPlatformClient();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<ActionResponse | void>();
  const [error, setError] = useState<LensSDKException>();
  const { track, trackError } = useAnalytics();

  // Keep up with mounted/unmounted state
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const deleteBusinessInvitation = useCallback(
    async (businessId: string, businessInvitationId: string, isActivationLinkInvitation?: boolean) => {
      let lensSDKException: LensSDKException | undefined;

      try {
        if (mounted.current) {
          setError(undefined);
          setLoading(true);
        }
        const response = isActivationLinkInvitation
          ? await lensPlatformClient.business.disableBusinessLightActivationLink(businessId)
          : await lensPlatformClient.business.deleteInvitation(businessId, businessInvitationId);

        if (mounted.current) {
          track("Delete business invitation");

          if (response) {
            setResponse(response);
          }
        }
      } catch (exception) {
        if (exception instanceof LensSDKException) {
          trackError("Delete business invitation failed");
          setError(exception);
          lensSDKException = exception;
        }
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }

      return lensSDKException;
    },
    [lensPlatformClient.business, track, trackError],
  );

  return {
    deleteBusinessInvitation,
    error,
    loading,
    response,
  };
};
