import { useState } from "react";
import type { LensSDKException } from "lens-platform-sdk";
import { Button, Modal } from "@k8slens/lds";

import { useAnalytics } from "src/hooks/useAnalytics";
import type { NewBusiness } from "src/hooks/useCreateBusiness";

import BusinessIdForm from "src/components/BusinessIdForm/BusinessIdForm";

import styles from "./CreateBusinessModal.module.css";

const backButtonLabel = "← back";
const createNewButtonLabel = "Create Lens Business ID";

interface Props {
  open: boolean;
  loading: boolean;
  disabled: boolean;
  error?: LensSDKException | null;
  onModalClose: () => void;
  onClickCreate: (newAccount: Partial<NewBusiness>) => void;
}

const CreateBusinessModal = ({ onModalClose, onClickCreate, open, loading, error, disabled }: Props) => {
  const { trackButtonClicked } = useAnalytics();
  const [newBusiness, setNewBusiness] = useState<Partial<NewBusiness>>();
  const [formValid, setFormValid] = useState<boolean>(false);

  return (
    <Modal
      title="Create Lens Business ID"
      subTitle="Provide your new Lens Business ID details."
      size="md"
      onClose={() => {
        if (!loading) {
          onModalClose();
        }
      }}
      contentComponent="div"
      isOpen={open}
      footer={
        <>
          <button
            aria-label={backButtonLabel}
            type="button"
            onClick={() => {
              if (!loading) {
                trackButtonClicked(backButtonLabel);
                onModalClose();
              }
            }}
            className={styles.backButton}
          >
            {backButtonLabel}
          </button>
          <Button
            loading={loading}
            label={createNewButtonLabel}
            id="createBusinessButton"
            buttonType="submit"
            primary
            onClick={() => {
              if (newBusiness) {
                trackButtonClicked(createNewButtonLabel);
                onClickCreate(newBusiness);
              }
            }}
            disabled={disabled || formValid === false}
          />
        </>
      }
    >
      <BusinessIdForm
        title="Provide your new Lens Business ID details."
        onChange={(newBusiness, valid) => {
          setNewBusiness(newBusiness);
          setFormValid(valid);
        }}
        error={error}
      />
    </Modal>
  );
};

export default CreateBusinessModal;
