import { useMemo } from "react";
import type { BusinessSubscription } from "lens-platform-sdk";

import { trimUUID } from "src/services/trimUUID";
import { getPlanName } from "src/services/plans";

import styles from "src/components/Business/SubscriptionSelectContent.module.css";
import { getSubscriptionSeatCount } from "src/services/getSubscriptionSeatCount";

interface Props {
  id: BusinessSubscription["id"];
  businessSubscriptions: BusinessSubscription[];
}

export const SubscriptionSelectContent = ({ id, businessSubscriptions }: Props) => {
  const businessSubscription = useMemo(
    () => businessSubscriptions.find((subscription) => subscription.id === id),
    [id, businessSubscriptions],
  );
  const seatCount = getSubscriptionSeatCount(businessSubscription);

  if (!businessSubscription) {
    return null;
  }

  return (
    <div className={styles.selectOptionLayout}>
      <div className={styles.planNameIdWrapper}>
        <span className={styles.planName}>{getPlanName(businessSubscription.planCode)}</span>
        <span className={styles.id}>{trimUUID(id)}</span>
      </div>
      <span className={styles.availableSeats}>{seatCount.available} Seat(s) Available</span>
    </div>
  );
};
