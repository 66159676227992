import LinkButton from "src/components/Button/TrackedRouterButton";
import styles from "./NoSubscriptions.module.css";

export function NoSubscriptions() {
  return (
    <div className={styles.noSubscriptions}>
      <div>
        <p className="mb-6 text-center">No active Lens Subscription for your Lens ID!</p>
        <LinkButton to="/subscribe" className={styles.addSubscription} primary label="Create New Subscription" />
      </div>
    </div>
  );
}
