import { useEffect, useMemo, useState } from "react";
import copy from "copy-to-clipboard";
import { useAnalytics } from "src/hooks/useAnalytics";
import { useHistory, useParams } from "react-router-dom";

import { TabList, TabGroup, Tab, TabPanel, TabPanels } from "src/components/Tabs/Tabs";

import styles from "src/components/Business/Children.module.css";
import { useBusinessChildren } from "src/hooks/useBusinessChildren";
import { useBusinessChildInvitation } from "src/hooks/useBusinessChildInvitation";
import { ButtonBar, Notification } from "@k8slens/lds";
import TrackedButton from "src/components/Button/TrackedButton";
import { UnprocessableEntityException } from "lens-platform-sdk";
import { ActiveChildrenTable } from "./ActiveChildrenTable";
import { PendingChildrenTable } from "./PendingChildrenTable";

export const Children = () => {
  const { trackButtonClicked } = useAnalytics();
  const [tabIndex, setTabIndex] = useState<number>();

  const { businessId } = useParams<{ businessId: string }>();
  const history = useHistory();

  const { businessChildren } = useBusinessChildren(businessId);
  const {
    businessChildrenInvitations,
    createChildInvitation,
    creatingChildInvitation,
    errorCreatingChildInvitation,
    newlyCreatedChildInvitation,
  } = useBusinessChildInvitation(businessId);

  const numberOfActiveChildren = useMemo(() => businessChildren?.length ?? 0, [businessChildren]);
  const numberOfPendingChildren = useMemo(
    () => businessChildrenInvitations?.filter?.((invitation) => invitation.state === "pending").length ?? 0,
    [businessChildrenInvitations],
  );

  const tabs = useMemo(
    () => [
      { label: `Active (${numberOfActiveChildren})`, key: "active" },
      { label: `Pending (${numberOfPendingChildren})`, key: "pending" },
    ],
    [numberOfActiveChildren, numberOfPendingChildren],
  );

  const handleChange = (index: number) => {
    setTabIndex(index);
    trackButtonClicked(tabs[index]?.label, { type: "tab" });
  };

  const invitationLink = `${window.location.origin}/business-child-invitation?businessChildInvitationToken=${newlyCreatedChildInvitation?.token}`;

  useEffect(() => {
    if (newlyCreatedChildInvitation?.token) {
      copy(invitationLink);
      setTabIndex(1);
    }
  }, [invitationLink, newlyCreatedChildInvitation?.token]);

  const parentHasNoBillingInfo = useMemo(() => {
    if (errorCreatingChildInvitation && errorCreatingChildInvitation instanceof UnprocessableEntityException) {
      return true;
    }

    return false;
  }, [errorCreatingChildInvitation]);

  return (
    <>
      <section className={styles.childrenTable}>
        <TabGroup defaultIndex={0} selectedIndex={tabIndex} onChange={handleChange}>
          <div className={styles.tableTab}>
            <span className={styles.tableSubTitle}>
              Children Lens Business ID billed under the parent Lens Business ID
            </span>
            <TabList className={styles.tableTabButtonBar}>
              {tabs.map((tab) => (
                <Tab key={tab.key} label={tab.label} />
              ))}
            </TabList>
          </div>
          {newlyCreatedChildInvitation?.token && invitationLink ? (
            <div className="mt-2 mb-6">
              <Notification
                level="success"
                message={
                  <span>
                    Invitation link <a href={invitationLink}>{invitationLink}</a> copied to clipboard
                  </span>
                }
              />
            </div>
          ) : null}
          <TabPanels>
            <TabPanel>
              <ActiveChildrenTable />
            </TabPanel>
            <TabPanel>
              <PendingChildrenTable />
            </TabPanel>
          </TabPanels>
        </TabGroup>
        {errorCreatingChildInvitation ? (
          <div
            className="mt-1 mb-1"
            onClick={() => {
              if (parentHasNoBillingInfo) {
                history.push(`/business/${businessId}/billing-details`);
              }
            }}
          >
            <Notification level="error" message={errorCreatingChildInvitation.message} type="closable" />
          </div>
        ) : null}
        <ButtonBar type="grid" className={styles.buttonBar}>
          <TrackedButton
            label={parentHasNoBillingInfo ? "Add Billing Info" : "Create Invitation"}
            onClick={() => {
              if (parentHasNoBillingInfo) {
                history.push(`/business/${businessId}/billing-details`);
              } else {
                createChildInvitation(businessId);
              }
            }}
            primary
            loading={creatingChildInvitation}
          />
        </ButtonBar>
        <p className={styles.helpText}>
          Use the generated links to securely invite Lens Business ID to be children accounts.
        </p>
      </section>
    </>
  );
};
