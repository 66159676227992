import { useBusinessUsers } from "./useBusinessUsers";
import { useUserId } from "./useUserId";

export const useHasInvitedUser = (businessId: string) => {
  const { userId, loading: loadingUserId } = useUserId();
  const { businessUsers, loading: loadingBusinessUsers, error } = useBusinessUsers(businessId);

  return {
    hasInvitedUser: businessUsers.some((user) => user.id !== userId),
    loading: loadingUserId || loadingBusinessUsers,
    error,
  };
};
