import { useBillingPageUrl, useBusinessBillingPageUrl } from "src/hooks/useBillingPageUrl";
import { useGetBusinesses } from "src/hooks/useGetBusinesses";

export function useBusinessInfo(businessId: string | undefined, username: string | undefined) {
  const { businesses, loading: loadingBusinesses, reload: reloadBusinesses } = useGetBusinesses();

  const business = businesses.find(({ id }) => id === businessId);

  const isBusinessID = Boolean(businessId);
  const isExternal = business?.external === true;

  const { accountUrl: userAccountUrl } = useBillingPageUrl(businessId ? undefined : username);
  const { accountUrl: businessAccountUrl } = useBusinessBillingPageUrl(businessId);
  const accountUrl = businessAccountUrl || userAccountUrl;

  const showBillingDetails = Boolean(!businessId && (accountUrl || isExternal));

  return { isBusinessID, isExternal, showBillingDetails, loadingBusinesses, reloadBusinesses, businesses, accountUrl };
}
