import { useBusinessSSO } from "./useBusinessSSO";

export const useIsSSOActive = (businessId: string) => {
  const { businessSSO, loadingBusinessSSO, errorLoadingBusinessSSO } = useBusinessSSO(businessId);

  if (businessSSO?.config) {
    return {
      isSSOActive: true,
      loading: loadingBusinessSSO,
      error: errorLoadingBusinessSSO,
    };
  }

  return {
    isSSOActive: false,
    loading: loadingBusinessSSO,
    error: errorLoadingBusinessSSO,
  };
};
