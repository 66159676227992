import { useState, useEffect, useCallback } from "react";
import type { BusinessSubscription, LensSDKException, UserBusinessRole } from "lens-platform-sdk";
import { Modal, Notification } from "@k8slens/lds";
import { FormField } from "@k8slens/lds-form";

import { useAnalytics } from "src/hooks/useAnalytics";
import { getUniqueEmailsArray, validateEmail } from "src/services/email";

import TrackedButton from "src/components/Button/TrackedButton";

import { RoleOption } from "./role";
import styles from "./AddUsersModal.module.css";
import { RoleSelect } from "./RoleSelect";

interface Props {
  open: boolean;
  adding: boolean;
  errorAdding?: LensSDKException | null;
  loadingBusinessInvitations: boolean;
  onModalClose: () => void;
  addUsers: (
    emails: string,
    subscriptionId: string | undefined,
    role: UserBusinessRole,
  ) => Promise<LensSDKException | null>;
}

/**
 * The shape of the option data in the business subscription selector
 */
export interface SubscriptionOption {
  id: BusinessSubscription["id"];
  label: string;
}

const cancelButtonLabel = "Cancel";
const title = "Invite Users";

const emailRequiredMessage = "Required";
const emailInvalidMessage = "Invalid email address(es)";

const addButtonLabel = "Invite";

export const AutomaticSeatAssignmentModal = ({
  open,
  adding,
  errorAdding,
  loadingBusinessInvitations,
  onModalClose,
  addUsers,
}: Props) => {
  const { trackButtonClicked } = useAnalytics();

  const [selectedRole, setSelectedRole] = useState<RoleOption | undefined>({ id: "Member", label: "Member" });

  const [emails, setEmails] = useState<string>("");
  const [emailsValid, setEmailsValid] = useState<boolean>(false);
  const [emailsErrorMessage, setEmailsErrorMessage] = useState<string>("");

  /**
   * Return the errorMessage if the emails are invalid or undefined if valid
   */
  const emailsValidate = useCallback((emails: string) => {
    // emails is a empty string
    if (emails.length === 0) {
      return emailRequiredMessage;
    }

    const emailsArray = getUniqueEmailsArray(emails);
    const isAllEmailValid = emailsArray.every(validateEmail);

    if (!isAllEmailValid) {
      return emailInvalidMessage;
    }

    return undefined;
  }, []);

  useEffect(() => {
    const result = emailsValidate(emails);

    setEmailsValid(result === undefined);
    setEmailsErrorMessage(result ?? "");
  }, [emails, emailsValidate]);

  /**
   * Reset state
   */
  const reset = useCallback(() => {
    setEmails("");
    setEmailsValid(false);
    setEmailsErrorMessage("");
  }, []);

  /**
   * Actions
   */
  const handleClickAdd = useCallback(async () => {
    trackButtonClicked(addButtonLabel);

    if (!selectedRole) {
      throw new Error("Role is not selected");
    }

    const exception = await addUsers(emails, undefined, selectedRole?.id);

    if (!exception) {
      reset();
    }
  }, [trackButtonClicked, selectedRole, addUsers, emails, reset]);

  const chooseRole = useCallback(
    (option: RoleOption | undefined) => {
      if (!option) {
        return;
      }
      setSelectedRole(option);
    },
    [setSelectedRole],
  );

  return (
    <Modal
      title={title}
      size="md"
      contentProps={{
        className: styles.addUsersModalContent,
      }}
      onClose={() => {
        if (!adding) {
          reset();
          onModalClose();
        }
      }}
      isOpen={open}
      buttonBarProps={{
        type: "grid",
        gridSize: 3,
      }}
      footer={
        <>
          <button
            aria-label={cancelButtonLabel}
            type="button"
            onClick={() => {
              if (!adding) {
                trackButtonClicked(cancelButtonLabel);
                onModalClose();
                reset();
              }
            }}
            className="lds-button"
          >
            {cancelButtonLabel}
          </button>
          <div />
          <TrackedButton
            loading={adding || loadingBusinessInvitations}
            label={addButtonLabel}
            buttonType="submit"
            primary
            onClick={handleClickAdd}
            disabled={emailsValid === false}
          />
        </>
      }
    >
      <form aria-label={title} autoComplete="off">
        {errorAdding ? (
          <Notification
            level="error"
            message={errorAdding?.message ?? "unknown"}
            type="flash"
            className={styles.errorNotification}
          />
        ) : null}
        <fieldset>
          <RoleSelect value={selectedRole} onChange={chooseRole} />
          <FormField
            id="emails"
            errorId="emailErrors"
            labelId="emailLabel"
            label="Invite users to your Lens Business ID"
            errors={emailsErrorMessage ? [emailsErrorMessage] : []}
          >
            <textarea
              value={emails}
              onChange={({ target: { value } }) => {
                setEmails(value);
              }}
              autoComplete="off"
              name="user-emails"
              placeholder="Comma separated list of Email addresses"
              className={styles.emailTextarea}
              spellCheck="false"
            />
          </FormField>
        </fieldset>
      </form>
    </Modal>
  );
};
