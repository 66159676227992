import type { BusinessInvitation, UserBusinessRole } from "lens-platform-sdk";
import { type BusinessUserWithSeats } from "lens-platform-sdk/dist/cjs/BusinessService";
import { LensSDKException } from "lens-platform-sdk";
import { useMutation, useQuery } from "@tanstack/react-query";

import { queryClient } from "src/queryClient";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { useAnalytics } from "src/hooks/useAnalytics";

type NewBusinessInvitation = {
  id: BusinessInvitation["id"];
  email: BusinessInvitation["email"];
  subscriptionId: BusinessInvitation["subscriptionId"];
  role: UserBusinessRole;
};

function useBusinessUsers(businessId?: string, role?: UserBusinessRole) {
  const { track } = useAnalytics();
  const lensPlatformClient = useLensPlatformClient();

  /**
   * Get users for a business by role and by id
   */
  const {
    isLoading: loading,
    error,
    data: businessUsers,
    refetch,
  } = useQuery<BusinessUserWithSeats[], LensSDKException>({
    queryKey: ["businessUsers", businessId, role],
    queryFn: async () => {
      if (!businessId) {
        return [];
      }
      const users = await lensPlatformClient.business.getUsers(businessId);

      if (role) {
        return users.filter((user) => user.role === role);
      }

      return users;
    },
    initialData: [],
  });

  /**
   * Create Business User Invites
   */
  const {
    isPending: creatingInvites,
    isSuccess: createInvitesSuccess,
    error: errorCreatingInvites,
    mutate: createInvites,
    mutateAsync: createInvitesAsync,
  } = useMutation<
    Array<NewBusinessInvitation>,
    LensSDKException,
    Array<Pick<BusinessInvitation, "email" | "role" | "subscriptionId">>
  >({
    mutationFn: async (arr) => {
      if (!businessId) {
        return Promise.reject(new Error("Invalid Lens Business ID"));
      }

      const users = Promise.all(
        arr.map(({ email, role, subscriptionId }) =>
          lensPlatformClient.business.createInvitation(businessId, email, subscriptionId, role),
        ),
      );

      return users;
    },

    onSuccess: async () => {
      track("Business ,user created", { businessId });

      await queryClient.invalidateQueries({ queryKey: ["businessUsers", businessId] });
    },
  });

  return {
    loading,
    error,
    businessUsers,
    reload: refetch,
    // Create (multiple invites)
    createInvites,
    createInvitesAsync,
    creatingInvites,
    createInvitesSuccess,
    errorCreatingInvites,
  };
}

export { useBusinessUsers };
