import { XMLParser } from "fast-xml-parser";
import { z } from "zod";

const KeyDescriptor = z.object({
  KeyInfo: z.object({
    X509Data: z.object({
      X509Certificate: z.object({
        "#text": z.string(),
      }),
    }),
  }),
});

const idpMetadataSchema = z.object({
  EntityDescriptor: z.object({
    entityID: z.string(),
    IDPSSODescriptor: z.object({
      SingleSignOnService: z.union([
        z.array(
          z.object({
            Location: z.string(),
          }),
        ),
        z.object({
          Location: z.string(),
        }),
      ]),
      KeyDescriptor: KeyDescriptor.or(z.array(KeyDescriptor)),
    }),
  }),
});

// Parse XML to JS object
const parser = new XMLParser({
  ignoreAttributes: false,
  attributesGroupName: "",
  attributeNamePrefix: "",
  removeNSPrefix: true,
  alwaysCreateTextNode: true,
});

/**
 * Parse the base 64 string from `FileReader.readAsDataURL()`
 * https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
 */
export const base64ToXMLString = (fileString: string) => {
  // parse if the xml is in data:text/xml;base64,PD94bWwgdmVyc2...bas64test...
  if (fileString.includes("data:text/xml") && fileString.includes("base64")) {
    // XML string is in the following format:
    const xmlContent = fileString.split(";");
    // Get base64 string part PD94bWwgdmVyc2...bas64test...
    const base64 = xmlContent[1] ? xmlContent[1].split(",") : [];

    // Decode base64 to xml text
    return atob(base64[1]);
  } else {
    throw new Error("file string not include data:text/xml and base64");
  }
};

/**
 * Returns SAML Identity Provider details from SAML XML file:
 * File examples: https://en.wikipedia.org/wiki/SAML_metadata#SAML_metadata_examples
 *
 * @throws if XML is invalid
 */
export const getIdentityProviderDetailsFromXML = (
  xmlString: string,
):
  | {
      idpEntityId: string;
      singleSignOnServiceUrl: string;
    }
  | never => {
  const metadata = idpMetadataSchema.parse(parser.parse(xmlString));
  const idpEntityId = metadata.EntityDescriptor.entityID;
  const singleSignOnService = metadata.EntityDescriptor.IDPSSODescriptor.SingleSignOnService;

  if (Array.isArray(singleSignOnService)) {
    return {
      idpEntityId,
      singleSignOnServiceUrl: singleSignOnService[0].Location,
    };
  } else {
    return {
      idpEntityId,
      singleSignOnServiceUrl: singleSignOnService.Location,
    };
  }
};
