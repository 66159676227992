import { ReactNode } from "react";
import clsx from "clsx";
import { flexRender, Row, Cell } from "@tanstack/react-table";

import styles from "./Table.module.css";

const renderTableCell = function <TData>({
  cell,
  className = "",
  responsive,
  renderCell,
  renderCellHeaderCell,
}: {
  cell: Cell<TData, unknown>;
  className: string;
  responsive: boolean;
  renderCell(cell: Cell<TData, unknown>): ReactNode;
  renderCellHeaderCell(cell: Cell<TData, unknown>): ReactNode;
}) {
  let Component = "td";
  const { meta } = cell.column.columnDef;

  const props: { key: string; className: string; scope?: "row" } = {
    key: cell.id,
    className: clsx(className, {
      [styles.center]: meta?.align === "center",
      [styles.end]: meta?.align === "end",
      [styles.textAccent]: meta?.text === "accent",
      [styles.textSecondary]: meta?.text === "secondary",
      [styles.textTertiary]: meta?.text === "tertiary",
      [styles.textQuarternary]: meta?.text === "quarternary",
      [styles.textDefault]: meta?.text === "default",
    }),
  };

  if (cell.column.columnDef.meta?.primary) {
    Component = "th";
    props.scope = "row";
  }

  return (
    <Component
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {responsive && (
        <div aria-hidden className={styles.cellHeader}>
          {renderCellHeaderCell(cell)}
        </div>
      )}
      {renderCell(cell)}
    </Component>
  );
};

export type TableRowsProps<TData> = {
  className?: string;
  responsive?: boolean;
  cellClassName?: string;
  rows: Array<Row<TData>>;
  renderCell?(cell: Cell<TData, unknown>): ReactNode;
  renderCellHeaderCell?(cell: Cell<TData, unknown>): ReactNode;
};

const TableRows = function <TData>({
  rows,
  renderCell = (cell) => flexRender(cell.column.columnDef.cell, cell.getContext()),
  renderCellHeaderCell = (cell) =>
    flexRender(
      cell.column.columnDef.header,
      {} as any, // TODO: Find a way to get context
    ),
  className = "",
  cellClassName = "",
  responsive = true,
}: TableRowsProps<TData>) {
  return (
    <>
      {rows.map((row) => (
        <tr key={row.id} className={clsx(styles.tableRow, className)}>
          {row.getVisibleCells().map((cell) =>
            renderTableCell({
              cell,
              className: clsx(styles.tableCell, cellClassName),
              renderCell,
              renderCellHeaderCell,
              responsive,
            }),
          )}
        </tr>
      ))}
    </>
  );
};

export default TableRows;
