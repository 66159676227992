import { useCallback, useContext, useMemo, useState } from "react";
import { createColumnHelper, useReactTable, getCoreRowModel, getSortedRowModel, Row } from "@tanstack/react-table";
import { type BusinessUserWithSeats } from "lens-platform-sdk/dist/cjs/BusinessService";
import { type BusinessSubscription } from "lens-platform-sdk";
import { Lozenge } from "@k8slens/lds";
import { misc } from "@k8slens/lds-icons";

import { useTrackedTableSort } from "src/hooks/useTrackedTableSort";

import { getUserAvatar } from "src/utils/user";
import { getUserFullname } from "src/services/getUserFullname";

import Table from "src/components/Table/Table";
import { AvatarTableCell, PendingUserTableCell } from "src/components/TableCells/AvatarTableCell";
import DateCell, { dateColDef, dateColSortingFn, getDate } from "src/components/TableCells/DateCell";
import { getPlanName } from "src/services/plans";
import styles from "./Users.module.css";
import { trimUUID } from "src/services/trimUUID";
import { dateToString } from "src/utils/date";
import { contextMenuColDef } from "src/components/TableCells/ContextMenuCell";

import { UserContextMenu } from "./UserContextMenu";
import { getUserSubscriptions } from "src/services/getUserSubscription";
import { AssignSubscriptionModal } from "src/components/Business/AssignSubscriptionModal";
import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";
import { BusinessContext } from "src/components/Business/Base";
import { isAssignableSubscription } from "src/services/getSubscriptionState";
import { useActivateBusinessUserSubscription } from "src/hooks/useActivateBusinessUserSubscription";
import { nonExpiredSeats } from "src/services/nonExpiredSeats";
import { lastestActivatedAtBusinessUserSubscriptionSeat } from "src/services/lastestActivatedAtSeat";
import { trimEmail } from "src/services/trimEmail";

const { CreditCardOffIcon } = misc;

const tableColumnHelper = createColumnHelper<BusinessUserWithSeats>();

interface Props {
  businessUsers: Array<BusinessUserWithSeats>;
  loading?: boolean;
  notDataText: string;
  caption: string;
}

const isOfflineSeat = (d: Row<BusinessUserWithSeats>) => {
  const { seats } = d.original;

  return seats.some((seat) => seat.active && seat.offline);
};

const Users = ({ businessUsers, notDataText, caption }: Props) => {
  const { businessId } = useContext(BusinessContext);
  const { businessSubscriptions, loading: loadingBusinessSubscriptions } = useGetBusinessSubscriptions(businessId);
  const assignableBusinessSubscriptions = useMemo(
    () => businessSubscriptions.filter(isAssignableSubscription),
    [businessSubscriptions],
  );
  const [assignSubscriptionModalOpen, setAssignSubscriptionModalOpen] = useState(false);
  const [assigneeId, setAssigneeId] = useState<string | undefined>(undefined);

  const openAssignSubscriptionModal = useCallback((userId: string) => {
    setAssigneeId(userId);
    setAssignSubscriptionModalOpen(true);
  }, []);
  const closeAssignSubscriptionModal = useCallback(() => {
    setAssignSubscriptionModalOpen(false);
  }, []);
  const {
    activate,
    loading: activating,
    error: activationError,
  } = useActivateBusinessUserSubscription(businessId, () => {
    closeAssignSubscriptionModal();
  });

  const onClickAssign = useCallback(
    (selectedSubscription: BusinessSubscription) => {
      if (!businessId) {
        throw new Error("Business ID is missing");
      }

      if (!assigneeId) {
        throw new Error("Assignee is missing from business");
      }

      const businessSubscriptionId = selectedSubscription.id;

      activate({ businessId, businessSubscriptionId, userId: assigneeId });
    },
    [activate, assigneeId, businessId],
  );

  const tableColumns = [
    tableColumnHelper.accessor("username", {
      header: () => <span>Lens ID</span>,
      cell: ({ row, getValue }) => {
        const username = getValue();
        const user = row.original;
        const { email } = user;
        const userAvatar = getUserAvatar(user);
        const role = user.role === "Administrator" ? "Admin" : undefined;

        // User is invited to the business, but not yet have a Lens ID account
        const userNotHaveAccount = email && !username;

        if (userNotHaveAccount) {
          return <PendingUserTableCell email={email} />;
        }

        const fullname = getUserFullname(user);

        return <AvatarTableCell image={userAvatar} name={username ?? ""} subtitle={fullname} role={role} />;
      },
      meta: { primary: true },
    }),
    tableColumnHelper.accessor("email", {
      header: () => <span>Email</span>,
      cell: ({ getValue }) => {
        const email = getValue();

        return (
          <address title={email}>
            <a href={`mailto:${email}`} className={styles.emailLink}>
              {trimEmail(email)}
            </a>
          </address>
        );
      },
    }),
    tableColumnHelper.accessor("lastAccess", {
      ...dateColDef,
      sortingFn: (row1, row2, key) => {
        const firstOffline = isOfflineSeat(row1);
        const secondOffline = isOfflineSeat(row2);

        if (!firstOffline && secondOffline) {
          return row1.original.lastAccess ? 1 : -1;
        } else if (firstOffline && !secondOffline) {
          return row2.original.lastAccess ? -1 : 1;
        }

        return dateColSortingFn(row1, row2, key);
      },
      cell: (props) => {
        if (isOfflineSeat(props.row)) {
          return (
            <div>
              <Lozenge textTransform="uppercase">Offline</Lozenge>
            </div>
          );
        }

        return <DateCell {...props} />;
      },
      header: () => <span>Last Seen</span>,
    }),
    tableColumnHelper.accessor(
      (row) => {
        const noExpiredSeats = row.seats.filter(nonExpiredSeats);
        const latestActivatedAtSeat = noExpiredSeats.reduce(
          lastestActivatedAtBusinessUserSubscriptionSeat,
          noExpiredSeats[0],
        );

        return latestActivatedAtSeat;
      },
      {
        id: "subscriptions",
        header: () => <span className={styles.subscriptionHeader}>Subscription</span>,
        cell: ({ getValue }) => {
          const latestActivatedAtSeat = getValue();

          if (!latestActivatedAtSeat) {
            return null;
          }

          const businessSubscription = businessSubscriptions.find(
            ({ id }) => id === latestActivatedAtSeat?.subscription?.id,
          );

          if (!businessSubscription) {
            return null;
          }
          const isCanceled = businessSubscription.state === "canceled";

          return (
            <div
              className={styles.subscriptionPlanNameIdWrapper}
              title={
                isCanceled
                  ? `The subscription is valid until ${
                      businessSubscription.currentPeriodEndsAt
                        ? dateToString(new Date(businessSubscription.currentPeriodEndsAt))
                        : ""
                    }`
                  : ""
              }
            >
              {isCanceled ? <CreditCardOffIcon className={styles.stopIcon} /> : null}
              <span className={styles.planName}>{getPlanName(businessSubscription.planCode)}</span>
              <span className={styles.id}>{trimUUID(businessSubscription.id)}</span>
            </div>
          );
        },
      },
    ),
    tableColumnHelper.accessor(
      (row) => {
        const noExpiredSeats = row.seats.filter(nonExpiredSeats);
        const latestActivatedAtSeat = noExpiredSeats.reduce(
          lastestActivatedAtBusinessUserSubscriptionSeat,
          noExpiredSeats[0],
        );

        return latestActivatedAtSeat;
      },
      {
        id: "activationDate",
        header: () => <span>Activation Date</span>,
        sortingFn: (row1, row2) => {
          const date1 = getDate(row1.original.seats[row1.original.seats.length - 1]?.activatedAt);
          const date2 = getDate(row2.original.seats[row2.original.seats.length - 1]?.activatedAt);

          if (!date1 && !date2) {
            return 0; // Both dates are missing, consider them equal
          } else if (!date1) {
            return 1; // Only 'a' date is missing, 'b' comes first
          } else if (!date2) {
            return -1; // Only 'b' date is missing, 'a' comes first
          }

          return date2 - date1;
        },
        cell: ({ getValue }) => {
          const latestActivatedAtSeat = getValue();

          if (!latestActivatedAtSeat) {
            return null;
          }

          return <DateCell getValue={() => latestActivatedAtSeat?.activatedAt} />;
        },
      },
    ),
    tableColumnHelper.accessor("id", {
      ...contextMenuColDef,
      cell: ({ row }) => {
        const user = row.original;
        const businessHasOnlyOneAdmin = businessUsers.filter(({ role }) => role === "Administrator").length <= 1;
        const businessHasAvailableSeats = businessSubscriptions.some(({ state, seats, usedSeats }) => {
          if ((state === "active" || state === "canceled") && usedSeats.length < seats) {
            return true;
          }

          return false;
        });
        const userSubscriptions = user.id ? getUserSubscriptions(businessSubscriptions, user.id) : [];

        return (
          <UserContextMenu
            user={row.original}
            userSubscriptions={userSubscriptions}
            businessHasOnlyOneAdmin={businessHasOnlyOneAdmin}
            businessHasAvailableSeats={businessHasAvailableSeats}
            openAssignSubscriptionModal={openAssignSubscriptionModal}
          />
        );
      },
    }),
  ];
  const { sorting, onSortingChange } = useTrackedTableSort({
    table: caption,
    initialSort: [{ id: "username", desc: false }],
  });

  const table = useReactTable({
    data: businessUsers,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange,
  });
  const rowModel = table.getRowModel();
  const rows = useMemo(() => rowModel.rows, [rowModel]);

  return (
    <>
      <Table<BusinessUserWithSeats>
        caption={caption}
        loading={loadingBusinessSubscriptions}
        rows={rows}
        columns={table.getAllColumns()}
        headerGroups={table.getHeaderGroups()}
        noDataText={notDataText}
      />
      <AssignSubscriptionModal
        open={assignSubscriptionModalOpen}
        onModalClose={closeAssignSubscriptionModal}
        onClickAssign={onClickAssign}
        businessSubscriptions={assignableBusinessSubscriptions}
        businessInvitations={[]}
        loadingBusinessSubscriptions={loadingBusinessSubscriptions}
        loadingBusinessInvitations={false}
        assigningSubscription={activating}
        assignSubscriptionError={activationError}
        disabled={false}
      />
    </>
  );
};

export default Users;
