import { AnchorHTMLAttributes, forwardRef, useEffect, useRef, useState } from "react";

import { useAnalytics } from "src/hooks/useAnalytics";

const TrackedAnchor = forwardRef<HTMLAnchorElement, AnchorHTMLAttributes<HTMLAnchorElement>>(
  ({ children, ...props }, ref) => {
    const anchorRer = useRef<HTMLAnchorElement | null>(null);
    const { trackAnchorElement } = useAnalytics();
    const [tracked, setTracked] = useState(false);

    useEffect(() => {
      if (!tracked && anchorRer?.current && anchorRer.current !== null) {
        // eslint-disable-next-line xss/no-mixed-html
        const response = trackAnchorElement(anchorRer.current, anchorRer.current.innerHTML);

        setTracked(response);
      }
    }, [tracked, anchorRer, trackAnchorElement]);

    return (
      <a
        ref={(node) => {
          anchorRer.current = node;

          if (typeof ref === "function") {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </a>
    );
  },
);

export default TrackedAnchor;
