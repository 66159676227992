import { useAuth } from "./useAuth";

export const useUserId = () => {
  const { initialized, tokenParsed } = useAuth();

  if (!initialized) {
    return {
      userId: undefined,
      loading: true,
    };
  }

  return {
    userId: tokenParsed?.sub,
    loading: false,
  };
};
