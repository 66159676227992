import { useMemo } from "react";
import clsx from "clsx";
import { navigation } from "@k8slens/lds-icons";

import Select from "src/components/Select/Select";

import { useBinaryInfo } from "src/hooks/useBinaryInfo";
import { binaryYAMLEndpoint } from "src/services/downloadURI";

import styles from "./LensDownloadSelect.module.css";
import { Listbox } from "@headlessui/react";
import { Button } from "@k8slens/lds";

const { ArrowDownIcon } = navigation;

type Option = {
  id: string;
  url: string;
  label: string;
};

const LensDownloadSelect = () => {
  const { binaryInfo } = useBinaryInfo(binaryYAMLEndpoint);

  const options: Array<Option> = useMemo(
    () =>
      binaryInfo?.binaries
        .map(({ displayName, url }) => ({ id: url, url, label: displayName }))
        .sort(({ label: a }, { label: b }) => {
          if (a === "Others") {
            return 1;
          }

          if (b === "Others") {
            return -1;
          }

          if (a === binaryInfo.recommended) {
            return -1;
          }

          if (b === binaryInfo.recommended) {
            return 1;
          }

          return a.localeCompare(b);
        }) || [],
    [binaryInfo],
  );

  return (
    // TODO: Maybe use Menu instead?
    <Select
      className={styles.binarySelect}
      onChange={(d) => {
        // eslint-disable-next-line xss/no-location-href-assign
        window.location.href = d.url;
      }}
      options={options}
      value={undefined as Option | undefined}
      loading={!binaryInfo}
      renderOption={(d) => (
        <div
          className={clsx({
            [styles.highlightedOption]: d.label === binaryInfo?.recommended,
          })}
        >
          {d.label}
        </div>
      )}
      renderButton={(item, open) => (
        <Listbox.Button as="div">
          <Button label="Install Lens Desktop" type="primary" disabled={open} className={styles.downloadButton}>
            <ArrowDownIcon className={styles.dropDownIcon} />
          </Button>
        </Listbox.Button>
      )}
      placeholder="Install Lens Desktop"
      inline
    />
  );
};

export default LensDownloadSelect;
