import { LensSDKException, NotFoundException, UserBusinessWithSSOInfo } from "lens-platform-sdk";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAnalytics } from "src/hooks/useAnalytics";
import { queryClient } from "src/queryClient";

/**
 * Get get user businesses
 */
export const useGetUserBusinesses = () => {
  const lensPlatformClient = useLensPlatformClient();
  const { track } = useAnalytics();

  const {
    isLoading: loadingUserBusinesses,
    error: errorLoadingUserBusinesses,
    data: userBusinesses,
    refetch: refetchUserBusinesses,
  } = useQuery<UserBusinessWithSSOInfo[], LensSDKException>({
    queryKey: ["user-businesses"],
    queryFn: async () => {
      try {
        const businesses = await lensPlatformClient.user.getUserBusinesses();

        track("Businesses retrieved for user");

        return businesses;
      } catch (error) {
        if (error instanceof NotFoundException) {
          track("Fail to load businesses for user");

          return [];
        }
        throw error;
      }
    },
    initialData: [],
  });

  const {
    isPending: deleteUserLinkedAccountLoading,
    error: errorDeleteUserLinkedAccount,
    mutate: deleteUserLinkedAccount,
  } = useMutation<void, LensSDKException, string>({
    mutationFn: async (identityProviderAlias: string) =>
      lensPlatformClient.user.deleteUserLinkedAccount(identityProviderAlias),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["user-businesses"] });
    },
  });

  return {
    userBusinesses,
    refetchUserBusinesses,
    loadingUserBusinesses,
    errorLoadingUserBusinesses,
    deleteUserLinkedAccountLoading,
    errorDeleteUserLinkedAccount,
    deleteUserLinkedAccount,
  };
};
