import clsx from "clsx";
import styles from "./PageHeader.module.css";
import { useMemo } from "react";

type Type = "default" | "caps" | "sub";

type Props = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  headingContent?: React.ReactNode;
  ingressContent?: React.ReactNode;
  id?: string;
  className?: string;
  type?: Type;
};

const PageHeader = ({ title, subtitle, headingContent, ingressContent, id, className, type = "default" }: Props) => {
  const Heading = type === "sub" ? "h3" : "h2";
  const headingClass = useMemo(() => {
    if (type === "sub") {
      return "lds-h4";
    }

    if (type === "caps") {
      return "lds-h2-caps";
    }

    return "lds-h2";
  }, [type]);

  return (
    <header
      id={id}
      className={clsx(
        styles.pageHeader,
        {
          [styles.subHeading]: type === "sub",
        },
        className,
      )}
    >
      <span className={styles.heading}>
        <Heading className={headingClass}>{title}</Heading>
        {headingContent}
      </span>
      {subtitle ? (
        <span className={styles.ingress}>
          <p>{subtitle}</p>
          {ingressContent}
        </span>
      ) : null}
    </header>
  );
};

export default PageHeader;
