import { useParams } from "react-router-dom";
import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { useEffect, useState } from "react";
import { misc } from "@k8slens/lds-icons";

import { useIsEnterpriseFeaturesEnabled } from "src/hooks/useIsEnterpriseFeaturesEnabled";
import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";
import { useBusinessSSO } from "src/hooks/useBusinessSSO";

import styles from "src/components/Business/Auth.module.css";
import PageHeader from "src/components/PageHeader/PageHeader";
import PageSection from "src/components/PageSection/PageSection";

import { SCIMTokens } from "./SCIMTokens";
import { SingleSignOnConfig } from "./SingleSignOnConfig";
import { SCIMSwitch } from "./SCIMSwitch";
import { SSOSwitch } from "./SSOSwitch";

const VerifiedIcon = misc.VerifiedIcon;

export const BusinessAuth = () => {
  const { businessId } = useParams<{ businessId?: string }>();
  const { businessSubscriptions } = useGetBusinessSubscriptions(businessId);
  const { enterpriseFeaturesEnabled } = useIsEnterpriseFeaturesEnabled(businessSubscriptions);
  const { businessSSO } = useBusinessSSO(businessId);
  const hasSSOConfig = Boolean(businessSSO?.config);
  const scimflag = useBooleanFlagValue("scim-token-ui", false);
  const [ssoChecked, setSSOChecked] = useState(false);
  const showSSOConfig = ssoChecked || (hasSSOConfig && enterpriseFeaturesEnabled);
  const showSCIM = scimflag && enterpriseFeaturesEnabled;

  useEffect(() => {
    if (showSSOConfig) {
      setSSOChecked(true);
    }
  }, [showSSOConfig]);

  return (
    <div>
      <PageHeader title="Authentication"></PageHeader>
      <SSOSwitch checked={ssoChecked} setChecked={setSSOChecked} />
      {hasSSOConfig ? <SSOConfigOK /> : <SSONotConfigured />}
      {showSSOConfig ? <SingleSignOnConfig /> : null}
      {showSCIM ? <SCIMSwitch /> : null}
      {showSCIM ? <SCIMTokens /> : null}
    </div>
  );
};

function SSOConfigOK() {
  return (
    <PageSection>
      <VerifiedIcon className={styles.verifiedIcon} color="ok" />
      <span className={styles.verifiedText}>Your Lens Business ID SSO is successfully configured.</span>
    </PageSection>
  );
}

function SSONotConfigured() {
  return (
    <PageSection className={styles.ssoMessageSection}>
      <VerifiedIcon className={styles.unVerifiedIcon} />
      <span className={styles.unVerifiedText}>SSO not configured for your Lens Business ID.</span>
    </PageSection>
  );
}
