import queryString from "query-string";

const validHarcodedCustomProtocolURLs = [
  // Redirect success URL when purchasing a team / personal space:
  "lens://extension/@lensapp/lenscloud-lens-extension/subscription",
];

export const defaultCustomProtocolURL = "lens://extension/@lensapp/lenscloud-lens-extension";

const isValidCustomProtocolUrl = (c: string) => {
  return validHarcodedCustomProtocolURLs.includes(c) || c.startsWith("lens://app/open/");
};

export const getCustomProtocolURL = () => {
  const c = queryString.parse(window.location.search).c?.toString() ?? "";

  return isValidCustomProtocolUrl(c) ? c : defaultCustomProtocolURL;
};

export const openLens = async () => {
  window.location.replace(getCustomProtocolURL());
};
