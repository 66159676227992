import { type SubscriptionInfo, type BusinessSubscription } from "lens-platform-sdk";
import { action } from "@k8slens/lds-icons";
import { useCallback, useContext } from "react";
import { ContextMenuItem } from "src/components/ContextMenu/ContextMenu";
import ContextMenuCell from "src/components/TableCells/ContextMenuCell";
import { getAccountUrl } from "src/services/billing/billingUrl";
import { openUrl } from "src/utils/url";
import { useBusinessBillingPageToken } from "src/hooks/useBillingPageToken";
import { BusinessContext } from "src/components/Business/Base";

const { DeleteIcon, ReactivateIcon } = action;

export function BusinessSubscriptionContextMenu({
  subscription,
  fromChildBusinessId,
}: {
  subscription: SubscriptionInfo | BusinessSubscription;
  fromChildBusinessId: boolean;
}) {
  const { businessId } = useContext(BusinessContext);
  const { token } = useBusinessBillingPageToken(businessId);
  const openAccountPage = useCallback(() => {
    if (!token) {
      return;
    }

    const accountUrl = getAccountUrl(token);

    if (!accountUrl) {
      return;
    }
    openUrl(accountUrl, true);
  }, [token]);

  switch (subscription.state) {
    case "active":
      return (
        <ContextMenuCell toggleButtonProps={{ title: "Toggle subscription context menu" }}>
          <ContextMenuItem
            disabled={fromChildBusinessId}
            label="Cancel"
            icon={DeleteIcon}
            onClick={() => openAccountPage()}
          />
        </ContextMenuCell>
      );
    case "canceled":
      return (
        <ContextMenuCell toggleButtonProps={{ title: "Toggle subscription context menu" }}>
          <ContextMenuItem
            disabled={fromChildBusinessId}
            label="Reactivate"
            icon={ReactivateIcon}
            onClick={() => openAccountPage()}
          />
        </ContextMenuCell>
      );
    case "expired":
      return null;
    default:
      return null;
  }
}
