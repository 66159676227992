import { useAuth } from "src/hooks/useAuth";
import { ActivateProTrial } from "src/app/subscribe/pro-trial/ActivateProTrial";
import { LoadingIndicator } from "@k8slens/lds";
import { RedirectToRegistration } from "./RedirectToRegistration";

export function ProTrialSignUpPage() {
  const { authenticated, initialized, isNavigating } = useAuth();

  if (initialized && !isNavigating) {
    // if the user is already signed in, activate the pro trial immediately
    if (authenticated) {
      return <ActivateProTrial />;
    }

    return <RedirectToRegistration />;
  }

  return <LoadingIndicator size="2xl" />;
}
