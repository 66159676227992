import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { type NotificationProps } from "@k8slens/lds/lib/es/Notification/Notification";

import Button from "src/components/Button/TrackedButton";
import LinkButton from "src/components/Button/TrackedRouterButton";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";
import PublicLayout from "src/components/PublicLayout/PublicLayout";

import { useQuery } from "src/hooks/useQuery";
import { useBusinessChildInvitation } from "src/hooks/useBusinessChildInvitation";

import styles from "./page.module.css";

const panelProps = {
  footerProps: {
    type: "grid",
    gridSize: 4,
  },
  contentProps: {
    className: styles.content,
  },
};
const notificationProps: Partial<NotificationProps> = { level: "error" };

export const ConfirmChildAttachment = () => {
  const history = useHistory();
  const {
    confirmChildInvitation,
    errorConfirmingChildInvitation,
    confirmingChildInvitation,
    confirmingChildInvitationSuccess,
  } = useBusinessChildInvitation();
  const query = useQuery();
  const token = useMemo(() => query.get("token"), [query]);
  const childBusinessId = useMemo(() => query.get("childBusinessId"), [query]);
  const parentBusinessId = useMemo(() => query.get("parentBusinessId"), [query]);

  const confirm = useCallback(async () => {
    if (token && childBusinessId) {
      confirmChildInvitation({ token, childBusinessId });
    }
  }, [token, confirmChildInvitation, childBusinessId]);

  useEffect(() => {
    if (confirmingChildInvitationSuccess) {
      history.push(`/business/${parentBusinessId}/parent-child-settings`);
    }
  }, [confirmingChildInvitationSuccess, parentBusinessId, history]);

  const errorMessage = useMemo(() => {
    if (errorConfirmingChildInvitation) {
      return errorConfirmingChildInvitation.message;
    } else if (!token) {
      return "Something went wrong";
    }

    return undefined;
  }, [errorConfirmingChildInvitation, token]);

  return (
    <PublicLayout
      title="Child Business Attachment"
      size="lg"
      panelProps={panelProps}
      footer={
        <>
          <LinkButton to="/home" label="Cancel" discreet />
          <div />
          <div />
          <Button
            label="Confirm"
            onClick={confirm}
            loading={confirmingChildInvitation}
            primary
            disabled={!token || confirmingChildInvitation}
          />
        </>
      }
      notification={errorMessage}
      notificationProps={notificationProps}
    >
      <>
        {token && "Click the button below to confirm child business attachment."}
        {!token && (
          <div className={styles.error}>
            <p>There seems to be some issues with your invitation token.</p>
            <p>
              Try re-opening the link in your email or trigger the confirmation process again. If that doesn&apos;t
              help, please contact us at <TrackedAnchor href="mailto:info@k8slens.dev">info@k8slens.dev.</TrackedAnchor>
            </p>
          </div>
        )}
      </>
    </PublicLayout>
  );
};
