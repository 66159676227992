import { parse, stringifyUrl } from "query-string";
import { zendeskDomain } from "src/constants";

export const getZendeskUrl = (
  zendeskJwt: string,
  location: {
    search: string;
  },
) => {
  const queryParams = parse(location.search);

  // Add return_to to the url if it's found in the query parameters
  // More information: https://support.zendesk.com/hc/en-us/articles/4408845838874-Enabling-JWT-JSON-Web-Token-single-sign-on#topic_hkm_kst_kk
  const { return_to: returnTo } = queryParams;

  const zendeskUrl = stringifyUrl({
    url: `https://${zendeskDomain}/access/jwt`,
    query: {
      jwt: zendeskJwt,
      ...(returnTo ? { return_to: returnTo } : {}),
    },
  });

  return zendeskUrl;
};
