import { Business, LensPlatformClient, LensSDKException, NotFoundException } from "lens-platform-sdk";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "src/queryClient";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useAnalytics } from "./useAnalytics";

export type BusinessFeature = Awaited<ReturnType<LensPlatformClient["business"]["getBusinessFeatures"]>>[number];

/**
 * Hooks to read and update Business Features
 */
export const useBusinessFeatures = (businessId: Business["id"] | undefined) => {
  const lensPlatformClient = useLensPlatformClient();
  const { track } = useAnalytics();

  const {
    isLoading: loadingBusinessFeatures,
    error: errorLoadingBusinessFeatures,
    data: businessFeatures,
    refetch: refetchBusinessFeatures,
  } = useQuery<Array<BusinessFeature>, LensSDKException>({
    queryKey: ["businessFeatures", businessId],
    queryFn: async () => {
      if (!businessId) {
        return [];
      }

      try {
        const features = await lensPlatformClient.business.getBusinessFeatures(businessId);

        return features;
      } catch (error) {
        if (error instanceof NotFoundException) {
          return [];
        }
        throw error;
      }
    },
    initialData: [],
  });

  const {
    isPending: updatingBusinessFeatures,
    isSuccess: updatingBusinessFeaturesSuccess,
    error: errorUpdatingBusinessFeatures,
    mutate: updateBusinessFeatures,
  } = useMutation<
    Array<Omit<BusinessFeature, "description">>,
    LensSDKException,
    { businessId: Business["id"]; features: Array<Pick<BusinessFeature, "key" | "enabled">> }
  >({
    mutationFn: async ({ businessId, features }) =>
      lensPlatformClient.business.updateBusinessFeatures(businessId, features),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["businessFeatures", businessId] });
      track("BusinessID features updated", { businessId });
    },
  });

  return {
    loadingBusinessFeatures,
    errorLoadingBusinessFeatures,
    businessFeatures,
    refetchBusinessFeatures,
    updatingBusinessFeatures,
    updatingBusinessFeaturesSuccess,
    errorUpdatingBusinessFeatures,
    updateBusinessFeatures,
  };
};
