import {
  type Business,
  type LensSDKException,
  BusinessInvitation,
  BusinessInvitationWithBusinessInfo,
} from "lens-platform-sdk";
import { useQuery } from "@tanstack/react-query";
import { useLensPlatformClient } from "./useLensPlatformClient";

export const useBusinessInvitation = (
  businessId?: Business["id"] | null,
  invitationId?: BusinessInvitation["id"] | null,
) => {
  const lensPlatformClient = useLensPlatformClient();

  const {
    isLoading: loadingBusinessInvitation,
    error: errorLoadingBusinessInvitation,
    data: businessInvitation,
  } = useQuery<BusinessInvitationWithBusinessInfo, LensSDKException | Error>({
    queryKey: ["businessInvitation", businessId, invitationId],
    queryFn: async () => {
      if (!businessId || !invitationId) {
        throw new Error("Business ID and Invitation ID are required");
      }
      const invitation = await lensPlatformClient.business.getOneInvitation(businessId, invitationId);

      return invitation;
    },
  });

  return {
    loadingBusinessInvitation,
    errorLoadingBusinessInvitation,
    businessInvitation,
  };
};
