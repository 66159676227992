import { useMemo } from "react";
import { createColumnHelper, useReactTable, getCoreRowModel, getSortedRowModel } from "@tanstack/react-table";
import { type BusinessInvitation } from "lens-platform-sdk";
import { action } from "@k8slens/lds-icons";

import { useTrackedTableSort } from "src/hooks/useTrackedTableSort";

import Table from "src/components/Table/Table";
import { dateColDef } from "src/components/TableCells/DateCell";
import ContextMenuCell, { contextMenuColDef } from "src/components/TableCells/ContextMenuCell";
import { ContextMenuItem } from "src/components/ContextMenu/ContextMenu";

const { DeleteIcon } = action;

const tableColumnHelper = createColumnHelper<BusinessInvitation>();

const caption = "Pending Users";

interface Props {
  businessInvitations: Array<BusinessInvitation>;
  onRemoveBusinessInvitation?: (invitation: BusinessInvitation) => void;
  loading?: boolean;
}

const Pending = ({ businessInvitations, onRemoveBusinessInvitation, loading }: Props) => {
  const handleRemovePendingUser = (invitation: BusinessInvitation) => {
    onRemoveBusinessInvitation && onRemoveBusinessInvitation(invitation);
  };

  const tableColumns = [
    tableColumnHelper.accessor("email", {
      header: () => <span>Email</span>,
      cell: ({ getValue }) => getValue(),
      meta: { primary: true },
    }),
    tableColumnHelper.accessor("role", {
      header: () => <span>Role</span>,
      cell: ({ getValue }) => getValue(),
    }),
    tableColumnHelper.accessor("createdAt", {
      ...dateColDef,
      header: () => <span>Invitation Date</span>,
    }),
    tableColumnHelper.accessor("id", {
      ...contextMenuColDef,
      cell: ({ row }) => (
        <ContextMenuCell toggleButtonProps={{ title: "Toggle context menu" }}>
          <ContextMenuItem label="Remove" icon={DeleteIcon} onClick={() => handleRemovePendingUser(row.original)} />
        </ContextMenuCell>
      ),
    }),
  ];
  const { sorting, onSortingChange } = useTrackedTableSort({
    table: caption,
    initialSort: [{ id: "createdAt", desc: true }],
  });

  const pendingUserTable = useReactTable({
    data: businessInvitations,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange,
  });
  const rowModel = pendingUserTable.getRowModel();
  const rows = useMemo(() => rowModel.rows, [rowModel]);

  return (
    <Table<BusinessInvitation>
      caption={caption}
      loading={loading}
      rows={rows}
      columns={pendingUserTable.getAllColumns()}
      headerGroups={pendingUserTable.getHeaderGroups()}
      noDataText="No pending users on your Lens Business ID!"
    />
  );
};

export default Pending;
