import React, { PropsWithChildren, useCallback, useEffect } from "react";

import { FormField, common } from "@k8slens/lds-form";
import { type FormFieldComponentProps } from "@k8slens/lds-form/lib/es/FormField/FormField";
import { type Validator, type CustomValidityState } from "@k8slens/lds-form/lib/es/common/validation.d";

import Select, { DefaultOption, SelectProps } from "src/components/Select/Select";

const { useFieldIds, useInputErrorProps, useFormComponentData, defaultErrorParser: ldsDefaultErrorParser } = common;

export const defaultErrorParser = ldsDefaultErrorParser;

type ValueType<T> = SelectProps<T>["value"];

type CustomSelectProps<T> = Omit<SelectProps<T>, "onChange" | "name" | "value"> & {
  name: string;
  required?: boolean;
};

interface Props<T extends DefaultOption, CustomError extends string>
  extends CustomSelectProps<T>,
    FormFieldComponentProps<ValueType<T>, CustomError> {
  id?: string;
  value: SelectProps<T>["value"];
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
}

const handleValidation = <T, CustomError extends string>(
  value: T,
  // TODO: Fix types
  selectProps: CustomSelectProps<T>,
  validate?: Validator<any, CustomError>,
) => {
  // Valid unless explicitly invalidated
  const validity: CustomValidityState<CustomError> = { valid: true };

  if (selectProps.required && !value) {
    validity.valid = false;
    validity.valueMissing = true;
  }

  if (validate) {
    // Get errors from custom validator function
    const result = validate(value, validity, selectProps.name, selectProps);

    if (typeof result === "string") {
      validity[result] = true;
      validity.valid = false;
    } else if (result) {
      result.forEach((key) => {
        validity[key] = true;
      });
      validity.valid = false;
    }
  }

  return validity;
};

/**
 * Input wrapped in Form Field
 *
 * Handles validation and errors with accessible label and error notifications.
 */
export function FormSelect<T extends DefaultOption, CustomError extends string>({
  id: _id,
  label,
  wrapperProps,
  labelProps,
  validate,
  errorParser = ldsDefaultErrorParser,
  errorProps: errorComponentProps,
  value,
  onChange,
  ...selectProps
}: PropsWithChildren<Props<T, CustomError>>) {
  const { id, labelId, errorId } = useFieldIds(_id);

  const { currentValue, setCurrentValue, errors } = useFormComponentData<ValueType<T>, CustomError>({
    value,
    errorParser,
    constraints: selectProps,
    label,
  });
  const errorProps = useInputErrorProps({ id: errorId, errors });

  /**
   * Value changed
   */
  const handleChange = useCallback(
    (value: T) => {
      const validity = handleValidation(value, selectProps, validate);

      setCurrentValue({
        value,
        validity: { ...validity },
      });
      // TODO: Fix types
      (onChange as any)(value, validity.valid);
    },
    [validate, onChange, setCurrentValue, selectProps],
  );

  /**
   * Validator changed
   */
  useEffect(() => {
    if (currentValue.validity) {
      // Revalidate when validator function changes and field is dirty (has validity object)
      const validity = handleValidation(currentValue.value, selectProps, validate);

      const wasValid = currentValue.validity?.valid;
      const isValid = validity.valid;

      if (wasValid !== isValid) {
        setCurrentValue((current) => ({
          ...current,
          validity,
        }));
        // TODO: Fix types
        (onChange as any)(currentValue.value, isValid);
      }
    }
  }, [validate, value, currentValue, setCurrentValue, onChange, selectProps]);

  return (
    <FormField
      id={id}
      label={label}
      labelId={labelId}
      errorId={errorId}
      errors={errors}
      required={selectProps.required}
      labelProps={labelProps}
      errorProps={errorComponentProps}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...wrapperProps}
    >
      <Select
        id={id}
        aria-labelledby={labelId}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...errorProps}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...selectProps}
        value={currentValue?.value}
        onChange={handleChange}
      />
    </FormField>
  );
}
