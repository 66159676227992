import { type BusinessUserSubscriptionSeat } from "lens-platform-sdk/dist/cjs/BusinessService";
import type { SubscriptionSeat } from "lens-platform-sdk";

/**
 * reduce function to get the seat with the latest `activatedAt` date
 */
export const lastestActivatedAtBusinessUserSubscriptionSeat = (
  previous: BusinessUserSubscriptionSeat,
  current: BusinessUserSubscriptionSeat,
) => {
  if (new Date(previous.activatedAt).getTime() > new Date(current.activatedAt).getTime()) {
    return previous;
  }

  return current;
};

/**
 * reduce function to get the seat with the latest `activatedAt` date
 */
export const lastestActivatedAtSeatSubscriptionSeat = (previous: SubscriptionSeat, current: SubscriptionSeat) => {
  if (new Date(previous.activatedAt).getTime() > new Date(current.activatedAt).getTime()) {
    return previous;
  }

  return current;
};
