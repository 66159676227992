import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * Based on https://reactrouter.com/web/example/query-parameters
 * Returns query parameters from the url. Parameters can be accessed with query.get("name").
 */
export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
