import type { UserWithEmail } from "lens-platform-sdk";
import * as React from "react";
import { useFetchProfile } from "src/hooks/useFetchProfile";

export const ProfileContext = React.createContext<{
  profile: UserWithEmail | null;
  loading: boolean;
  updateProfile: () => void;
}>({
  profile: null,
  loading: false,
  updateProfile: () => {},
});

interface Props {
  children?: React.ReactNode;
}

export const ProfileProvider: React.FC<Props> = ({ children }) => {
  const { profile, isProfileLoading, updateProfile } = useFetchProfile();

  return (
    <ProfileContext.Provider
      value={{
        profile,
        loading: isProfileLoading,
        updateProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
