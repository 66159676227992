import { useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Notification } from "@k8slens/lds";

import { ProfileContext } from "src/providers/profile-provider";
import { useAuth } from "src/hooks/useAuth";
import { useGetUserSSO } from "src/hooks/useGetUserSSO";

import Layout from "src/components/Layout/Layout";
import { DownloadOfflineActivationCodeModal } from "src/components/DownloadOfflineActivationModal/DownloadOfflineActivationCodeModal";
import { UserSSOBanner } from "src/components/UserSSOBanner/UserSSOBanner";
import PageHeader from "src/components/PageHeader/PageHeader";
import PageSection from "src/components/PageSection/PageSection";
import PageAction from "src/components/PageAction/PageAction";

import { LicenseContainer } from "../../components/pages/Home/LicenseContainer";

import styles from "src/components/pages/Home/Home.module.css";
import { useCanDownloadCode } from "src/hooks/useCanDownloadCode";

interface LocationState {
  invitationId: string;
}

export const Home = () => {
  const location = useLocation<LocationState | undefined>();
  const { tokenParsed } = useAuth();
  const { profile } = useContext(ProfileContext);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const { userSSOAccountsConnectionLinks } = useGetUserSSO();

  const handleDownload = () => {
    // has to use native window.location instead of react-router-dom's history
    // because `/offline-activation-code/login` is using another instance of AuthProvider
    window.location.replace(`${window.location.origin}/offline-activation-code/login`);
  };

  const canDownloadCode = useCanDownloadCode();

  const isPersonalLicense = useMemo(() => tokenParsed?.license_type === "personal", [tokenParsed?.license_type]);

  return (
    <Layout className="bg-secondary" profile={profile}>
      {location.state?.invitationId && (
        <Notification
          level="info"
          type="flash"
          message="You have successfully accepted the Lens Business ID invitation."
        />
      )}
      <div className={styles.home}>
        <div>
          <UserSSOBanner userSSOLinks={userSSOAccountsConnectionLinks} />
          <PageHeader title="Manage your Lens ID" subtitle="Lens ID is your personal identity in the Lens universe." />
          <PageSection>
            <PageHeader
              title="Your Active Lens Subscription"
              subtitle={
                <>
                  Some products and services (such as Lens Desktop and Lens Cloud Services) made available to you may
                  require active Lens Subscription. Your currently active subscription is displayed below.
                </>
              }
              type="sub"
            />
            <div className={styles.subscriptions}>
              <LicenseContainer />
            </div>
          </PageSection>
          {tokenParsed?.license_type ? (
            <PageSection>
              <PageHeader
                title="Lens ID offline activation code"
                subtitle={
                  <>
                    Activate Lens Desktop with Activation Code. This activation method works for offline and air-gapped
                    environments. This activation code is for your use and should not be shared.
                  </>
                }
                type="sub"
              />
              <PageAction
                label="Download Activation Code"
                buttonProps={{
                  disabled: !canDownloadCode,
                  onClick: () => setIsModalOpened(true),
                }}
              />
              {!canDownloadCode && !isPersonalLicense && (
                <p>
                  <i>
                    <small>Your administrator has disabled the use of offline activation.</small>
                  </i>
                </p>
              )}
              {!canDownloadCode && isPersonalLicense && (
                <p>
                  <small>
                    Offline activation is available with a Lens Enterprise subscription. Upgrade{" "}
                    <a className="primary" href="subscribe">
                      here.
                    </a>
                  </small>
                </p>
              )}
            </PageSection>
          ) : null}
        </div>
      </div>
      {canDownloadCode ? (
        <DownloadOfflineActivationCodeModal
          isOpened={isModalOpened}
          onDownload={handleDownload}
          onCancel={() => setIsModalOpened(false)}
        />
      ) : null}
    </Layout>
  );
};
