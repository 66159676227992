import { base } from "@k8slens/lds-icons";

import styles from "./ProductName.module.css";

const { LogoIcon } = base;

export function ProductName({ title }: { title: string | React.ReactNode }) {
  return (
    <div className={styles.productName}>
      <LogoIcon color="accent" />
      <div className={styles.title} aria-hidden>
        {title}
      </div>
    </div>
  );
}
